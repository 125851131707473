import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { API_ENDPOINT } from '../../../../services/Api';
import requestInstance from '../../../../services/request';
import './globalTemplate.css';
import { useSelector } from 'react-redux';
import Spinner from '../../../loader/SimpleLoader';

const Template = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation();
  const templateId = pathname.split('/')[3];
  const [templateData, setTemplateData] = useState(null);
  const { email, full_name } = useSelector(state => state.user.value);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getTemplateById = async () => {
      try {
        setLoading(true);
        const response = await requestInstance.get(`${API_ENDPOINT.GLOBAL_TEMPLATES_INDIVIDUAL}${templateId}`);
        setTemplateData(response?.data?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    getTemplateById();
  }, []);

  return (
    <>
      {
        loading ? <Spinner /> : <div className='container d-flex justify-content-center my-5 px-5'>
          <div className="templateContainer">
            <div className="from">
              <span>From: </span>
              <span>{full_name ?? ''} ({email ?? 'N/A'})</span>
            </div>
            <div className="subject">
              <span>Subject: </span>
              <strong>{templateData?.template_subject ?? 'N/A'}</strong>
            </div>
            <div className="body" dangerouslySetInnerHTML={{ __html: templateData?.template_body ?? 'N/A' }}></div>
          </div>
          <div className="templateDetailsContainer d-flex align-self-center flex-column">
            <button onClick={() => navigate('/campaign/create-template', { state: templateData })} className='btn btn-danger'>Save in my templates</button>
            <div className="templateFilter my-5">
              <p>Categories</p>
              <span>{templateData?.template_type}</span>
              <span>{templateData?.template_name}</span>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Template