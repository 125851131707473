import React from "react";
import Navbar from "./Navbar_1";
import Body_1 from "./Body_1";

import "./New.css";

function newhome() {
  return (
    <div>
      <Navbar />
      <Body_1 />
    </div>
  );
}

export default newhome;
