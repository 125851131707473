import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../new_home/Navbar_1";
import Footer from "../../new_home/Footer";
import faqFirst from "../../assets/faq-banner-image.svg";
import plusIcon from "../../assets/accordion-plus.svg"
// import { Collapse } from 'bootstrap';
import "./Faq.css";

export default function Faq() {
  const generalQuestion = [
    {
      id: 1,
      title: "What is the best time to use 1 credit on Discovemail.com?",
      description:
        "Each email request costs one credit on DiscoveMail.com. You can sign up for the 100% free plan at Discovemail.com.You can select one of our paying plans if you want some of the more advanced features. Our goal is to assist as many individuals as we can in meeting their prospecting needs without trying to take advantage of them financially.",
    },
    {
      id: 2,
      title: "What is the accuracy of Discovemail Email Finder ?",
      description:
        "The accuracy of Discovemail Email Finder can vary, but it's designed to provide reliable results by leveraging multiple data sources and advanced algorithms.Many users find it to be an efficient tool for discovering email addresses, especially for outreach and lead generation.However, as with any email finder tool, it's important to verify email addresses for accuracy before using them for communication to ensure successful outreach.",
    },
    {
      id: 3,
      title: "Are the email addresses guesses or found somewhere ?",
      description:
        "Email addresses found using an email finder tool like Discovemail are typically not guesses. Instead, these tools search for email addresses that are publicly available or have been published on websites, social media profiles, online directories, or other publicly accessible sources. The process typically involves web scraping and data mining techniques to locate and compile email addresses from various online sources. It doesn't involve random guessing of email addresses, as that would be highly inefficient and unlikely to yield accurate results.",
    },

  ];

  const generalQuestion2 = [
    {
      id: 4,
      title: "Can I find the email addresses of a list of people ?",
      description:
        "Yes, We have a bulk email verification option to verify email addresses. You have to just upload the file and press enter it will validate and give the results.",
    },
    {
      id: 5,
      title: "How is the Discovemail different from other email search tools ?",
      description:
        "The Discovemail automates email searches using various data sources, making it efficient for bulk searches and potentially offering email verification, integration, and privacy features that distinguish it from manual email search methods or other tools.",
    },
    {
      id: 6,
      title: "Does Discovemail send out any e-mail message while validating?",
      description:
        "No, Discovemail doesn't send out any e-mail message while email verify",
    },

  ];

  return (
    <>
      <Helmet>
        <title>Discovemail - Terms of service</title>
      </Helmet>

      <Navbar />

      <section className="mt-md-5 pt-md-5 faqFirstSection">
        <div className=" container">
          <div className="row align-items-center">
            <div className="col-md-6 py-5 my-5 ">
              <h1 className="title1 mb-3">Frequently Asked Questions</h1>
              <p className="description mt-2 mb-4">
                Below we have listed some commonly asked questions and answers
                that belong to particular topics around Descovemail services.
              </p>
            </div>
            <div className="col-md-6 text-end">
              <img src={faqFirst} alt="img not found" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section className="faqSecondSection py-5">
        <div className="container py-3">
          <div className="row">
            <div className="col-12 mb-4">
              <h6 className="title">General Questions </h6>
            </div>
            {/* <div className='col-12'> */}
            <div
              class="accordion frqAskedQusAccordion"
              id="accordionExample"
            >
              <div
                class=" row"

              >
                <div className="col-md-6 general-acc">
                  {generalQuestion.map((res, index) => (
                    <div class="accordion-item mb-4 py-3" key={res.id}>
                      <h2 class="accordion-header" id={"heading" + res.id}>
                        <button
                          class={
                            (res.id !== 1 ? "collapsed " : "") +
                            "accordion-button border-0 pb-2"
                          }
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#collapse" + res.id}
                          aria-expanded={res.id === 1 ? true : false}
                          aria-controls={"collapse" + res.id}
                        >
                          {res.title}
                        </button>
                      </h2>
                      <div
                        id={"collapse" + res.id}
                        class={
                          (res.id === 1 ? "show " : "") +
                          "accordion-collapse collapse border-0"
                        }
                        aria-labelledby={"heading" + res.id}
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body pt-0">
                          <p className="m-0 pe-md-5">{res.description}</p>
                        </div>
                      </div>
                    </div>

                  ))}
                </div>
                <div className="col-md-6">
                  {generalQuestion2.map((res, index) => (
                    <div class="accordion-item mb-4 py-3" key={res.id}>
                      <h2 class="accordion-header" id={"heading" + res.id}>
                        <button
                          class={
                            (res.id !== 1 ? "collapsed " : "") +
                            "accordion-button border-0 pb-2"
                          }
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#collapse" + res.id}
                          aria-expanded={res.id === 1 ? true : false}
                          aria-controls={"collapse" + res.id}
                        >
                          {res.title}
                        </button>
                      </h2>
                      <div
                        id={"collapse" + res.id}
                        class={
                          (res.id === 1 ? "show " : "") +
                          "accordion-collapse collapse border-0"
                        }
                        aria-labelledby={"heading" + res.id}
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body pt-0">
                          <p className="m-0 pe-md-5">{res.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* </div> */}
            {/* <div className="col-12 mb-4">
              <h6 className="title">Service Usage </h6>
            </div>
            <div className="col-12">
              <div
                class="accordion frqAskedQusAccordion"
                id="accordionExample2"
              >
                {generalQuestion.map((res, index) => (
                  <div class="accordion-item py-3 mb-4 service-accc" key={index}>
                    <h2 class="accordion-header" id={"heading2" + index}>
                      <button
                        class={
                          (index !== 0 ? "collapsed " : "") +
                          "accordion-button border-0 pb-2"
                        }
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#collapse2" + index}
                        aria-expanded={index === 0 ? true : false}
                        aria-controls={"collapse" + index}
                      >
                        {res.title}
                      </button>
                    </h2>
                    <div
                      id={"collapse2" + index}
                      class={
                        (index === 0 ? " show " : "") +
                        "accordion-collapse collapse border-0"
                      }
                      aria-labelledby={"heading2" + index}
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body pt-0">
                        <p className="m-0">{res.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
