import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Navbar from '../../new_home/Navbar_1'
import Footer from '../../new_home/Footer'
import Loader from "../loader/Loader";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import { formatMobileNumber } from "../../utils/utils";
import missionImg from '../../assets/about/mission_pic.webp'
import superFast from '../../assets/product/rocket.svg'
import support_247 from '../../assets/product/247_support.svg';
import high_speed from '../../assets/product/high_speed.svg';
import secure_validation from '../../assets/product/secure_validation.svg';
import teamMember3 from '../../assets/about/team_member3.svg'
import teamMember4 from '../../assets/about/team_member4.svg'
import member1 from '../../assets/about/naveen.svg'
import member2 from '../../assets/about/harsh.svg'
import member3 from '../../assets/about/sweta.svg'
import member4 from '../../assets/about/vannati.svg'
import member5 from '../../assets/about/pooja.svg'
import vission1 from '../../assets/about/vision_pic1.svg'
import vission2 from '../../assets/about/vision_pic2.svg'
import vission3 from '../../assets/about/vision_pic3.svg'
import rockstarFamily from '../../assets/about/about-family.webp'
import contactBanner from '../../assets/about/contact.webp'
import './AboutUs.css'

const validate = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "Please Enter your Name";
  } else if (values.firstName[0].includes(" ")) {
    errors.firstName = "Must not include space at start";
  } else if (/^(?=.*?[0-9])/i.test(values.firstName)) {
    errors.firstName = "Must not contain number";
  } else if (/(?=.*?[#?!@$%^&*-])/i.test(values.firstName)) {
    errors.firstName = "Must not contain special characters";
  }
  if (!values.email) {
    errors.email = "Please enter email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "Please enter your Phone No";
  } else if (values.phoneNumber.length < 10) {
    errors.phoneNumber = "Must be of 10 digits";
  }
  if (!values.message) {
    errors.message = "Please write a message";
  } else if (values.message[0].includes(" ")) {
    errors.message = "Must not include space at start";
  }

  return errors;
};

export default function AboutUs() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      setButtonDisabled(true);
      handleContactUs(values, { resetForm });
    },
  });

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  console.log(query.get("plan"))
  let plan = query.get("plan");

  const handleContactUs = (values, { resetForm }) => {
    let data = JSON.stringify({
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone_number: values.phoneNumber,
      message: values.message,
      data_type: "contact_message",
    });
    
    requestInstance
      .post(API_ENDPOINT.CONTACT_API, data)
      .then((res) => {
        if (res.status == 200) {
          toast.success(res.data.status);
          setLoading(false);
          resetForm();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Discovemail</title>
      </Helmet>

      <Navbar />
      <section className='mt-md-5 pt-md-5 bg_color_dark2 aboutFirstSection'>
        <div className='container'>
          <div className='row'>
            <h3 className="m-0 text-center text-white mb-3">Our Company</h3>
            <div className='col-12'>
              <div className='row justify-content-center'>
                <div className='col-md-8 col-lg-8'>
                  <h2 className="m-0 text-center title mb-3">We streamline email outreach for organizational growth</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='py-5 bg_color_dark'>
        <div className='container aboutSecondSection px-md-5 my-md-5'>
          <div className='row mx-4'>
            <div className='col-lg-6 col-12 px-md-5 align-self-center leftSection'>
              <h3 className='title1 text-start px-4 pt-lg-4'>Discovemail Your comprehensive email outreach solution. Seamlessly connect with prospects, whether you're a marketer, salesperson, recruiter, or beyond.</h3>
            </div>
            <div className='col-lg-6 col-12 px-0 d-flex justify-content-end'>
              <img src={missionImg} alt="img" className='img-fluid' />
            </div>
          </div>
        </div>
      </section>
      <section className='py-5 bg_color_dark2 aboutThirdSection'>
        <div className='container'>
          <div className='row justify-content-center py-4'>
            <div className='col-md-5'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='aboutThirdSectionCard p-4 text-center mb-3 mt-5'>
                    <img src={superFast} alt='img-not-found' className='img-fluid' />
                    <h2 className='title'>Super Fast</h2>
                  </div>
                  <div className='aboutThirdSectionCard p-4 text-center mb-3'>
                    <div className=''>
                      <img src={high_speed} alt='img-not-found' className='img-fluid ' />
                    </div>
                    <h2 className='title'>High-speed validation</h2>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='aboutThirdSectionCard p-4 text-center mb-3'>
                    <img src={support_247} alt='img-not-found' className='img-fluid' />
                    <h2 className='title'>24/7 Customer</h2>
                  </div>
                  <div className='aboutThirdSectionCard p-4 text-center mb-3'>
                    <img src={secure_validation} alt='img-not-found' className='img-fluid' />
                    <h2 className='title'>Secure Email Validation</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-5 offset-md-1 align-self-center'>
              <h2 className='title'>Our story</h2>
              <p className='description'>In 2018, Harsh Vardhan Singh recognized the potential of cold emails and founded Discovemail to address prospecting challenges. Originally named findemail, the platform quickly gained traction, offering user-friendly features and high data accuracy. Rebrand as Discovemail, it aimed to democratize cold emailing, catering to professionals of all sizes. Over time, it evolved into a comprehensive outreach platform, providing contact discovery and email sending services. Led by Harsh and his dedicated team, Discovemail continues to empower users with effective and accessible email outreach solutions.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='py-5 bg_color_dark ourTeamSection'>
        <div className=' container'>
          <div className='row justify-content-center py-5'>
            <h3 className='mainHeading'>Meet our team</h3>
            <div className='col-md-5 text-center my-5'>
              <img src={member2} alt="img not found" className='img-fluid' />
              <h2 className='title1 mt-4'>Harsh Vardhan Singh</h2>
              <p className='description'>CEO | Founder</p>
            </div>
            <div className='col-md-5 text-center my-5'>
              <img src={member1} alt="img not found" className='img-fluid' />
              <h2 className='title1 mt-4'>Naveen Kumar</h2>
              <p className='description'>Advisor</p>
            </div>
            <div className='col-md-4 text-center'>
              <img src={member3} alt="img not found" className='img-fluid' />
              <h2 className='title2 mt-4'>Sweta Raha</h2>
              <p className='description'>Account Manager</p>
            </div>
            <div className='col-md-4 text-center'>
              <img src={member4} alt="img not found" className='img-fluid' />
              <h2 className='title2 mt-4'>Vannati Theja</h2>
              <p className='description'>Customer Success</p>
            </div>
            <div className='col-md-4 text-center'>
              <img src={member5} alt="img not found" className='img-fluid' />
              <h2 className='title2 mt-4'>Pooja Seervi</h2>
              <p className='description'>Associate Account Manager</p>
            </div>
            {/* <div className='col-md-3 text-center'>
              <img src={teamMember4} alt="img not found" className='img-fluid' />
              <h2 className='title2 mt-4'>Mirtyunjay Tiwari</h2>
              <p className='description'>Backend Developer</p>
            </div> */}
          </div>
        </div>
      </section>
      <section className='aboutVissionSection bg_color_dark2 py-4'>
        <div className=' container py-5'>
          <div className='row px-5'>
            <h3 className="m-0 mainHeading mb-3">Our Vision</h3>
            <p className="mainDescription  text-center mb-5 px-5">Discovemail originated from our own challenges as digital marketers. We faced issues with email deliverability and unreliable data from verification services. These shortcomings inspired us to create Discovemail with accuracy, speed, affordability, and excellent support as our guiding principles!</p>
            <div className='col-md-4 text-center px-5'>
              <img src={vission1} alt="img not found" className='img-fluid' />
              <h2 className='title my-3'>Customer success</h2>
              <p className='description'>Empowering customers to achieve their goals and realize successful outcomes through our dedicated support and tailored solutions.</p>
            </div>
            <div className='col-md-4 text-center px-5'>
              <img src={vission2} alt="img not found" className='img-fluid' />
              <h2 className='title my-3'>Inclusive Growth</h2>
              <p className='description'>Inclusivity is a cornerstone of our company's growth and scale, guiding our decisions and actions at every step.</p>
            </div>
            <div className='col-md-4 text-center px-5'>
              <img src={vission3} alt="img not found" className='img-fluid' />
              <h2 className='title my-3'>Care about our users' experience</h2>
              <p className='description'>We prioritize the experience of our users and ensure that any issues are promptly addressed and resolved.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='bg_color_dark aboutFamilySection'>
        <div className=' container-fluid py-5'>
          <div className='row'>
            <h3 className='title'>Join the Discovemail 20+ rockstars family</h3>
            <div className=' d-flex justify-content-center my-5'>
              <img src={rockstarFamily} alt='img not found' className='img-fluid' />
            </div>
          </div>
        </div>
      </section>
      
      <section className='bg_color_dark2'>
        <div className=' container-fluid'>
          <div className='row'>
            <div className='col-md-6 contactForm ps-5 pt-5'>
              <h2 className='title ms-md-5 mb-4 mt-4'>Let's talk</h2>
              <form onSubmit={formik.handleSubmit} className="ms-md-5">
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="input-group">
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                        className="form-control py-3"
                        placeholder="First name"
                      />
                    </div>
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div className="errormsg">
                        {formik.errors.firstName}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        className="form-control py-3"
                        placeholder="Last name"
                      />
                    </div>
                  </div>
                </div>

                <div className="row mb-4 ">
                  <div className="col-md-6 mt-3">
                    <div className="input-group">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        className="form-control py-3"
                        placeholder="Email"
                      />
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <div className="errormsg">{formik.errors.email}</div>
                    ) : null}
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="input-group">
                      <PhoneInput
                        inputProps={{
                          name: "phoneNumber",
                          required: true,
                          placeholder: "Phone Number",
                          className: "form-control py-3",
                        }}
                        country={"in"}
                        name="phoneNumber"
                        enableSearch={true}
                        autoFormat={false}
                        value={formik.values.phoneNumber}
                        onChange={(value, data, event, formattedValue) => formik.setFieldValue('phoneNumber', formatMobileNumber(formattedValue, data))}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber ? (
                      <div className="errormsg">
                        {formik.errors.phoneNumber}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="input-group mb-lg-4 mb-3">
                  <textarea
                    type="text"
                    className="form-control mt-3"
                    id="message"
                    name="message"
                    rows={6}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                    placeholder="Message"
                  ></textarea>
                </div>
                {formik.touched.message && formik.errors.message ? (
                  <div className="errormsg">
                    {formik.errors.message}
                  </div>
                ) : null}

                <div className="d-flex justify-content-end">
                  <button type="submit" className="btn btn-disco">
                    Submit
                  </button>
                </div>

              </form>
            </div>
            <div className='col-md-6 px-0 d-flex justify-content-end'>
              <div>
                <img src={contactBanner} alt='img not found' className='img-fluid' />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='bg_color_dark py-3' ></section>
      <Footer />
    </>
  )
}
