import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { campaignSettingsDelayTime, campaignSettingsEndTime, campaignSettingsStartTime, campaignSettingsWeekDays } from '../../../redux/slices/campaign/CampaignSlice';
import { toast } from 'react-toastify';

const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const delaytimeOptions = [15, 30, 45, 60];


export default function CampaignSettings() {
  const initialWeekDays = useSelector((state)=>state?.campaign?.campaignSettingsWeekDays);
  const initialStartTime = useSelector((state)=>state?.campaign?.campaignSettingsStartTime);
  const initialEndTime = useSelector((state)=>state?.campaign?.campaignSettingsEndTime);
  const initialDelayTime = useSelector((state)=>state?.campaign?.campaignSettingsDelayTime);

  const [selectedDays, setSelectedDays] = useState(initialWeekDays);
  const [startTime, setStartTime] = useState(initialStartTime);
  const [endTime, setEndTime] = useState(initialEndTime);
  const [delayTime, setDelayTime] = useState(initialDelayTime);

  const dispatch = useDispatch();
  

  const handleCheckboxChange = (e) => {
    const { value } = e.target;
    setSelectedDays((prevSelectedDays) => {
      if (prevSelectedDays?.includes(value)) {
        return prevSelectedDays.filter((day) => day !== value);
      } else {
        return [...prevSelectedDays, value];
      }
    });
  }

  useEffect(() => {
    dispatch(campaignSettingsWeekDays(selectedDays));
  }, [selectedDays])


  const compareTimes = (time1, time2) => {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    if (hours1 < hours2 || (hours1 === hours2 && minutes1 < minutes2)) {
      return -1; // time1 is before time2
    } else if (hours1 === hours2 && minutes1 === minutes2) {
      return 0; // time1 is equal to time2
    } else {
      return 1; // time1 is after time2
    }
  };
  

  const handleStartTimeChange = (e) => {
    const newStartTime = e?.target?.value;

    if (compareTimes(newStartTime, endTime) === -1) {
      setStartTime(newStartTime);
      dispatch(campaignSettingsStartTime(newStartTime));
    } else {
      toast.error("Start time must be earlier than end time");
    }
  };


  const handleEndTimeChange = (e) => {
    const newEndTime = e?.target?.value;

    if (compareTimes(startTime, newEndTime) === -1) {
      setEndTime(newEndTime);
      dispatch(campaignSettingsEndTime(newEndTime));
    } else {
      toast.error("End time must be later than start time");
    }
  };


  const handleDelayTimeChange = (e) => {
    setDelayTime(e?.target?.value);
    dispatch(campaignSettingsDelayTime(e?.target?.value));
  };



  return (
    <div className='container'>

      <div className='px-3'>
        <h6 className='fw-bold text-dark fs-5 pt-3'>Settings</h6>
        <small>Manage your settings for this campaign. You can overwrite your default settings if needed.</small>
      </div>

      <div className='px-3 pt-5'>
        <h6 className='fw-semibold text-dark pt-3'>Custom Sending Window</h6>
        <div className='d-flex gap-3'>
          {weekDays.map((day) => (
              <label key={day} className='border border-2 border-secondary p-2 text-dark fw-bold'>
                <input
                  type="checkbox"
                  value={day}
                  checked={selectedDays.includes(day)}
                  onChange={handleCheckboxChange}
                  className='mx-2'
                />
                {day}
              </label>
          ))}
        </div>

          <div className='mt-5'>
            <h6 className='fw-semibold text-dark '>Choose Start time and End time</h6>
            <div className='d-flex align-items-baseline'>
              <div class="col-2">
                <input type="time" id="startTime" className='form-control' value={startTime} onChange={handleStartTimeChange}  />
              </div>

              <div className='text-dark mx-2'>to</div>

              <div className='col-2'>
                <input type="time" id="endTime" className='form-control' value={endTime} onChange={handleEndTimeChange}  />
              </div>

              <div className='fw-bold mx-3'>(IST - Asia / Kolkata)</div>
            </div>
         </div>
           
         <div className='mt-5'>
            <h6 className='fw-semibold text-dark '>Choose Average Delay in (Seconds)</h6>
            <div className='d-flex align-items-baseline'>
              <div className='col-1'>
                <select id="delayTime" className='form-select' value={delayTime} onChange={handleDelayTimeChange}>
                  {delaytimeOptions.map((time, index) => (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
              </div>
            </div>
         </div>
      </div>

    </div>
  )
}
