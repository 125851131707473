import React, { useEffect, useState } from 'react';
import './BulkLaunch.css';
import Navbar_Dash from '../dashboard/Navbar';
import { useNavigate, useParams } from 'react-router-dom';
import requestInstance from '../../services/request';
import { toast } from 'react-toastify';
import { API_ENDPOINT } from '../../services/Api';
import Spinner from "../../pages/loader/SimpleLoader";

const BulkLaunch = () => {
    const [loading, setLoading] = useState(true);
    const [bulkLaunchData, setBulkLaunchData] = useState();
    const navigate = useNavigate();
    const params = useParams();

    const getLaunchData = () =>{
        requestInstance
          .get(API_ENDPOINT.BULK_EMAIL_VERIFIER_SUMMARY+`/${params?.id}`)
          .then((res) => {
            setLoading(true)
            setBulkLaunchData(res.data);
            toast.success(res.data.message);
            setLoading(false);
          })
          .catch((err) => {
            toast.error(err);
            setLoading(false);
          });
      }

    const handleLaunch = () =>{
      setLoading(true)
        requestInstance
          .get(API_ENDPOINT.BULK_EMAIL_VERIFIER_LAUNCH+`/${params?.id}`)
          .then((res) => {
            toast.success(res?.data?.message);
            navigate(`/bulk-tasks/bulk-email-verifier-summary/${params?.id}`);
            setLoading(false);
          })
          .catch((err) => {
            toast.error(err);
            setLoading(false);
          });
    }

      useEffect(() => {
        getLaunchData();
      }, [params])
      
  return (
    <>
      <Navbar_Dash />


      {loading ? <Spinner /> : <div className="bulkLaunch_main mt_6rem">
        <div className="bulkLaunch_card bg-white">
          <div className="p-4">
            <div className="mt-3">
                <div className='d-flex justify-content-between container align-items-baseline'>
                    <div className="d-flex gap-3 align-items-baseline">
                        <div onClick={()=>{navigate('/bulk-tasks/bulk-email-verifier')}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-circle cursorPointer" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                        </svg>
                        </div>
                        <div>
                        <h6 className='fw-bold'>
                            {bulkLaunchData?.bulk_email_list_name}
                        </h6>
                        <small className='text-secondary'>{bulkLaunchData?.count} email addresses to verify</small>
                        </div>
                    </div>
                </div>
            </div>

            <div className='hrLine my-4'></div>

            <div className='d-flex justify-content-between'>
                <div>
                    <h6 className='fw-bold'>Launch the verification</h6>
                    <small>This will use up to {bulkLaunchData?.count} verification</small>
                </div>
                <div>
                    <button className="btn btn-danger" onClick={handleLaunch}>
                        Launch the verification
                    </button>
                </div>
            </div>

            <div className='hrLine my-4'></div>

            <div>
                <h6 className='fw-bold'>Includes sources: <span className='fw-normal'>No</span></h6>
            </div>

            </div>
        </div>
      </div>}
    </>
  )
}

export default BulkLaunch