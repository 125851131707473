import React, { useEffect, useRef, useState } from 'react';
import './ImportLeads.css';
import requestInstance from '../../services/request';
import { API_ENDPOINT } from '../../services/Api';
import documentCsv from '../../assets/documentCsv.jpg';
import { RiFileExcel2Line } from 'react-icons/ri';
import axios from "axios";
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { leadNameData, leadTableData } from '../../redux/slices/lead/LeadSlice';

const ImportLeads = () => {
  const [leadNameList, setLeadNameList] = useState();
  const [customLeadNameInput, setCustomLeadNameInput] = useState(false);
  const [customLeadInput, setCustomLeadInput] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonState, setButtonState] = useState(true);
  const dispatch = useDispatch();
  
  const fileInputRef = useRef(null);
  const navigate = useNavigate();



  //   ---API for leads list details---
  const leadsNameListData = () => {
    requestInstance
      .get(API_ENDPOINT.LEAD_NAME_LIST)
      .then((res) => {
        setLeadNameList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };



  const importPostData = () => {
    const formData = new FormData();
    formData.append("leads_name", customLeadInput);
    formData.append("file", selectedFile);

    setButtonState(false);
    requestInstance
      .post(API_ENDPOINT.LEAD_IMPORT, formData)
      .then((res) => {
        toast.success(res.data.message);
        dispatch(leadTableData(res?.data?.data?.file_data));
        dispatch(leadNameData(res?.data?.data?.leads_data?.leads_name));
        setSelectedFile('');
        setLoading(false);
        navigate("leads-configuration");
      })
      .catch((err) => {
        setButtonState(true);
        toast.error(err);
        setLoading(false);
      });

  };

  const handleNextSubmit = () => {
        importPostData(); 
       
  };

  const handleFileChange = () => {
    const newFile = fileInputRef?.current?.files[0];
    if (newFile) {
      setSelectedFile(newFile);
    } else {
      setSelectedFile('No file selected');
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.includes('-')) {
      setErrorMessage('Hyphens are not allowed');
    } else {
      setCustomLeadInput(inputValue);
      setErrorMessage(''); 
    }
  };

  // API for download excelsheet
  const handleExportToFile = () => {
    const token = JSON.parse(localStorage.getItem("find_email"));
    axios({
      url: "https://api.discovemail.com/user/get/excel/data",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}  `,
      },
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;

        toast.success("Download successful");
        link.setAttribute("download", "Discovemail.csv");
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          toast.error(err?.response?.statusText);
        }
      });
  };
  
  useEffect(() => {
    leadsNameListData();
  }, [])
  

  return (
    <div className='container'>
      
      <div className='row mt-4'>
        <div className='col-12 d-flex cursorPointer'>
          <div>
          <Link to="/leads/all-leads">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-circle text-dark" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
            </svg> 
          </Link>
          </div>
          <div>
            <span className='fs-5 fw-semibold px-2'>Import leads</span>
          </div>
        </div>

        <div className='col-10 mt-1'>
          
        <div className="my-2 col-md-12">
                <label htmlFor="select-example" className="fw-bold">Create New List<sup style={{color:"red"}}>*</sup></label>
                <div className="input-group">
                {/* ........CHANGES TO BE MADE HERE ......... */}
                {/* <select
                    className="form-select"
                    id="select-example"
                    name="industry"
                    placeholder="Select destination list"
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      if (selectedValue === "custom") {
                        setCustomLeadNameInput(true);
                        setCustomLeadInput('');
                      }else{
                        setCustomLeadNameInput(false);
                        setCustomLeadInput(selectedValue);
                      }
                    }}
                    value={customLeadInput}
                  >
                    <option value="">Select destination list</option>
                    {leadNameList?.map((val) => (
                      <option key={val.id} value={val.leads_name}>
                        {val.leads_name}
                      </option>
                    ))}
                    <option value="custom" className="createNewImport">Create a new list</option>
                  </select> */}

                  {customLeadNameInput && (
                    <div className="col-md-12 mt-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Lead List Name"
                        value={customLeadInput}
                        id="custom_lead"
                        name="custom_lead"
                        onChange={handleInputChange}
                      />
                      {errorMessage && (
                        <p style={{ color: 'red', marginTop: '5px' }}>{errorMessage}</p>
                      )}
                    </div>
                  )}
              </div>
            </div>
            {/* REQUIRED DIV  */}
            <div className="col-md-12 mt-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Lead List Name"
                        value={customLeadInput}
                        id="custom_lead"
                        name="custom_lead"
                        onChange={handleInputChange}
                      />
                      {errorMessage && (
                        <p style={{ color: 'red', marginTop: '5px' }}>{errorMessage}</p>
                      )}
                    </div>
            <hr className='my-2' />

            <div className='two d-flex align-items-center justify-content-between'>
              <div>
                <h5 className='fw-bold my-3'>Upload a file (CSV)</h5>
                <div className='three d-flex flex-column'>
                <div className='uploadBox d-flex justify-content-center w-100 py-4' onClick={() => fileInputRef.current.click()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-folder2-open" viewBox="0 0 16 16">
                    <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z"/>
                  </svg>
                  <p className='px-2 m-0'>Select a file </p>
                  <input type="file" accept='.csv' ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }}/>
                </div>
                {selectedFile !== '' ? 
                <div className='my-2 px-2 bg-success-subtle fw-bold d-flex justify-content-between pt-2'>
                    <div>
                      <p>{selectedFile?.name}</p> 
                    </div>
                    <div className='cursorPointer' onClick={()=>setSelectedFile('')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                      </svg>
                    </div>
                </div>:null}
                </div>
                <h6 className='my-2'>Your file must use commas or semicolons as column delimiters.</h6>
                <h6 className='my-2'>To be valid, your rows must contain at least an email column.</h6>
              </div>
              <div>
                <img className='documentCsv' src={documentCsv} alt={document} />
              </div>
            </div>

            {/* <div className='three d-flex flex-column'>
                <div className='uploadBox d-flex justify-content-center w-100 py-4' onClick={() => fileInputRef.current.click()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-folder2-open" viewBox="0 0 16 16">
                    <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z"/>
                  </svg>
                  <p className='px-2 m-0'>Select a file </p>
                  <input type="file" accept='.csv' ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }}/>
                </div>
                {selectedFile !== '' ? 
                <div className='mt-2 bg-success-subtle fw-bold d-flex justify-content-between p-3'>
                    <div>
                      <p>{selectedFile?.name}</p> 
                    </div>
                    <div className='cursorPointer' onClick={()=>setSelectedFile('')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                      </svg>
                    </div>
                </div>:null}
            </div> */}

            <div className='four d-flex align-items-center'>
              <div className='px-5'>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
              </div>
              <div className='text-secondary pt-3'>
                <p className='mb-2' onClick={handleExportToFile}> <span className='text-success excelUpload cursorPointer'><RiFileExcel2Line /></span> - Checkout our format</p>  
                <p className='mb-2'>Invalid leads won't be imported.</p>  
                <p className='mb-2'>Attributes from duplicate leads will be used to update the original leads.</p>
                <p className='mb-2'>You can import up to 100,000 leads per file. Additional rows will be skipped.</p>
                <p className='mb-2'>Some special or unexpected characters may be deleted in the file.</p>
              </div>
            </div>

            {/* ---Button--- */}
            <div className="my-4">
                  <button
                    className=" btn btn-disco col-12"
                    disabled={customLeadInput===undefined || customLeadInput?.length === 0 || buttonState === false}
                    onClick={handleNextSubmit}
                    type="submit"
                  >
                    Import
                  </button>
                </div>
        </div>
      </div>
    </div>
  )
}

export default ImportLeads;