import React, { useEffect, useState } from "react";
import { BsFillPersonFill, BsGlobe } from "react-icons/bs";
import { FiSend } from "react-icons/fi";
import { API_ENDPOINT } from "../../../services/Api";
import { toast } from "react-toastify";
import requestInstance from "../../../services/request";
import Cookies from "js-cookie";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../pages/loader/SimpleLoader";

import "./TrailMailFromNameAndCompany.css";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { planStatus } from "../../../redux/slices/user/UserSlice";

export default function TrailMailFromNameAndCompany() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [domain, setDomain] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [EmailNameData, setEmailNameData] = useState({});
  const [TrailEmailFromName, setTrailEmailFromName] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [statusMsg, setStatusMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const token = localStorage.getItem('find_email');
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState();

  useEffect(() => {
    setIsLogin(token?.length > 0 ? true : false);
  }, []);

  // To get User Details API
  const planApi = () => {
    setLoading(true);
    requestInstance
      .get(API_ENDPOINT.ACTIVE_PLAN_STATUS)
      .then((res) => {
        setLoading(false);
        dispatch(planStatus(res?.data?.data));
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handelTrailMailSubmit = (e) => {
    e.preventDefault();

    if (firstName === "") {
      toast.error("First name required");
    } else if (lastName === "") {
      toast.error("Last name required");
    } else if (domain === "") {
      toast.error("Valid domain required");
    } else {
      const TrailEmailDomain = Cookies.get("TrailEmailDomain") || 0;

      if (+TrailEmailDomain > 4 && !isLogin) {
        navigate("/signup");
        toast.error("Limit Reached");
      } else {
        Cookies.set("TrailEmailDomain", +TrailEmailDomain + 1, { expires: 365 });
        setTrailEmailFromName(TrailEmailFromName + 1);
        setIsLoading(true);
        emailfromname();
      }
    }
  };

  const emailfromname = () => {
    let value = {
      first_name: firstName,
      last_name: lastName,
      company: domain,
    };

    requestInstance
      .post(API_ENDPOINT.EMAIL_FROM_NAME, value)
      .then((res) => {
        setIsLoading(false);
        setShowModal(true);
        setEmailNameData(res.data.data);
        if (res?.data?.status === "fail") {
          setStatusMsg(false)
          toast.error(res.data.message);
        } else {
          planApi();
          setStatusMsg(true)
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
        setIsLoading(false);
      });
  };

  const getUserName = (str) => {
    const words = str.split(" ");
    const firstLetters = words.map(word => word.charAt(0));
    const result = firstLetters.join("");
    return result.toUpperCase();
  }

  return (
    <>

      <Helmet>
        <title>Discovemail - Smart email verification tool for finding and checking emails | Top And Quick E-mail Verification For Your Business - Discovemail. | The Best Online Email Extractor And Verifier For Free - Discovemail.</title>

        <meta
          name="description"
          content="Discovemail Is A Quick E-mail Verification Tool. Get Fast & Accurate Results With Discovemail. Improve Your E-mail Deliverability. Try With Discovemail Today For Free."
        />

        <meta
          name="description"
          content="Discovemail Is The Best Online Email Extractor And Verifier For Free. Improve your email marketing with Discovemail. Ensure High-Quality Email Lists For Better Results."
        />
      </Helmet>
      {isLoading ? (
        <Spinner />
      ) : (
        <article className={`${token ? 'col-md-6' : 'col-md-12'} feature-card  col-11 px-5 py-4`}>
          <div className="">
            <h2 className="title pt-2">Email finder</h2>
            <p className="subTitle">We'll never share your email with anyone else.</p>
          </div>
          <form className="" onSubmit={handelTrailMailSubmit}>
            {/* <div className="row my-3"> */}
            <div className="row">
              <div className="col-md-6">

                {/* <label>Firstname</label> */}
                <div className=" input-group mb-4">
                  <input
                    placeholder="First name"
                    id="first_name"
                    name="first_name"
                    type="text"
                    className=" form-control w-100 py-3"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="input-group mb-4">
                  <input
                    id="last_name"
                    name="last_name"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    placeholder="Last name"
                    className="form-control w-100 py-3"
                  />
                </div>
              </div>
            </div>
            {/* ---Email--- */}
            <div className="col-12">
              <div className="input-group mb-4">
                <input
                  id="company"
                  name="company"
                  placeholder="Company website"
                  onChange={(e) => setDomain(e.target.value)}
                  value={domain}
                  className="form-control w-100 py-3 "
                  />
              </div>
            </div>
            {/* ---Button--- */}
            <div className="d-flex justify-content-end">
              <button className="btn btn-disco">
                Find email address
              </button>
            </div>
            {/* </div> */}
          </form>
          {/* </div> */}
          {/* </div> */}

          {showModal ? (
            <Modal show={showModal} size="lg" onHide={() => setShowModal(false)} centered className="emailVerifyModalPopup">
              <Modal.Header closeButton>
                {isLoading ?
                  <div className="dataNotFound py-4 d-flex align-items-center justify-content-center">Data Not Found</div>
                  :
                  <div className="d-flex align-items-center gap-3 py-4">
                    <div className="customeAvator d-flex align-items-center justify-content-center">
                      {getUserName(EmailNameData.full_name)}
                    </div>
                    <div className="emailInfoText">
                      <h5 className="emailInfoTextTitle mb-2">{EmailNameData.full_name}
                        <span className={(statusMsg ? 'validStatus' : 'invalidStatus')}>{statusMsg ? 'Valid' : 'Invalid'}</span>
                      </h5>
                      {EmailNameData.email_data &&
                        EmailNameData.email_data.length > 0 ? (
                        EmailNameData.email_data.map((dat, i) => {
                          return (
                            <p className="mb-1 emailInfoTextSubTitle" key={i}>
                              {dat}
                            </p>
                          );
                        })
                      ) : (
                        <p className="emailInfoTextSubTitle mb-2">NA</p>
                      )}
                    </div>
                  </div>
                }
              </Modal.Header>
              {/* <Modal.Body>
                  {isLoading ? (
                    "isLoading..."
                  ) : (
                    <div className="fw-semibold text-start ms-5 mt-2">
                      {" "}
                      Email Found:
                      <span className="fw-normal bg-primary-subtle">
                        {EmailNameData.email_data &&
                          EmailNameData.email_data.length > 0 ? (
                          EmailNameData.email_data.map((dat, i) => {
                            return (
                              <p className="mb-1" key={i}>
                                <span className="fw-normal bg-primary-subtle w-25">
                                  {dat}
                                </span>
                              </p>
                            );
                          })
                        ) : (
                          <p>NA</p>
                        )}
                      </span>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => setShowModal(false)}>Close</Button>
                </Modal.Footer> */}
            </Modal>
          ) : null}
        </article>
      )}
    </>
  );
}
