import React, { useEffect, useState } from "react";
import Navbar from "../../pages/dashboard/Navbar";
import "./BulkTasks.css";
import { useNavigate } from "react-router-dom";
import noData from '../../assets/no-data.jpg';
import deleteIcon from '../../assets/delete.png';
import Spinner from "../loader/SimpleLoader";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import { toast } from 'react-toastify';
import DataTable from "react-data-table-component";
import useDebounce from '../../hooks/useDebounce';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate } from '../../utils/utils';

const BulkTasks = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [bulkEmailVerifier, setBulkEmailVerifier] = useState();
  const [deleteVerifier, setDeleteVerifier] = useState(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchValue = useDebounce(searchTerm, 900);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const maxDate = new Date();

  const columns = [
    {
      name: 'BULK NAME',
      selector: row => row?.bulk_email_list_name,
      sortable: true,
      grow: 1,
      cell: row => (
        <div className="my-3" onClick={() => trackerApi(row)}>
          <p className='campaignTitle mb-2'>{row.bulk_email_list_name}</p>
          <p className='campaignRecipients m-0 text-secondary'>{row.email_count} email address</p>
        </div>
      ),
    },
    {
      name: 'STATUS',
      selector: row => row?.bulk_email_status,
      sortable: true,
      grow: 1,
      cell: row => (
        <div className={colorPallet[row.bulk_email_status]} onClick={() => trackerApi(row)}>
          {row.bulk_email_status}
        </div>
      ),
    },
    {
      name: 'CREATED',
      selector: row => calculateRelativeTime(row?.created_at),
      sortable: true,
      grow: 1,
      style: {
        color: 'gray'
      }
    },
    {
      name: 'ACTION',
      selector: row => calculateRelativeTime(row?.id),
      grow: 1,
      cell: row => (
        (row?.bulk_email_status !== 'In-Progress') &&
        <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Delete">
          <div data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={(e) => {
            e.stopPropagation();
            setDeleteVerifier(row?.id);
          }}>
            <img src={deleteIcon} alt='img not found' width='22px' className='img-fluid' />
          </div>
        </div >
      ),
    },
    {
      name: '',
      cell: row => '>',
      grow: 0,
      style: {
        color: 'gray'
      }
    },
  ];

  const tableData = bulkEmailVerifier || [];

  const calculateRelativeTime = (dateStr) => {
    const currentDate = new Date();
    const inputDate = new Date(dateStr);

    const timeDifferenceInSeconds = Math.floor((currentDate - inputDate) / 1000);

    if (timeDifferenceInSeconds < 60) {
      return 'Just now';
    } else if (timeDifferenceInSeconds < 3600) {
      const minutes = Math.floor(timeDifferenceInSeconds / 60);
      return `About ${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (timeDifferenceInSeconds < 86400) {
      const hours = Math.floor(timeDifferenceInSeconds / 3600);
      return `About ${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else {
      const days = Math.floor(timeDifferenceInSeconds / 86400);
      return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    }
  };

  const colorPallet = {
    "Draft": 'text-secondary',
    "In-Progress": "text-warning",
    "Completed": "text-success",
    "Ready": "text-info"
  }

  const BulkEmailVerifierDataFetch = () => {
    setIsLoading(true);
    requestInstance.get(`${API_ENDPOINT.BULK_EMAIL_VERIFIER_LIST}?search=${debouncedSearchValue}&start_date=${startDate && formatDate(startDate)}&end_date=${endDate && formatDate(endDate)}`)
      .then((res) => {
        setBulkEmailVerifier(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const trackerApi = (res) => {
    if (res?.bulk_email_status === 'In-Progress') {
      navigate(`/bulk-tasks/bulk-email-verifier-summary/${res?.id}`);
    }
    else if (res?.bulk_email_status === 'Completed') {
      navigate(`/bulk-tasks/bulk-email-verifier-summary/${res?.id}`);
    }
    else if (res?.bulk_email_status === 'Ready') {
      navigate(`/bulk-tasks/new-bulk-email-verifier/${res?.id}`);
    }
    else if (res?.bulk_email_status === 'Draft') {
      navigate(`/bulk-tasks/new-bulk-email-verifier`);
    }
  }

  useEffect(() => {
    BulkEmailVerifierDataFetch();
  }, [debouncedSearchValue, startDate, endDate]);

  const handleBulkVerifierDelete = (id) => {
    const newBody = {
      'email_list_ids': [id],
    }
    requestInstance
      .delete(API_ENDPOINT.BULK_EMAIL_VERIFIER_DELETE, {
        data: newBody,
      })
      .then((res) => {
        BulkEmailVerifierDataFetch();
        toast.success(res.data.message);
      })
      .catch((error) => {
        console.error('Error sending data:', error);
      });
  };

  const handleStartDateChange = (selectedDate) => {
    if (selectedDate && selectedDate instanceof Date && !isNaN(selectedDate)) {
      if (selectedDate > endDate) {
        setEndDate(selectedDate);
      }
      setStartDate(selectedDate);
    } else {
      console.error("Invalid start date");
    }
  };

  const handleEndDateChange = (selectedDate) => {
    if (selectedDate && selectedDate instanceof Date && !isNaN(selectedDate)) {
      if (selectedDate < startDate) {
        setEndDate(startDate);
      } else {
        setEndDate(selectedDate);
      }
    } else {
      console.error("Invalid end date");
    }
  };

  return (
    <>
      <Navbar />
      <div className="bulkTask_main mt_6rem py-5">
        <div className="bulkTask_card bg-white">
          <div className="p-4">
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <div className="d-flex gap-3 align-items-center">
                <div onClick={() => { navigate('/bulks') }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-circle cursorPointer" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                  </svg>
                </div>
                <div>
                  <h6 className='fw-bold m-0'>
                    {props?.pageRoute.replaceAll('-', ' ').toUpperCase()}
                  </h6>
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-end'>
                <input type="search" name="search" id="search" className='form-control w-25 me-5' placeholder='Search..' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                <div className='d-flex align-items-center pe-3 datePickerContainer'>
                  <label>Start Date:</label>
                  <DatePicker selected={startDate} onChange={handleStartDateChange} dateFormat="dd-MM-yyyy" maxDate={maxDate} className='form-control ms-2' />
                </div>
                <div className='d-flex align-items-center me-5 datePickerContainer'>
                  <label className='ms-3'>End Date:</label>
                  <DatePicker selected={endDate} onChange={handleEndDateChange} dateFormat="dd-MM-yyyy" maxDate={maxDate} className='form-control ms-2' />
                </div>
                <button className='btn btn-danger' onClick={() => { navigate('/bulk-tasks/new-bulk-email-verifier') }}>+ New Bulk</button>
              </div>
            </div>
            {isLoading ? <Spinner type="centerSpinner" /> :
              <div>
                <>
                  <DataTable
                    columns={columns}
                    data={tableData}
                    pagination
                    onRowClicked={row => trackerApi(row)}
                  />
                  {!bulkEmailVerifier?.length > 0 && isLoading ? <div className="d-flex justify-content-center container align-items-baseline centeredDiv mt-5">
                    <div>
                      <img className="noDataImg" src={noData} alt="No Data Found" />
                    </div>
                    <div className="">
                      <h5 className="fw-bold">No bulk task, yet.</h5>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          navigate("/bulk-tasks/new-bulk-email-verifier");
                        }}
                      >
                        New bulk
                      </button>
                    </div>
                  </div> : null}
                </>
              </div>}
          </div>
        </div>
      </div>

      {/* ------------------------Delete Modal----------------------- */}
      <div className="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-4" id="deleteModalLabel">Delete Bulk Verifier</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className=''>
                <p>Are you sure to delete the bulk verifier?</p>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={(e) => {
                handleBulkVerifierDelete(deleteVerifier)
              }}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkTasks;
