import React from "react";
import { Helmet } from "react-helmet";
import Navbar_Dash from "../dashboard/Navbar";
import "./Campaign.css";
import ConnectWithGoogle from "./ConnectWithGoogle";
import Footer from "../../new_home/Footer";

export default function index() {
  return (
    <>
      <Helmet>
        <title>
          Campaign |Find Free Verified Email Addresses With Discovemail.
        </title>

        <meta
          name="description"
          content="Discover The Best Free Online Email Marketing Verification Services With Discovemail. Ensure Clean And Valid E-Mails To Boost Your Email Campaigns Successfully."
        />

        <meta
          name="keywords"
          content=" LinkedIn Email Finder,verified email addresses,discovemail,email,linkedin,emailfinder,get email,search email,location finder,linkedin details,
          linkedin user details,linkedin id "
        />
      </Helmet>
      <Navbar_Dash />
      <section className="campaign__area">
        <ConnectWithGoogle />
      </section>


      <Footer />
    </>
  );
}
