import { AiFillStar } from "react-icons/ai";
import testmonialImg1 from "../../assets/testmonial-img1.svg";

export const feedBackData = [
  {
    id: 1,
    name: "Akhil sardar",
    pic: testmonialImg1,
    head: "Lead designer",
    comment:
      "Good product, can fetch details of every user through in the form of excel sheet and other personal data of a person in linkedIn",
  },
  {
    id: 2,
    name: "Hemanth B",
    pic: testmonialImg1,
    head: "Backend Developer",
    comment: "Personalizing the testimonial makes it easier for readers to be empathetic. Adding before and after images, a company logo, or other imagery that demonstrates the value of the service or product is another way to personalize the testimonial.",
  },
  {
    id: 3,
    name: "Vishal",
    pic: testmonialImg1,
    head: "Senior Software Developer",
    comment:
      "It is a good extension for finding bulk emails and also providing valid professional emails. I like this extension.",
  },
  {
    id: 4,
    name: "Madhumanti Biswas",
    pic: testmonialImg1,
    head: "Project Manager",
    comment:
      "The product is very user friendly as it does not really require any kind of guidance and most importantly when one needs to collect bulk email which might take hours that can be done in minutes ",
  },
  
];
