import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../../new_home/Navbar_1'
import Footer from '../../new_home/Footer'
import './OurRecords.css'
import ContactUsSection from '../../components/contact/ContactUsSection'



export default function OurRecords() {

  const [webpages, setWebpages] = useState(10);
  const [website, setWebsite] = useState(5);
  const [professionals, setProfessionals] = useState(2);

  return (
    <>
      <Helmet>
        <title>Discovemail - Terms of service</title>
      </Helmet>

      <Navbar />

      <section className='d-flex align-items-center justify-content-center pt-4 OurRecordesSection'>
        <div className='container-fluid'>
          <div className='row align-items-center'>
            <div className='col-12 text-center pt-5 my-5 '>
              <h2 className='title1'>Our records</h2>
              <h2 className='title2'>Uncovering the methods and<br/> purposes of <span className=' discove-primary'>our records.</span></h2>
              <div className='row justify-content-center'>
                <div className='col-md-8 mt-4'>
                  <p className='description my-4'>As a Discovemail subscriber, you'll enjoy a distinct advantage through our unparalleled database that taps into the vast realm of the public web. Our cutting-edge technology sifts through millions of websites on a daily basis to curate professional contact details, all in full compliance with the most stringent data protection regulations. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='twoColumnRecordsSection'>
        <div className="container">
          <div className="row align-items-center justify-content-center py-5">
            <div className={"col-12 col-md-4 text-center"}>
              <img src="/pic/underconstruction.jpg" alt='img' className='img-fluid' />
            </div>
            <div
              className='col-12 col-md-7  offset-md-1 pe-md-5'
            >
              <h6 className="title pe-md-5 mt-4 mt-lg-0">
                The moral method for email outreach.
              </h6>
              <p className="description ">
                At Discovemail, our mission is to promote transparency in the business landscape. We're dedicated to assisting you in conducting cold email campaigns that not only adhere to stringent data protection regulations but also uphold the privacy and dignity of your recipients.
              </p>

              <p className="description ">
                With our data, you can approach cold email campaigns with increased confidence because you have the ability to transparently communicate to your recipients exactly how you obtained their contact information.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='twoColumnRecordsSection'>
        <div className="container">
          <div className="row flex-lg-row flex-column-reverse align-items-center justify-content-center py-5">
            <div
              className='col-12 col-md-7 pe-md-5'
            >
              <h6 className="title pe-md-5 mt-4 mt-lg-0">
                Dedicated to high-quality data.
              </h6>
              <p className="description">
                The precision of your contact information is pivotal in determining the success of your email outreach efforts, and the tools you employ to acquire email addresses must be unwaveringly reliable for your campaigns to thrive.
              </p>

              <p className="description">
                That's precisely why we place a strong emphasis on the precision of our Email Finder and Email Verifier tools. Our primary objective is to discover as many valid email addresses as we can, all while upholding the highest standards of data quality.
              </p>
            </div>
            <div className={"col-12 col-md-4 offset-md-1 text-center"}>
              <img src="/pic/quality-data.png" alt='img' className='img-fluid' />
            </div>
          </div>
        </div>
      </section>

      <section className='ourRecordsThirdSection'>
        <div className=' container py-5'>
          <div className='row pt-5'>
            <div className='col-md-4 offset-lg-1'>
              <div className='countContainer ps-4 mb-md-4'>
                <h1 className='numberText'>{webpages}K</h1>
                <p className='numberDiscription'>Web pages are scanned every minute.</p>
              </div>
              <div className='countContainer ps-4 mb-md-4'>
                <h1 className='numberText'>{website}M</h1>
                <p className='numberDiscription'>Websites are valuable data resources.</p>
              </div>
              <div className='countContainer ps-4 mb-md-4'>
                <h1 className='numberText'>{professionals}M</h1>
                <p className='numberDiscription'>Professional email addresses are cataloged.</p>
              </div>
            </div>
            <div className='col-md-6 offset-lg-1'>
              <h2 className='mainTitle'>Aligned with the entire web.</h2>
              <p className='mainDiscription w-lg-50'>Each day, Discovemail scans millions of web pages to discover contact details for a wide range of businesses. Similar to a search engine, we maintain a comprehensive web index, which we leverage to obtain authenticated email addresses that power your email outreach efforts.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='connectBusinessesSection'>
        <div className="container py-5">
          <div className="row text-center">
            <div
              className='col-12'
            >
              <h6 className="mainTitle">
                Forging a business connection standard
              </h6>
              <div className='row justify-content-center'>
                <div className='col-12 col-md-8 px-2'>
                  <p className='mainDiscription'>Data providers commonly aggregate data from numerous private and undisclosed sources. At Discovemail, we advocate for transparent data processing that garners approval from all stakeholders, guided by four key principles.</p>
                </div>
                <div className='col-12 col-md-8 mt-5'>
                  <div className="accordion frqAskedQusAccordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          Discovemail exposes public source information data.
                        </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body text-start">
                          <p className=''>In the Domain Search results, all the contacts are accompanied by details regarding when and where we located them.</p>
                          <p>Within the Email Finder, we distinguish between results that are speculative and those sourced from public information. The speculative email addresses are derived from publicly accessible data at the domain name level.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Data with no remaining public sources is deleted.
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body text-start">
                          <p>After a period of six months, we eliminate contact or company information that is no longer supported by public sources. Before taking this action, we label the data as "Removed" for sources that can no longer be located online.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          Data owners retain control over their information.
                        </button>
                      </h2>
                      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body text-start">
                          <p>Any individual can readily delete their contact details by verifying ownership of their email address. The owner has the ability to modify all the fields linked to an email address. In the event of deletion, both the email address and all associated data are completely removed</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                          Website owners can dictate the indexing of their pages.
                        </button>
                      </h2>
                      <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div className="accordion-body text-start">
                          <p>The User-Agent provided on this page can help you identify the robot employed by Discovemail.</p>
                          <p>It adheres to the robots.txt standard, allowing you to prohibit it from accessing your website.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </section>
      <ContactUsSection />
      <Footer />
    </>
  )
}
