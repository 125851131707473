import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate, useSearchParams
} from "react-router-dom";
import { getCurrentUser } from "./utils";
import { useSelector } from "react-redux";
import NotFound from '../pages/not-found/NotFound';


// const ProtectedRoute = ({ component: Component, ...rest }) => {
//   
//   debugger
//   return currentUser ? (
//     <Component {...rest} />
//   ) : (
//     <Navigate
//       to={{
//         pathname: "/",
//         state: { from: rest.location },
//       }}
//     />
//   );
// };

// export { ProtectedRoute };

export const ProtectedRoute = ({ children }) => {
  const [searchParms, setSearchParms] = useSearchParams();
  
  const currentUser = JSON.parse(getCurrentUser());

  if (!currentUser) {
    // user is not authenticated
    if(searchParms.get("redirect")){
      return <Navigate to={`/signin?redirect=${searchParms.get("redirect")}`} />;
    } else {
      return <Navigate to="/signin" />;
    }
    
  }
  return children;
};

export const GAuthVerification = ({ children }) => {
  const authStatus = useSelector((state)=>state.gAuth.authStatus);
  if (!authStatus) {
    return <Navigate to="/campaign" />;
  }
  return children;
};

export const RoleAuth = ({ allowedRoles, children }) => {
  const role = useSelector((state) => state?.user?.value?.role);

  return allowedRoles?.includes(role) ? children : <NotFound />;
};