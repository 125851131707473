import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../../new_home/Navbar_1'
import Footer from '../../new_home/Footer'
import './Compare.css'
import ContactUsSection from '../../components/contact/ContactUsSection'

export default function Compare() {

  const CompareData = [
    {
      "img": '/pic/compare/discovemail.png',
      "price": "$15 for 20k"
    },
    {
      "img": '/pic/compare/compare-briteverify.jpg',
      "price": "$200 for 20k"
    },
    {
      "img": '/pic/compare/compare-zerobounce.jpg',
      "price": "$140 for 20k"
    },
    {
      "img": '/pic/compare/compare-debounce.jpg',
      "price": "$30 for 20k"
    },
    {
      "img": '/pic/compare/compare-emaillistverify.jpg',
      "price": "$48 for 20k"
    },
    {
      "img": '/pic/compare/compare-hunter.jpg',
      "price": "$198 for 20k"
    },
    {
      "img": '/pic/compare/compare-kickbox.jpg',
      "price": "$140 for 20k"
    },
    {
      "img": '/pic/compare/compare-neverbounce.jpg',
      "price": "$100 for 20k"
    },
    {
      "img": '/pic/compare/compare-verifalia.jpg',
      "price": "$130 for 20k"
    },
    {
      "img": '/pic/compare/compare-xverify.jpg',
      "price": "$140 for 20k"
    }
    
  ]

  return (
    <>
      <Helmet>
        <title>Discovemail - Terms of service</title>
      </Helmet>

      <Navbar />

      <section className='d-flex align-items-center justify-content-center pt-4 compareSection'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12 text-center pt-5 my-5 '>
              <p className='firstText'>Discovemail can be compared to other verifiers.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='pt-4 compareSectionTwo'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12 text-center '>
              <p className='mainTitle py-5 '>What is the cost of verifying 20K emails ?</p>
              <div className='row gy-5 g-0 g-lg-5 justify-content-center'>
                {CompareData.map((item, i) => (
                  <div className='col-md-3'>
                    <div className='cards py-3'>
                      <div className=''>
                        <img src={item.img} alt='img' className=' img-fluid' />
                      </div>
                      <h3>{ item.price}</h3>
                    </div>
                  </div>
                ))}
                
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='compareSectionThree'>
        <div className=' container py-5 mt-5'>
          <div className='row'>
            <div className='col-md-5 offset-lg-1'>
              <h1>Get Started Now</h1>              
            </div>
            <div className='col-md-6 d-flex justify-content-center'>
              <a
                className="btn btn-discove py-3"
                href="/email-verifier"
              >
                Try it Free
              </a>
            </div>
          </div>
        </div>
      </section>
      <ContactUsSection />
      <Footer />
    </>
  )
}
