import React, { useState, useEffect } from 'react'
import Spinner from '../../loader/SimpleLoader';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINT } from '../../../services/Api';
import requestInstance from '../../../services/request';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import paginationFactory from "react-bootstrap-table2-paginator";
import {useParams } from 'react-router-dom';


const CampaignTemplate = () => {
  const [isLoading, setIsLoading] = useState();
  const [templatesData, setTemplatesData] = useState([]);
  const [lastIndex, setLastIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(false);
  const [nextData, setNextData] = useState();
  const [prevData, setPrevData] = useState();
  const navigate = useNavigate();
  const params = useParams();


  const columns = [
    {
      name: 'EMAIL TEMPLATE',
      selector: row => row?.template_name,
      // sortable: true,
      grow: 1,
      cell: row => (
        <div className="my-2" onClick={() => handleNewTemplate(row?.id)}>
          <h6>{row?.template_name}</h6>
          <p className=' mb-2'>{row?.subject}</p>
        </div>
      )
    },
    {
      name: 'CREATED',     
      selector: row => calculateRelativeTime(row.created_at),
      sortable: true,
      grow: 1,
      style: {
        color: 'gray'
      }
    },
    {
      name: '',
      cell: row => '>',
      grow: 0,
      style: {
        color: 'gray'
      }
    }
  ];

  const templatesDataFetch = async (page) => {
    setIsLoading(true);
    try {
    const apiPath = `${API_ENDPOINT.CAMPAIGN_DATA_USER_TEMPLATES}?&limit=${perPage}&offset=${(page - 1) * perPage}`;
      const response = await requestInstance.get(apiPath);
      setTemplatesData(response.data.data);
      setTotalRows(response.data.count);
      setCurrentPage(page);
      setLastIndex((page - 1) * perPage);
      setNextData(response.data.next || null);
      setPrevData(response.data.previous || null);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = async (page) => {
    try {
      setPending(true);
    const apiPath = `${API_ENDPOINT.CAMPAIGN_DATA_USER_TEMPLATES}?&limit=${perPage}&offset=${(page - 1) * perPage}`;
      const response = await requestInstance.get(apiPath);
      setTemplatesData(response.data.data);
      setCurrentPage(page); 
      setLastIndex((page - 1) * perPage);
      setTotalRows(response.data.count);
      setIsLoading(false);
      setPending(false);
    } catch (error) {
      setPending(false);
      console.error("Error fetching data:", error);
    }
  };


  const handlePerRowsChange = async (newPerPage) => {
    
    setIsLoading(true);
  const offset = (currentPage - 1) * perPage;
      const apiPath = `${API_ENDPOINT.CAMPAIGN_DATA_USER_TEMPLATES}?&limit=${newPerPage}&offset=${offset}`;
      const response = await requestInstance.get(apiPath);
      setTemplatesData(response.data.data);
      setPerPage(newPerPage);
      setLastIndex((currentPage - 1) * newPerPage);
      setTotalRows(response.data.count);
      setIsLoading(false);
};

paginationFactory({
  sizePerPage: 10,
  hideSizePerPage: true,
  hidePageListOnlyOnePage: true,
});

  const handleCreate = () => {
    navigate(`/campaign/create-template`);
  }

  const calculateRelativeTime = (dateStr) => {
    const currentDate = new Date();
    const inputDate = new Date(dateStr);

    const timeDifferenceInSeconds = Math.floor((currentDate - inputDate) / 1000);

    if (timeDifferenceInSeconds < 60) {
      return 'Just now';
    } else if (timeDifferenceInSeconds < 3600) {
      const minutes = Math.floor(timeDifferenceInSeconds / 60);
      return `About ${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (timeDifferenceInSeconds < 86400) {
      const hours = Math.floor(timeDifferenceInSeconds / 3600);
      return `About ${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else {
      const days = Math.floor(timeDifferenceInSeconds / 86400);
      return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    }
  };

  

  const handleNewTemplate = (val) => {
    setIsLoading(true);
    navigate(`/campaign/template/${val}`);
  }

  useEffect(() => {
    templatesDataFetch(1);
  }, [])


  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='col-12 minHeight84vh'>
          <div className='d-flex align-items-center justify-content-between p-4'>
            <h5 className='text-dark fw-bold'>Email Templates</h5>
            <div className='d-flex align-items-center gap-5'>
              <button className='btn btn-disco px-3' onClick={() => navigate('/campaign/global-templates')}>
                Browse Templates
              </button>
              <button className='btn btn-disco px-3' onClick={handleCreate}>
                + New Template
              </button>
            </div>
          </div>

          <div className='row'>
              <div className='col-12 overflow-auto p-4'>
                <DataTable
                  columns={columns}
                  data={templatesData}
                  progressPending={pending}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationPerPage={perPage}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
            </div>
          </div> 
        </div>
      )}
    </>
  )
}

export default CampaignTemplate;