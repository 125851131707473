import { Outlet } from 'react-router-dom';


import './SettingsLayout.css';
import Navbar_Dash from '../dashboard/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { Helmet } from 'react-helmet';
import SettingSidebar from './SettingSidebar';

function SettingsLayout() {
  return (
    <>
    <Helmet>
    <title>
      Campaign |  Top And Quick E-mail Verification For Your Business - Discovemail. | The Best Online Email Extractor And Verifier For Free - Discovemail.
    </title>
    <meta
      name="description"
      content="Discovemail Is The Best Online Email Extractor And Verifier For Free. Improve your email marketing with Discovemail. Ensure High-Quality Email Lists For Better Results. "
    />
    </Helmet>

    <div className='page'>
        <div className='headerContainer'>
            <Navbar_Dash />
        </div>

        <div className='row mt7rem  p-0 g-0' style={{ flex: 'auto', overflow: 'auto' }}>
          <div className='col-2 scrollLeft'>
           <SettingSidebar />
          </div>
          <div className='col-10 position-relative pageBody scrollRight p-0'>
            {<Outlet /> }
          </div>
        </div>
    </div>
    </>
  );
}

export default SettingsLayout;
