import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../../new_home/Navbar_1'
import Footer from '../../new_home/Footer'
import affiliateHero from '../../assets/affiliate-banner-img.webp'
import affiliateRevenue from '../../assets/affiliate-img3.webp'
import partnerBanner from '../../assets/affiliate-img2.webp'
import testimonialUser1 from '../../assets/affliate-testmonial-user1.svg'
import testimonialUser2 from '../../assets/affliate-testmonial-user2.svg'
import testimonialUser3 from '../../assets/affliate-testmonial-user3.svg'
import affiliateApply from '../../assets/affiliate_apply.svg'
import affiliateTalk from '../../assets/affiliate_talk.svg'
import affiliateEarn from '../../assets/affiliate_earn.svg'
import './affiliateProgram.css'
import ContactUsSection from '../../components/contact/ContactUsSection'

export default function AffiliateProgram() {
  return (
    <>
      <Helmet>
        <title>Discovemail - Terms of service</title>
      </Helmet>

      <Navbar />

      <section className='affiliateFirstSection'>
        <div className='container'>
          <div className="row">
            <div className="col-md-5 col-sm-12 d-flex align-items-center">
              <div className="">
                <h1 className='title1'>Join DiscoveMail Affiliate Program</h1>
                <p className='description mt-2 mb-4'>Use your content to create new revenue streams while assisting professionals with cold email outreach</p>
                <div className="d-flex align-items-center my-4">
                  <a
                    className="btn btn-disco"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSeSLkoj-W5ByO3W25hK4RwoR0PpSDHxNWHmXu-SlL8M1YnVQw/viewform" target='blank'
                  >
                    Partner With Us
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-sm-12 d-flex justify-content-end">
              <img src={affiliateHero} alt="img" className=" img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section className='bg_color_dark2 py-5 affiliateGrowingSection'>
        <div className='container'>
          <div className='row'>
            <h2 className='heading text-center mb-5'>Join our expanding ecosystem.</h2>
            <div className='col-md-4 text-center'>
              <h2 className='title'>2,00+</h2>
              <p className='description'>Consulting partners</p>
            </div>
            <div className='col-md-4 text-center'>
              <h2 className='title'>7+</h2>
              <p className='description'>Countries with <br /> partner presence</p>
            </div>
            <div className='col-md-4 text-center'>
              <h2 className='title'>1.2x</h2>
              <p className='description'>The Partner Ecosystem Is Growing 1.3x Rate To Salesforce.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='bg_color_dark affiliatePartnerTwoColumn pt-5'>
        <div className='container'>
          <div className='row pt-5'>
            <div className='col-md-6 d-flex justify-content-center'>
              <img src={partnerBanner} alt="img not found" className='img-fluid' />
            </div>
            <div className='col-md-6 pe-md-5 pt-5'>
              <h2 className='title1'>Who should partner?</h2>
              <p className='description1 my-4'>Whether you're an agency seeking collaboration or looking for a software integration partnership, let's explore together. Our email verification tools offer versatile applications, supporting seamless communication. We tailor partnerships to suit unique needs. Reach out today to start the conversation!</p>
            </div>
          </div>
        </div>
      </section>
      <section className='py-5 bg_color_dark'>
        <div className='container affiliateSecondSection px-md-5 my-md-5'>

          <div className='row mx-4'>
            <div className='col-lg-6 col-12 px-md-5 py-4 py-lg-5 leftSection'>
              <h3 className='title1 text-start px-4 pt-lg-4'>Generate new revenue.</h3>
              <p className='description my-3 my-lg-3 text-start px-4'>Discovemail offers an endless number of options for establishing new, relevant relationships with professionals. Join us to receive rewards for sharing discovemail  with your followers</p>
            </div>
            <div className='col-lg-6 col-12 px-0 d-flex justify-content-end'>
              <img src={affiliateRevenue} alt="img" className='img-fluid' />
            </div>
          </div>
        </div>
      </section>
      <section className="affiliateThirdSection bg_color_dark">
        <div className='container'>
          <div className="row py-4 px-4">
            <div className="col-lg-6">
              <h2 className='title1'>Getting started.</h2>
              <p className='description1 my-4 '>Describe your plan for promoting Discovemail in your application. You'll receive your affiliate link, once you're approved for the program. Then, you can start making commissions.</p>
              <a
                className="btn btn-disco d-inline-block"
                href="https://docs.google.com/forms/d/e/1FAIpQLSeSLkoj-W5ByO3W25hK4RwoR0PpSDHxNWHmXu-SlL8M1YnVQw/viewform" target='blank'
              >
                Partner With Us
              </a>
            </div>

            <div className="col-lg-6 mt-5 mt-lg-1">
              <div className=' position-relative'>
                <div className="px-lg-5 ps-5">
                  <h6 className='title2 ps-5 ms-4'>Apply.</h6>
                  <div className='dottedLine'>
                    <h6 className='description2 ps-5 mt-4 pb-5 ms-4'>Tell us more about yourself and the Discovemail promotion you intend to run.</h6>
                  </div>
                </div>
                <div className='affiliateImage'>
                  <img src={affiliateApply} alt="img" className='img-fluid' />
                </div>
              </div>

              <div className='position-relative'>
                <div className="ps-5 pe-lg-4">

                  <h6 className='title2 ps-5 ms-4'>Talk about Discovemail.</h6>
                  <div className='dottedLine'>
                    <h6 className='description2 ps-5 ms-4 mt-4 pb-5'>Using your new affiliate link, promote Discovemail in your content.</h6>
                  </div>
                </div>
                <div className='affiliateImage'>
                  <img src={affiliateTalk} alt="img" className='img-fluid' />
                </div>
              </div>

              <div className='position-relative'>
                <div className="ps-5 pe-lg-4">
                  <h6 className='title2 ps-5 ms-4'>Earn recurring revenue.</h6>
                  <h6 className='description2 ps-5 mt-4 pb-5 ms-4'>Receive regular payments from each person you recommend.
                  </h6>
                </div>
                <div className='affiliateImage'>
                  <img src={affiliateEarn} alt="img" className='img-fluid' />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section className='bg_color_dark2 py-5 affiliateTestimonialSection'>
        <div className="container overflow-hidden text-center py-5">
          <div className="row g-5 px-5">
            <h2 className='heading text-center mb-5'>Hear from our Partners</h2>
            <div className="col-md-4">
              <div className='affiliateCard d-flex align-items-end p-4'>
                <div className='affiliateInnerCard position-relative px-3 py-3'>
                  <div className='profilePic'>
                    <img src={testimonialUser2} alt="img not found" className='img-fluid' />
                  </div>
                  <div className="rating mb-2 pt-4">
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                  </div>
                  <p className='description'>If you're a business owner who hasn't integrated Xalor into your arsenal, I highly advise exploring its benefits.</p>
                <h2 className='title'>Wills Lockman</h2>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className='affiliateCard d-flex align-items-end p-4'>
                <div className='affiliateInnerCard position-relative px-3 py-3'>
                  <div className='profilePic'>
                    <img src={testimonialUser1} alt="img not found" className='img-fluid' />
                  </div>
                  <div className="rating mb-2 pt-4">
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                  </div>
                  <p className='description'>That's fantastic to hear! It's always great to hear positive feedback from satisfied users. If you have any questions or need assistance with anything, feel free to reach out to us anytime. We're here to help!</p>
                <h2 className='title'>Alex Nienow</h2>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className='affiliateCard d-flex align-items-end p-4'>
                <div className='affiliateInnerCard position-relative px-3 py-3'>
                  <div className="profilePic">
                    <img src={testimonialUser3} alt="img not found" className='img-fluid' />
                  </div>
                  <div className="rating mb-2 pt-4">
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                    <span>☆</span>
                  </div>
                  <p className='description'>It's great to hear that Pharps has been such a valuable tool for your team! Organizational tools can make a big difference in productivity and efficiency. If you ever need any assistance or have feedback, don't hesitate to let us know. We're here to help!</p>
                <h2 className='title'>Ms.Tony smith</h2>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
      <section className='bg_color_dark py-3 '>
        <div className='container d-grid align-items-center affiliateConsultingPartner mb-5 '>
          <div className='row '>
            <div className='col-md-6 ps-md-5 pt-5 mt-5'>
              <h1 className='title ps-md-4 mt-5'>Become our Consulting <br /> Partner today!</h1>
              <div className="d-flex align-items-center my-4 ps-md-4">
                <a
                  className="btn btn-disco buttonColor"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeSLkoj-W5ByO3W25hK4RwoR0PpSDHxNWHmXu-SlL8M1YnVQw/viewform" target='blank'
                >
                  Become a partner
                </a>
              </div>
            </div>
            <div className='col-md-6'></div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
