import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    authStatus: false,
    signValue: null
}

export const googleAuthSlice = createSlice({
  name: 'gAuth',
  initialState,
  reducers: {
    googleAuth: (state, action) => {
      state.authStatus = action.payload;
    },
    googleSign: (state, action) => {
      state.signValue = action.payload;
    },
    resetGoogleAuth: () => {
      return initialState;
    },
  },
})

export const { googleAuth, googleSign, resetGoogleAuth } = googleAuthSlice.actions

export default googleAuthSlice.reducer