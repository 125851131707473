import React, { useEffect } from "react";
// import { toast } from "react-toastify";
import Profile from "./Profile";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import DiscoveLogo from '../../assets/discovemail_logo.png';
import dashboardIcon from '../../assets/nav-dashboard.svg';
import verifierIcon from '../../assets/nav-verifier.svg';
import finderIcon from '../../assets/nav-finder.svg';
import leadsIcon from '../../assets/nav-leads.svg';
import campaignsIcon from '../../assets/nav-campaigns.svg';
import globeIcon from '../../assets/globe-2-svgrepo-com.svg';
import menuLine from '../../assets/majesticons_menu-line.svg'
import { MdMarkEmailRead, MdOutlineCampaign } from "react-icons/md";
import { PiGoogleChromeLogoLight } from "react-icons/pi";
import { PiUserFocus } from "react-icons/pi";
import { TbDatabaseSearch } from "react-icons/tb";
import { BiSearchAlt } from "react-icons/bi";



function Navbar_Dash() {

  const location = useLocation();
  const { pathname } = location;
  // const navigate = useNavigate();
  // const handlePrice = (e) => {
  //   navigate("/dashboard/price1");
  // };

  // const onLogOut = () => {
  //   navigate("/signin");
  //   localStorage.clear();
  //   toast.success("Logged out successfully.");
  // };

  useEffect(() => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);
  const handleContact = () => {
    window.open(window.location.origin + `/select-plan?contactus`, "_blank");
  };

  const isBulkRoute = (pathname) => {
    return pathname.split('/')[1].split('-')[0] === 'bulk' ? true : false
  }
  return (
    <div>
      <nav className="navbar navbar-expand-lg bg_color_dark  fixed-top py-0">
        <div className="navbar_linear_gradient w-100  py-3 ">
          <div className="container ">
            <div className="row">
              <div className="col-12 d-flex align-items-center justify-content-between">
                <div className=" d-block d-lg-none">
                  <a className="navbar-brand " href="/">
                    <img
                      className="img-fluid"
                      src={DiscoveLogo}
                      alt="logo"
                    ></img>
                  </a>
                </div>

                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavAltMarkup"
                  aria-controls="navbarNavAltMarkup"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  {/* <span className="navbar-toggler-icon"></span> */}
                  <img src={menuLine} alt="img not found" className="img-fluid" />
                </button>
              </div>

              <div
                className="collapse navbar-collapse justify-content-lg-between "
                id="navbarNavAltMarkup"
              >
                <div>
                  <a className="navbar-brand d-none d-lg-block " href="/">
                    <img
                      className="img-fluid"
                      src={DiscoveLogo}
                      alt="logo"
                    ></img>
                  </a>
                </div>
                <div className="d-flex align-items-center">
                  <div className="navbar-nav ">
                    <li className={(pathname === '/dashboard' ? 'activeNavButton ' : '') + "nav-item"}>
                    <Link
                      className="nav-link textnav mx-2"
                      aria-current="page"
                      to="/dashboard"
                    >
                      <img src={dashboardIcon} alt="img not found" className="img-fluid" /> <span className="mx-1">Dashboard</span>
                    </Link>
                    </li>

                    <li className={(pathname === '/verifier' || pathname === '/email-verifier' || pathname === '/domain-verifier' ? 'activeNavButton ' : '') + "nav-item d-none d-lg-block "}>
                      <NavLink
                        exact
                        className="nav-link textnav mx-2 cursorPointer"
                        aria-current="page"
                        id="megamenu"
                        to="/verifier"
                      >
                        <img src={verifierIcon} alt="img not found" className="img-fluid" /> <span className="mx-1">Verifier</span>
                        <ul className="megamenu__dropdown_logged">
                          <div className="megamenu_item_container">
                            <div className="megamenu__item">
                              <li className="title align-self-start pt-3">
                                <ul className="megamenu__list mt-3">
                                  <li className="megamenu__item feature">
                                    <div className="feature__icon">
                                      <Link
                                        to="/email-verifier"
                                        className="text-link"
                                      >
                                        <MdMarkEmailRead />
                                      </Link>
                                    </div>

                                    <div className="feature__content">
                                      <Link
                                        to="/email-verifier"
                                        className="text-link"
                                      >
                                        <span className="title">Email Verifier</span>
                                        <span className="discription">Use our online email validator.</span>
                                      </Link>
                                    </div>
                                  </li>

                                  <li className="megamenu__item feature">
                                    <div className="feature__icon">
                                      <Link
                                        to="/domain-verifier"
                                        className="text-link"
                                      >
                                        <TbDatabaseSearch />
                                      </Link>
                                    </div>

                                    <div className="feature__content">
                                      <Link
                                        to="/domain-verifier"
                                        className="text-link"
                                      >
                                        <span className="title">Domain Verifier</span>
                                        <span className="discription">
                                          Use our a powerful domain search tool
                                        </span>
                                      </Link>
                                    </div>
                                  </li>

                                  {/* <li className="megamenu__item feature">
                              <div className="feature__icon">
                                <Link
                                  to="/email-from-name-and-domain"
                                  className="text-link"
                                >
                                  <PiUserFocus />
                                </Link>
                              </div>

                              <div className="feature__content">
                                <Link
                                  to="/email-from-name-and-domain"
                                  className="text-link"
                                >
                                  <span className="title">
                                    Email from name and domain
                                  </span>
                                  <span className="discription">
                                    Get the email of domain you registered
                                  </span>
                                </Link>
                              </div>
                            </li> */}
                                </ul>
                              </li>
                            </div>
                          </div>
                        </ul>
                      </NavLink>
                    </li>

                    {/* Mobile responsive */}
                    <li className="nav-item d-block d-lg-none">
                      <Link
                        className="nav-link textnav mx-2 cursorPointer"
                        aria-current="page"
                        id="megamenu"
                        to="/verifier"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-shield-check" viewBox="0 0 16 16">
                          <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                          <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                        </svg> <span className="mx-1">Verifier</span>
                        <ul className="megamenu__dropdown">
                          <div className="megamenu_item_container">
                            <div className="megamenu__item">
                              <li className="title align-self-start pt-3">
                                <ul className="megamenu__list mt-3">
                                  <li className="megamenu__item feature">
                                    <div className="feature__icon">
                                      <Link
                                        to="/email-verifier"
                                        className="text-link"
                                      >
                                        <MdMarkEmailRead />
                                      </Link>
                                    </div>

                                    <div className="feature__content">
                                      <Link
                                        to="/email-verifier"
                                        className="text-link"
                                      >
                                        <span className="title">Email Verifier</span>
                                        <span className="discription">Use our online email validator.</span>
                                      </Link>
                                    </div>
                                  </li>

                                  <li className="megamenu__item feature">
                                    <div className="feature__icon">
                                      <Link
                                        to="/domain-verifier"
                                        className="text-link"
                                      >
                                        <TbDatabaseSearch />
                                      </Link>
                                    </div>

                                    <div className="feature__content">
                                      <Link
                                        to="/domain-verifier"
                                        className="text-link"
                                      >
                                        <span className="title">Domain Verifier</span>
                                        <span className="discription">
                                          Use our a powerful domain search tool
                                        </span>
                                      </Link>
                                    </div>
                                  </li>

                                  {/* <li className="megamenu__item feature">
                              <div className="feature__icon">
                                <Link
                                  to="/email-from-name-and-domain"
                                  className="text-link"
                                >
                                  <PiUserFocus />
                                </Link>
                              </div>

                              <div className="feature__content">
                                <Link
                                  to="/email-from-name-and-domain"
                                  className="text-link"
                                >
                                  <span className="title">
                                    Email from name and domain
                                  </span>
                                  <span className="discription">
                                    Get the email of domain you registered
                                  </span>
                                </Link>
                              </div>
                            </li> */}
                                </ul>
                              </li>
                            </div>
                          </div>
                        </ul>
                      </Link>
                    </li>

                    <li className={(pathname === '/finder' || pathname === '/email-from-name-and-domain' || pathname === '/search-company' ? 'activeNavButton ' : '') + "nav-item d-none d-lg-block "}>
                      <Link
                        className="nav-link textnav mx-2 cursorPointer"
                        id="megamenu"
                        to="/finder"
                      >
                        <img src={finderIcon} alt="img not found" className="img-fluid" /> <span className="mx-1">Finder</span>
                        <ul className="megamenu__dropdown_logged">
                          <div className="megamenu_item_container">
                            <div className="megamenu__item">
                              <li className="title align-self-start pt-3">
                                <ul className="megamenu__list mt-3">
                                  <li className="megamenu__item feature">
                                    <div className="feature__icon">
                                      <Link
                                        to="/email-from-name-and-domain"
                                        className="text-link"
                                      >
                                        <PiUserFocus />
                                      </Link>
                                    </div>

                                    <div className="feature__content">
                                      <Link
                                        to="/email-from-name-and-domain"
                                        className="text-link"
                                      >
                                        <span className="title">
                                          Email from name and domain
                                        </span>
                                        <span className="discription">
                                          Get the email of domain you registered
                                        </span>
                                      </Link>
                                    </div>
                                  </li>
                                  <li className="megamenu__item feature">
                                    <div className="feature__icon">
                                      <Link
                                        to="/search-company"
                                        className="text-link"
                                      >
                                        <BiSearchAlt />
                                      </Link>
                                    </div>

                                    <div className="feature__content">
                                      <Link
                                        to="/search-company"
                                        className="text-link"
                                      >
                                        <span className="title">Search Company</span>
                                        <span className="discription">
                                          Get comprehensive latest information
                                        </span>
                                      </Link>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </div>
                          </div>
                        </ul>
                      </Link>
                    </li>

                    {/* Mobile responsive */}
                    <li className="nav-item d-block d-lg-none">
                      <Link
                        className="nav-link textnav mx-2 cursorPointer"
                        id="megamenu"
                        to="/finder"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-binoculars" viewBox="0 0 16 16">
                          <path d="M3 2.5A1.5 1.5 0 0 1 4.5 1h1A1.5 1.5 0 0 1 7 2.5V5h2V2.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5v2.382a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V14.5a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 14.5v-3a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5v3A1.5 1.5 0 0 1 5.5 16h-3A1.5 1.5 0 0 1 1 14.5V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V2.5zM4.5 2a.5.5 0 0 0-.5.5V3h2v-.5a.5.5 0 0 0-.5-.5h-1zM6 4H4v.882a1.5 1.5 0 0 1-.83 1.342l-.894.447A.5.5 0 0 0 2 7.118V13h4v-1.293l-.854-.853A.5.5 0 0 1 5 10.5v-1A1.5 1.5 0 0 1 6.5 8h3A1.5 1.5 0 0 1 11 9.5v1a.5.5 0 0 1-.146.354l-.854.853V13h4V7.118a.5.5 0 0 0-.276-.447l-.895-.447A1.5 1.5 0 0 1 12 4.882V4h-2v1.5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V4zm4-1h2v-.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V3zm4 11h-4v.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V14zm-8 0H2v.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V14z" />
                        </svg><span className="mx-1">Finder</span>
                        <ul className="megamenu__dropdown">
                          <div className="megamenu_item_container">
                            <div className="megamenu__item">
                              <li className="title align-self-start pt-3">
                                <ul className="megamenu__list mt-3">
                                  <li className="megamenu__item feature">
                                    <div className="feature__icon">
                                      <Link
                                        to="/email-from-name-and-domain"
                                        className="text-link"
                                      >
                                        <PiUserFocus />
                                      </Link>
                                    </div>

                                    <div className="feature__content">
                                      <Link
                                        to="/email-from-name-and-domain"
                                        className="text-link"
                                      >
                                        <span className="title">
                                          Email from name and domain
                                        </span>
                                        <span className="discription">
                                          Get the email of domain you registered
                                        </span>
                                      </Link>
                                    </div>
                                  </li>
                                  <li className="megamenu__item feature">
                                    <div className="feature__icon">
                                      <Link
                                        to="/search-company"
                                        className="text-link"
                                      >
                                        <BiSearchAlt />
                                      </Link>
                                    </div>

                                    <div className="feature__content">
                                      <Link
                                        to="/search-company"
                                        className="text-link"
                                      >
                                        <span className="title">Search Company</span>
                                        <span className="discription">
                                          Get comprehensive latest information
                                        </span>
                                      </Link>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </div>
                          </div>
                        </ul>
                      </Link>
                    </li>

                    {/* <Link
                className="nav-link txtnav mx-2"
                aria-current="page"
                to="/email-verifier"
              >
                Email Verifier
              </Link> */}

                    {/* <Link
                className="nav-link txtnav mx-2"
                aria-current="page"
                to="/domain-verifier"
              >
                Domain Verifier
              </Link> */}

                    {/* <Link
                className="nav-link txtnav mx-2"
                aria-current="page"
                to="/email-from-name-and-domain"
              >
                Email from name and domain
              </Link> */}

                    {/* <Link
                className="nav-link txtnav mx-2"
                aria-current="page"
                to="/search-company"
              >
                Search Company
              </Link> */}


                    {/* <a
                  className="nav-link  fs-5 text-dark  txtnav mx-3"
                  id="workControl"
                  href="#works"
                >
                  How It Works
                </a> */}

                    {/* <a
                  className="nav-link  fs-5 text-dark  txtnav mx-3"
                  id="priceControl"
                  href="#price"
                >
                  Pricing
                </a> */}
                    {/* <a
                className="nav-link txtnav mx-2"
                href="https://chrome.google.com/webstore/detail/discovemail/hbfdhianoohibldpghooaeeamjhcjhjn"
                target="blank"
              >
                Our Extension
              </a> */}
                    
                    <li className={(pathname === '/bulks' || isBulkRoute(pathname)  ? 'activeNavButton ' : '') + "nav-item"}>
                      <Link
                        className="nav-link textnav mx-2 "
                        to="/bulks"
                      // target="_blank"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-boxes" viewBox="0 0 16 16">
                          <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z" />
                        </svg><span className="mx-1">Bulk</span>
                      </Link>
                    </li>

                    <li className={(pathname === '/leads/all-leads' ? 'activeNavButton ' : '') + "nav-item"}>
                      <Link
                        className="nav-link textnav mx-2"
                        to="/leads/all-leads"
                      // target="_blank"
                      >
                        <img src={leadsIcon} alt="img not found" className="img-fluid" /> <span className="mx-2">Leads</span>
                      </Link>
                    </li>

                    <li className={(pathname.split('/')[1] === 'campaign' ? 'activeNavButton ' : '') + "nav-item"}>
                      <Link
                        className="nav-link textnav mx-2"
                        to="/campaign"
                        target="_self"
                      >
                        <img src={campaignsIcon} alt="img not found" className="img-fluid" /> <span className="mx-2">Campaigns</span>
                      </Link>
                    </li>

                    {/* ..........THIS IS DISCOVER TESTING PART ............ */}

                    <li className={(pathname.split('/')[1] === 'discover' ? 'activeNavButton ' : '') + "nav-item"}>
                      <Link
                        className="nav-link textnav mx-2"
                        to="/discover"
                        target="_self"
                      >
                        <img src={globeIcon} style={{height:"16px", width:"16px"}} alt="img not found" className="img-fluid" /> <span className="px-2">Discover-(Beta)</span>
                      </Link>
                    </li>


                  {/*................... END OF DISCOVER............  */}




                    {/* Contact Us */}
                    {/* <Link
                className="nav-link txtnav mx-2"
                id="contact"
                onClick={() => handleContact()}
                href="# "
              >                
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-outbound-fill" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"/>
                </svg>
              </Link> */}
                  </div>

                  {/* ---Dashboard--- */}
                  <div className="d-flex align-items-center" id="dashboard">
                    {/* <a
                className="mx-3 mb-2 btn btn-outline-danger btn2"
                href="/dashboard/price1"
              >
                Plan
              </a> */}
                    <div className="" id="profile">
                      <Profile />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar_Dash;

