export const API_ENDPOINT = {
  LOGIN_API: "/user/login/",
  SIGNUP_API: "/user/signup/",
  LINKEDIN_SCRAPER_API: "/user/linkedin/username/",
  GET_ALL_SCRAPPED_DATA_API: "/user/get/user/collected/data/",
  SEND_RESET_LINK: "/user/send/password/reset/email/",
  PASSWORD_RESET_API: "/user/password/reset/",
  EMAIL_FROM_NAME: "/user/email/from/name/",
  DOMAIN_VERIFY: "user/verify/domain/",
  EMAIL_VERIFY: "user/verify/email/",
  TRAIL_EMAIL_VERIFY: "user/verify/email/",
  DISCOVER_COMPANY:"/user/companies/",
  DISCOVER_COMPANY_LIST:"user/companies/industries",
  FOLLOWUP_LISTING: "campaign/followup/listing/",
  FOLLOWUP_STOP:"campaign/followup/email/status/change/",
  FOLLOWUP_MAIL_DISLAY: "/campaign/campaign/followup/data/fetch/list",
  CAMPAIGN_AUDIENCE_DETAILS: "/campaign/audience/details/",
  CAMPAIGN_AUDIENCE_DOWNLOADS: "/campaign/download/audience/details",
  CAMPAIGN_SUMMARY_CONTENTS: "/campaign/content/details",
  USER_PROFILE: "/user/user/profile",
  EDIT_PROFILE: "/user/user/edit_profile ",
  EDIT_PROFILE_PIC: "/user/user/profile/pic/upload ",
  COMPANY: "/user/user/company/details",
  COMPANY_INFO: "/user/user/company/all/details",
  COMPANY_PHONE_INFO: "/user/user/company/details/",
  COOKIES_DATA_API: "/user/fetch/cookie/",
  CAMPAIGN: "/campaign/gmail/login",
  CALLBACK_CAMPAIGN_API: "/campaign/gmail/callback",
  CAMPAIGN_GMAIL_API: "/campaign/send/email",
  LEAD_NAME_LIST:'/campaign/user/fetch/leads/list',
  LEAD_IMPORT:'/campaign/user/fetch/file/leads/data',
  LEAD_CONFIG: '/campaign/user/import/leads/data',
  LEAD_UPDATE: '/campaign/update/lead',
  LEAD_DELETE: '/campaign/delete/lead',
  CAMPAIGN_SIGNATURE_API:'/campaign/user/email/details',
  LEAD_DATA_FETCH: '/campaign/user/leads/data/fetch',
  CAMPAIGN_DATA_CREATE: '/campaign/user/create/new/campaign/list/create',
  CAMPAIGN_DATA_FETCH: '/campaign/user/fetch/campaign/list',
  CAMPAIGN_CREATE_CONTENT:'/campaign/user/create/campaign',
  CAMPAIGN_FOLLOWUP_MAIL:'campaign/campaign/createfollowup/email',
  CAMPAIGN_CREATE_AUDIENCE:'/campaign/user/import/leads/into/campaign',
  CAMPAIGN_CREATE_SETTINGS:'/campaign/user/send/email/preferences',
  CAMPAIGN_REVIEW_DATA:'/campaign/create/email/content/for/user/leads',
  CAMPAIGN_LAUNCH_API: '/campaign/user/schedule/send/email',
  CAMPAIGN_PAUSE: 'campaign/campaign/pause',
  CAMPAIGN_RESUME: 'campaign/campaign/resume',
  CAMPAIGN_DELETE: 'campaign/campaign/draft/delete',
  CAMPAIGN_RENAME: 'campaign/campaign/rename',
  CAMPAIGN_ARCHIVE: 'campaign/campaign/archive',
  CAMPAIGN_UNARCHIVE: 'campaign/campaign/unarchive',
  CAMPAIGN_ARCHIVE_LIST: '/campaign/campaign/all/archive/list',
  CAMPAIGN_EDIT: 'campaign/campaign/edit',
  CAMPAIGN_DATA_USER_TEMPLATES: 'campaign/get/user/templates',
  CAMPAIGN_DATA_EACH_TEMPLATES: 'campaign/get/each/user/templates',
  CAMPAIGN_CREATE_TEMPLATES: 'campaign/create/template',
  CAMPAIGN_UPDATE_TEMPLATES: 'campaign/updated/user/templates',
  CAMPAIGN_DELETE_TEMPLATES: 'campaign/delete/user/template',
  CONTACT_API: '/user/new/user/faq/get/started',
  TRACKER_API: '/campaign/user/campaign/process/tracker',
  CAMPAIGN_SUMMARY_DATA: '/campaign/user/all/campaign/summary',
  CAMPAIGN_SUMMARY_CONTENT: '/campaign/campaign/summary/email/content',
  BULK_EMAIL_VERIFIER_CONTENT:'/bulkverification/user/bulk/email/verification/content',
  BULK_EMAIL_VERIFIER_SUMMARY:'/bulkverification/user/bulk/email/summary',
  BULK_EMAIL_VERIFIER_LAUNCH:'/bulkverification/user/bulk/email/launch/verification',
  BULK_EMAIL_VERIFIER_LIST:'/bulkverification/user/fetch/bulk/email/name/list',
  BULK_EMAIL_VERIFIER_SUMMARY_LAST:'/bulkverification/user/bulk/email/summary/content',
  BULK_EMAIL_VERIFIER_INDIVIDUAL_DOWNLOAD: '/bulkverification/user/get/bulk/email/verification/csv/export',
  BULK_EMAIL_VERIFIER_DELETE: '/bulkverification/bulk/email/verification/list/delete',
  BULK_EMAIL_VERIFIER_RENAME: '/bulkverification/rename/bulk/email/verifier',
  BULK_EMAIL_FINDER_CONTENT: '/bulkverification/user/bulk/email/finder/csv/import',
  BULK_EMAIL_FINDER_LIST: '/bulkverification/user/bulk/email/count',
  BULK_EMAIL_FINDER_EACH_COUNT: '/bulkverification/user/bulk/email/each/count',
  BULK_EMAIL_FINDER_VALID_DOMAIN_VERIFIER: '/bulkverification/user/bulk/valid/domain/verifier',
  BULK_EMAIL_FINDER_LAUNCH: '/bulkverification/user/bulk/email/launcher',
  BULK_EMAIL_FINDER_INDIVIDUAL_DOWNLOAD: 'bulkverification/user/bulk/email/finder/csv/export',
  BULK_EMAIL_FINDER_DELETE: '/bulkverification/bulk/email/finder/list/delete',
  BULK_EMAIL_FINDER_RENAME: '/bulkverification/rename/bulk/email/finder',
  STRIPE_PAYMENT_CHECKOUT:'/payment/stripe/payment/checkout',
  STRIPE_PAYMENT_HANDLE: '/payment/stripe/success/payment/handle',
  ACTIVE_PLAN_STATUS: '/payment/user/active/plan/status/report',
  GOOGLE_AUTH_REVOKE: '/campaign/revoke/gmail/access',
  CAMPAIGN_STATISTICS: '/campaign/campaign/statistics',
  GLOBAL_TEMPLATES_FILTER_LIST: '/campaign/template/type/and/name/list',
  GLOBAL_TEMPLATES_LIST: '/campaign/global/email/template/list',
  GLOBAL_TEMPLATES_INDIVIDUAL: '/campaign/single/global/email/template/',
  REPORTING_CHART_LIST: '/campaign/all/campaigns/statistics',
  CAMPAIGN_STATS_EXCEL: '/campaign/email/statistics/download',
  CAMPAIGN_STATS_FOLLOW_UP: '/campaign/followup/email/statistics',
  CAMPAIGN_SCHEDULED_FOLLOW_UP: '/campaign/campaign/followup/scheduled/data',
  CAMPAIGN_MANUAL_LEAD_CREATE: '/campaign/manual/add/leads',
  TEAM_INVITE: '/user/invite/user',
  TEAM_USER_LIST: '/user/user/team/list',
  COMPANY_TYPE_OPTIONS: '/user/companies/company-type/list',
  COMPANY_DISCOVER_EMAIL_ADDRESSES: '/user/emails/by/company/name',
  GOOGLE_SIGN_IN: '/user/google/authorization/redirect',
  GOOGLE_SIGN_IN_VERIFY: '/user/google/oauth/login',
};
