import React from "react";
import Slider from "react-slick";
import { feedBackData } from "../resource/data/feedback";
import Card1 from "../components/cards/Card1";
import Accordion from "react-bootstrap/Accordion";
import "./New.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function FeedbackCard() {
  var settings = {
    // className: "slider variable-width",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className="testmonialsSection pb-5">
      <div className="container px-0 py-4 overflow-hidden">
        <h1 className="mainTitle text-center ">What People Say About Us!</h1>
        <Slider {...settings}>
          {feedBackData.map((ele) => {
            return <Card1 props={ele} key={ele.id} />;
          })}
        </Slider>

      </div>
    </section>
  );
}

export default FeedbackCard;
