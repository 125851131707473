import React from "react";
import { AiFillStar } from "react-icons/ai";
import Quotes from "../../assets/quotes.png"
import testmonialUser1 from "../../assets/testmonial-user1.svg"
import "./Card.css";

function Card1(props) {
  return (
    
      <div className="row justify-content-center py-5 mb-5">
        <div className="col-md-5 d-flex justify-content-center">
        <div className="text-center mx-4 avatar">
          <span className="avatarText">{props?.props?.name?.charAt(0)}</span>
        </div>
        </div>
        <div className="col-lg-6 px-5 px-lg-0">
          <p className="commentText my-4">{props?.props?.comment}</p>
        <p className="nameText">{props?.props?.name}</p>
        <div className="rating">
          <span>☆</span>
          <span>☆</span>
          <span>☆</span>
          <span>☆</span>
          <span>☆</span>
        </div>
        </div>
      </div>
    // <div>
    //   <div className="d-flex justify-content-around card_1 my-3">
    //     <div className="card_main">
    //       <div>
    //         <img className="card_img" src="/pic/blankperson.png" alt="images"></img>
    //       </div>
    //       <div className="text-dark px-3">
    //         <p className="fs-6 text-start">{props?.props?.comment}</p>
    //       </div>
    //       <div className="card_first">
    //         <h5 className="pt-3 fs-4 text-center">{props?.props?.name}</h5>
    //       </div>
    //       <div className="d-flex justify-content-around">
    //         <p className="txt_color fs-4 fw-semibold">{props?.props?.head}</p>
    //       </div>
    //       <div>
    //         <img className="card_img" src="/pic/blankperson.png" alt="images"></img>
    //       </div>
    //     </div>

    //     {/* ---2nd Card--- */}
    //     {/* <div className="card_main">
    //       <div className="card_first">
    //         <h5 className="pt-3">Anupam</h5>
    //       </div>
    //       <div>
    //         <img className="card_img" src="/pic/blankperson.png"></img>
    //       </div>
    //       <div className="d-flex justify-content-around">
    //         <p className="text-primary fw-semibold">Fascinating</p>
    //         <p className="star">
    //           <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar />{" "}
    //           <AiFillStar />
    //         </p>
    //       </div>
    //       <div className="text-dark px-3">
    //       Very interesting and unique website for getting user details.
    //       </div>
    //     </div> */}

    //     {/* ---3rd card--- */}
    //     {/* <div className="card_main">
    //       <div className="card_first">
    //         <h5 className="pt-3">Vishal</h5>
    //       </div>
    //       <div>
    //         <img className="card_img" src="/pic/blankperson.png"></img>
    //       </div>
    //       <div className="d-flex justify-content-around">
    //         <p className="text-primary fw-semibold">Gripping</p>
    //         <p className="star">
    //           <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar />{" "}
    //           <AiFillStar />
    //         </p>
    //       </div>
    //       <div className="text-dark px-3">
    //       It is a good extension for finding bulk emails and also providing valid professional emails. I like this extension.
    //       </div>
    //     </div> */}
    //   </div>
    // </div>
  );
}

export default Card1;
