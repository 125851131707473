import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Megamenu.css";

import { MdMarkEmailRead, MdOutlineCampaign } from "react-icons/md";
import { PiGoogleChromeLogoLight } from "react-icons/pi";
import { PiUserFocus } from "react-icons/pi";
import { TbDatabaseSearch } from "react-icons/tb";
import { LiaMailBulkSolid } from "react-icons/lia";
import { BiSearchAlt } from "react-icons/bi";

import DiscoveLogo from '../assets/discovemail_logo.png'
import menuLine from '../assets/majesticons_menu-line.svg'
import { Link, NavLink } from "react-router-dom";
import { LuFileSpreadsheet } from "react-icons/lu";
import Profile from "../pages/dashboard/Profile";

function Navbar() {
  let navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);

  const handleScroll = () => {
    window.scrollTo(0, 0);
  }

  function priceScroll() {
    if (window.location.pathname == "/") {
      const element = document.getElementById("price");
      element.scrollIntoView();
    } else {
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById("price");
        element.scrollIntoView();
      }, 50);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('find_email');
    if (token) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [])


  return (
    <div>
      <nav
        className={(window.location.pathname === "/faq" || window.location.pathname === "/company" ? 'bg_color_dark2 ' : 'bg_color_dark ') + "navbar navbar-expand-lg fixed-top"}
        id="navbar-example2"
      >
        <div className="container mt-4">
          <div>
            <a className="navbar-brand ms-0 " href="/">
              <img
                className="newlogo ms-0 img-fluid"
                src={DiscoveLogo}
                alt="logo" />
            </a>
          </div>
          <button
            className="navbar-toggler me-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <span className="navbar-toggler-icon"></span> */}
            <img src={menuLine} alt="img not found" className="img-fluid" />
          </button>

          <div
            className="collapse gap-5 navbar-collapse justify-content-lg-end main-nav"
            id="navbarNavAltMarkup"
          >
            <ul className="navbar-nav nav-pills">
              <li className="nav-item pe-4">
                <NavLink
                  exact
                  className="nav-link textnav mx-2"
                  aria-current="page"
                  id="homeControl"
                  activeClassName="activeNav"
                  // to="/?query=Home#home"
                  to="/"
                  onClick={handleScroll}
                >
                  HOME
                </NavLink>
              </li>

              <li className="nav-item pe-4">
                <div
                  className={(window.location.pathname === "/email-verifier" || window.location.pathname === "/domain-verifier" || window.location.pathname === "/email-from-name-and-domain" || window.location.pathname === "/search-company" ? "activeNav " : "") + "nav-link megamenu__product textnav mx-2"}
                  id="megamenu"
                  href="#products"
                >
                  PRODUCT
                  <ul className="megamenu__dropdown">
                    <div className="megamenu_item_container">
                      <div className="megamenu__item">
                        <li className="title align-self-start pt-3">
                          <ul className="megamenu__list mt-3">
                            <li className="megamenu__item feature">
                              <div className="feature__icon">
                                <Link
                                  to="/email-verifier"
                                  className="text-link"
                                >
                                  <MdMarkEmailRead />
                                </Link>
                              </div>

                              <div className="feature__content">
                                <Link
                                  to="/email-verifier"
                                  className="text-link"
                                >
                                  <span className="title">Email Verifier</span>
                                  <span className="discription">Use our online email validator.</span>
                                </Link>
                              </div>
                            </li>

                            <li className="megamenu__item feature">
                              <div className="feature__icon">
                                <Link
                                  to="/domain-verifier"
                                  className="text-link"
                                >
                                  <TbDatabaseSearch />
                                </Link>
                              </div>

                              <div className="feature__content">
                                <Link
                                  to="/domain-verifier"
                                  className="text-link"
                                >
                                  <span className="title">Domain Verifier</span>
                                  <span className="discription">
                                    Use our a powerful domain search tool
                                  </span>
                                </Link>
                              </div>
                            </li>

                            <li className="megamenu__item feature">
                              <div className="feature__icon">
                                <Link
                                  to="/email-from-name-and-domain"
                                  className="text-link"
                                >
                                  <PiUserFocus />
                                </Link>
                              </div>

                              <div className="feature__content">
                                <Link
                                  to="/email-from-name-and-domain"
                                  className="text-link"
                                >
                                  <span className="title">
                                    Email from name and domain
                                  </span>
                                  <span className="discription">
                                    Get the email of domain you registered
                                  </span>
                                </Link>
                              </div>
                            </li>

                            <li className="megamenu__item feature">
                              <div className="feature__icon">
                                <a
                                  href="/search-company"
                                  className="text-link"
                                >
                                  <BiSearchAlt />
                                </a>
                              </div>

                              <div className="feature__content">
                                <a
                                  href="/search-company"
                                  className="text-link"
                                >
                                  <span className="title">Search Company</span>
                                  <span className="discription">
                                    Get comprehensive latest information
                                  </span>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li className="title align-self-start">
                          <ul className="megamenu__list mt-3">


                            <li className="megamenu__item">
                              <div className="feature__icon">
                                <a
                                  href="https://chrome.google.com/webstore/detail/discovemail/hbfdhianoohibldpghooaeeamjhcjhjn"
                                  className="text-link"
                                >
                                  <PiGoogleChromeLogoLight />
                                </a>
                              </div>

                              <div className="feature__content">
                                <a
                                  href="https://chrome.google.com/webstore/detail/discovemail/hbfdhianoohibldpghooaeeamjhcjhjn"
                                  className="text-link"
                                >
                                  <span className="title">Chrome Extenstion</span>
                                  <span className="discription">Collect lead from extension.</span>
                                </a>
                              </div>
                            </li>

                            <li className="megamenu__item align-items-center">
                              <div className="feature__icon">
                                <a
                                  href="/our-addon"
                                  className="text-link"
                                >
                                  <LuFileSpreadsheet />
                                </a>
                              </div>

                              <div className="feature__content">
                                <a
                                  href="/our-addon"
                                  className="text-link"
                                >
                                  <span className="title">Google Sheet Add-on</span>
                                  {/* <span className="discription">Collect lead from extension.</span> */}
                                </a>
                              </div>
                            </li>

                            <li className="megamenu__item align-items-center">
                              <div className="feature__icon">
                                <a
                                  href="/campaign"
                                  className="text-link"
                                >
                                  <MdOutlineCampaign />
                                </a>
                              </div>

                              <div className="feature__content">
                                <a
                                  href="/campaign"
                                  className="text-link"
                                >
                                  <span className="title">Campaigns</span>
                                  {/* <span className="discription">Collect lead from extension.</span> */}
                                </a>
                              </div>
                            </li>

                            <li className="megamenu__item align-items-center">
                              <div className="feature__icon">
                                <a
                                  href="/campaign"
                                  className="text-link"
                                >
                                  <LiaMailBulkSolid />
                                </a>
                              </div>

                              <div className="feature__content">
                                <a
                                  href="/campaign"
                                  className="text-link"
                                >
                                  <span className="title">Bulks</span>
                                  {/* <span className="discription">Collect lead from extension.</span> */}
                                </a>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </li>

              {/* <li className="nav-item">
                <a
                  className="nav-link fs-5 txtnav mx-3"
                  id="workControl"
                  href="#works"
                >
                  How It Works
                </a>
              </li> */}

              <li className="nav-item pe-4">
                <NavLink
                  exact
                  className="nav-link textnav mx-2"
                  aria-current="page"
                  activeClassName="activeNav"
                  to="/pricing"
                >
                  PRICING
                </NavLink>
              </li>

              <li className="nav-item pe-4">
                <a
                  className="nav-link textnav mx-2"
                  id="contactControl"
                  href="https://blog.discovemail.com/"
                  target="blank"
                >
                  BLOG
                </a>
              </li>

              <li className="nav-item pe-4">
                <NavLink
                  exact
                  className="nav-link textnav mx-2"
                  aria-current="page"
                  activeClassName="activeNav"
                  to="/company"
                >
                  COMPANY
                </NavLink>
              </li>
            </ul>

            {!isLogin ? <div className="d-flex align-items-center justify-content-end flex-row-reverse flex-lg-row" id="general">
              <a
                className="mx-3 btn textnav"
                href="/signin"
              >
                SIGN IN
              </a>
              <a
                className="btn btn-disco px-5"
                href="/signup"
              >
                SIGN UP
              </a>
            </div> : <Profile />}

            {/* ---Dashboard--- */}
          </div>

        </div>
      </nav>
    </div>
  );
}

export default Navbar;
