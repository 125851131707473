import { combineReducers } from "@reduxjs/toolkit";
import  campaignSlice from './slices/campaign/CampaignSlice';
import  userSlice  from "./slices/user/UserSlice";
import  googleAuthSlice  from "./slices/googleAuth/GoogleAuthSlice";
import  leadSlice from "./slices/lead/LeadSlice";

const rootReducer = combineReducers({
    campaign:campaignSlice,
    user:userSlice,
    gAuth:googleAuthSlice,
    lead:leadSlice,
})

export default rootReducer;