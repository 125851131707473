import React from "react";
import { useNavigate } from "react-router-dom";
import DisoveLogo from '../../assets/discovemail_logo.svg'

function PageNavbar() {
  // const handleContact = () => {
  //   window.open(window.location.origin + `/select-plan?contactus`, "_blank");
  // };
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("find_email"));

  const handleContactClick = () => {
    if (user) {
      window.open(window.location.origin + `/select-plan?contactus`, "_blank");
    } else {
      //navigate('/?query=Contact#contact');
      window.open(window.location.origin + `/?query=Contact#contact`);
    }
  };

  function priceScroll() {
    if (window.location.pathname == "/") {
      const element = document.getElementById("price");
      element.scrollIntoView();
    } else {
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById("price");
        element.scrollIntoView();
      }, 50);
    }
  }

  return (
    <div>
     <nav className="navbar navbar-expand-lg fixed-top  bg-light-subtle page_nav">
          <div className="container-fluid ">
            <div>
            <a className="navbar-brand " href="/">
              <img className="newlogo ms-5" src={DisoveLogo} alt="logo"></img>
            </a>
            </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
            <div
              className="collapse navbar-collapse justify-content-lg-end me-5 "
              id="navbarNavAltMarkup"
            >
              <div className="navbar-nav ">
                <a
                className="nav-link mx-3 txtnav fs-5"
                  aria-current="page"
                  href="./"
                  
                >
                  Home
                </a>
                {/* <a
                  className="nav-link  fs-5 text-dark  txtnav mx-3"
                  id="workControl"
                  href="./?query=Work#works"
                >
                  How It Works
                </a> */}
               
                <a
                  className="nav-link txtnav mx-3 fs-5"
                  id="priceControl"
                onClick={priceScroll}
                >
                  Pricing
                </a>
                <a
              className="nav-link txtnav mx-3 fs-5"
              href="https://chrome.google.com/webstore/detail/discovemail/hbfdhianoohibldpghooaeeamjhcjhjn"
              target="blank"
              rel="OUR EXTENSION"
            >
              Our Extension
            </a>
                <a
                className="nav-link txtnav mx-3 fs-5"
                  id="contactControl"
                  onClick={handleContactClick}
                  href="#contact"
                 
                >
                  Contact Us
                </a>
                <a
                  className="nav-link txtnav mx-3 fs-5"
                  id="protectedprice"
                  href="/dashboard/price1"
                 
                >
                  Plan
                </a>
               
              </div>
            </div>
            
          </div>
        </nav>
    </div>
  );
}

export default PageNavbar;
