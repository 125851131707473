import React, { useEffect } from 'react'
import { useState } from 'react';
import ReactQuill from 'react-quill'
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const EmailModal = ({currentRecord}) => {
    const [subject, setSubject] = useState();
    const [message, setMessage] = useState();
    const [addSign, setAddSign] = useState(false);
    const [receiverEmail, setReceiverEmail] = useState("");
    const signature = useSelector((state)=>state?.gAuth?.signValue?.signature);
    

  useEffect(() => {
    setReceiverEmail(currentRecord?.email ? currentRecord?.email : '')
  }, [currentRecord?.email]);


  const handelSend = () => {
    if(subject === ""){
      toast.warn("Please enter subject")
    }

    if(message === ""){
      toast.warn("Please enter message")
    }

    if(subject !== "" && message !== ""){
      requestInstance.post(API_ENDPOINT.CAMPAIGN_GMAIL_API, {
        "receiver": receiverEmail,
        "subject" : subject,
        "message" : message
      })
      .then(res => {
        if(res.status === 200){
          toast.success(res?.data?.message)
          setMessage("");
          setSubject("");
          setReceiverEmail("");
          setAddSign(false);
        }
      })
      .catch(err => toast.error(err.data.message))
    }

  }


  return (
    <div class="modal fade email-modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">New Email</h1>
                        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-0">
                        <div className='d-flex justify-content-start align-items-center py-3'>
                            <div id="avatar" className="text-center mx-3">
                                <span className="profile_firstapl">{currentRecord?.first_name ? currentRecord?.first_name.slice(0,1) : 'No Data Found'}</span>
                            </div>
                            <div>
                            <p className='mb-0 text-dark'>{currentRecord?.first_name ? currentRecord?.first_name : 'No Data Found'}<small className='text-secondary'> ({currentRecord?.email ? currentRecord?.email : 'No Data Found'})</small></p>
                            <small className='mb-0 text-secondary'>{currentRecord?.designation ? currentRecord?.designation : 'No Data Found'} at {currentRecord?.company_name ? currentRecord?.company_name : 'No Data Found'}</small>
                            </div>
                        </div>
                       <div className='borderBottom text-secondary'></div>
                       <div className="row h-100">
                  <div className="col-md-12">
                    <div className="campaign-subject input-group">
                      <input
                        type="text"
                        className="campaign-subject__input form-control border-bottom-0"
                        placeholder="Subject"
                        name="subject"
                        onChange={(e)=>setSubject(e.target.value)}
                        value={subject}
                      />
                    </div>

                    <div className="campaign-message input-group">
                      <ReactQuill
                        value={message}
                        onChange={(e)=>setMessage(e)}
                        className="form-control campaign-message__textarea h-100 p-0 border-0"
                      />
                      {addSign ? 
                      <div className="signature-container" dangerouslySetInnerHTML={{ __html: signature }}></div>
                      : <p className="signature-container cursorPointer" onClick={()=>setAddSign(true)}>Add Signature</p>
                    }
                    </div>
                  </div>
                </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={handelSend}>Send</button>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default EmailModal