import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import logger from "redux-logger";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import rootReducer from './RootReducers';

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk, logger];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: () => middleware,
});

export const persistor = persistStore(store);