
// import React from "react";
import { useEffect, useState } from "react";
import "./DiscoverCompany.css";
import requestInstance from "../services/request";
import { API_ENDPOINT } from "../services/Api";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';



const Discovercompany = () => {
  const navigate = useNavigate();
  const [searchingCompany, setSearchingCompany] = useState("");
  const [searchHeadquarters, setSearchHeadquarters] = useState("");
  const [industryType, setIndustryType] = useState("");
  const [industryList, setIndustryList] = useState([]);
  const [companiesData, setcompaniesData] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [selectedCompany, setSelectedCompany] =useState(null);
  const [companySize, setCompanySize] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(null);
  const [dataCount, setDataCount] = useState(null);
  const [companyTypes, setCompanyTypes] = useState(null);
  const [companyType, setCompanyType] = useState(null);
  const [keywords, setKeywords] = useState(null);
  const [emailData, setEmailData] = useState([]);

  useEffect(() => {
    if (page || page === 0) {
      searchCompanyFuction(true);
    }
  }, [page]);

  const columns = [
    {
      name: 'Email',
      selector: row => row?.email,
      // sortable: true,
    },
  ];

  const searchCompanyFuction =(pageChange)=>{
    if (!pageChange) {
      setPage(0);
    }
    const query = {};
  
    if (searchingCompany) {
      query.company_name = searchingCompany;
    }
    if (searchHeadquarters) {
      query.headquarter = searchHeadquarters;
    }
    if(industryType) {
      query.industries = industryType;
    }
    if(companySize) {
      const [min, max] = companySize.split('-');
      query.staff_count_start = min;
      query.staff_count_end = max;
    }
    if (companyType) {
      query.company_type = companyType.value;
    }
    if (keywords) {
      query.keywords = keywords;
    }
    query.limit = 10;
    if (!pageChange) {
      query.offset = 0;
    } else {
      query.offset = page ?? 0;
    }

    if(Object.keys(query).length > 0){
      const getCompanyData = () => {
        setLoading(true);
        requestInstance
          .get(API_ENDPOINT.DISCOVER_COMPANY, { params:query })
          .then((res) => {
            if (!res?.data?.data?.length && query?.company_name) {
              setCompanySize("");
              setSearchHeadquarters("");
              setSelectedOption("");
              setIndustryType("");
              setEmailData([])
              requestInstance.post(API_ENDPOINT.COMPANY_INFO, {
                company: query?.company_name
              }).then((response) => {
                setcompaniesData([response?.data?.data] || []);
                setLoading(false);
                setEmailData([])
              }).catch((err) => {
                console.log(err);
                setLoading(false);
                setEmailData([])
                setcompaniesData([]);
              });
            } else {
              setcompaniesData(res?.data?.data || []);
              setLoading(false);
              setEmailData([])
              toast.info(res?.data?.message);
              setDataCount(res?.data?.count);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      };
      getCompanyData();
    }
  }

  // ...........FOR GETTING INDUSTRY LIST........... 
  useEffect(()=>{
    requestInstance
    .get(API_ENDPOINT.DISCOVER_COMPANY_LIST)
    .then((res)=>{
      setIndustryList(res.data.data)
      setEmailData([])
    })
    .catch((err) => {
      console.log(err);
    });
  },[])
  let formattedIndustryList = industryList.map(industry=>({
    value:industry,
    label:industry
  }))

  let formattedTypesList = companyTypes?.map(type=>({
    value:type,
    label:type
  }))

  const handleIndustryChange =(event)=>{
    setIndustryType(event?.value);
    setSelectedOption(event);
  }

  const handleCompanyClick=(company)=>{
    setSelectedCompany(company)
  }

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const getCompanyTypeData = async () => {
      try {
        const response = await requestInstance.get(API_ENDPOINT.COMPANY_TYPE_OPTIONS);
        setCompanyTypes(response?.data?.data)
      } catch (error) {
        console.log(error);
      }
    }

    getCompanyTypeData();
  }, []);

  const handleEmailAddressesSubmit = () => {
    // setLoading(true);
    requestInstance.get(`${API_ENDPOINT.COMPANY_DISCOVER_EMAIL_ADDRESSES}/${companiesData[0]?.company_name}`)
      .then((res) => {
        setEmailData(res?.data?.data)
        toast.info(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  return (
    <div className="discoverCompany">
      <div className="mainDiscoverComp">
        <div className="companyFilters">
        <div className="col-12 mt-4 ms-4 " >
                    <div className="cursorPointer backButton" onClick={handleBack}>
                      <p className="mt-1 backText" >⬅ Back</p>
                    </div>
                  </div>
          <h4>Filters</h4>

          <div className="discoveringCategories">
            <label for="inputCompanyName" class="form-label">
              Company Name
            </label>
            <input
              type="text"
              id="inputCompanyName"
              class="form-control"
              aria-describedby="passwordHelpBlock"
              onChange={(e) => setSearchingCompany(e.target.value)}
            />
          </div>

          <div className="discoveringCategories">
            <label for="headquarterLocation" class="form-label">
              HeadQuarters Location
            </label>
            <input
              type="text"
              id="headquarterLocation"
              class="form-control"
              aria-describedby="passwordHelpBlock"
              value={searchHeadquarters}
              onChange={(e)=>setSearchHeadquarters(e.target.value)}
            ></input>
          </div>

          <div className="discoveringCategories">
            <label for="industry" class="form-label">
              Industry
            </label>

            <div className="industryListDiv" >
            {industryList && 
            <Select options={formattedIndustryList}
             value={selectedOption} 
             onChange={handleIndustryChange}
             isClearable={true}
             />  }
            </div>
          </div>

          <div className="discoveringCategories">
            <label htmlFor="companySize">Company Size</label>
            <select
              id="companySize"
              className="form-select"
              aria-label="Company Size"
              value={companySize}
              onChange={(e) => setCompanySize(e.target.value)}
            >
              <option value="">Select Size Range</option>
              <option value="1-10">1-10</option>
              <option value="11-50">11-50</option>
              <option value="51-200">51-200</option>
              <option value="201-500">201-500</option>
              <option value="501-1000">501-1000</option>
              <option value="1001-5000">1001-5000</option>
              <option value="5001-10000">5001-10000</option>
              <option value="10001-1000000">10001+</option>
            </select>
          </div>

          <div>
            <label htmlFor="companyType">Company Type</label>
            <Select id='companyType' options={formattedTypesList} value={companyType} onChange={(e)=>setCompanyType(e)} isClearable={true} />
          </div>  

          <div>
            <label htmlFor="keywords">Keywords</label>
            <input className='form-control' type="text" value={keywords} onChange={(e) => setKeywords(e.target.value)} />
          </div>

          <button className="btn btn-danger" onClick={() => searchCompanyFuction(false)} >Search</button>

        </div>
        <div className='container'>
          <div className="companyDisplay" type="button"
            data-bs-toggle={companiesData?.length && !loading ? 'modal' : ''}
            data-bs-target="#exampleModal">
            {
              loading ? <div className="companyDiscovemailLoader"></div> :
                <div className="container mt-5 row">
                  {companiesData?.length ? companiesData?.map((org) => (
                    <div key={org.id} className="company-card col-6" onClick={() => handleCompanyClick(org)}>
                      <img src={org.company_logo} alt={`${org.company_name} logo`} />
                      <h2>{org.company_name}</h2>
                      <p><strong>Staff Count:</strong> {org.staff_count}</p>
                      <p><strong>Industry:</strong> {org.industries.join(', ')}</p>
                      <p><strong>Founded On:</strong> {org.founded_on}</p>
                      <p><strong>Company Type:</strong> {org.company_type}</p>
                      <p><strong>LinkedIn:</strong> <a href={org.linkedin_url} target="_blank" rel="noopener noreferrer">View Profile</a></p>
                      <p><strong>Company Page:</strong> {org.company_page_url ? <a href={org.company_page_url} target="_blank" rel="noopener noreferrer">Visit Page</a> : 'N/A'}</p>
                      <p><strong>Headquarters:</strong> {org.headquarter.country}, {org.headquarter.geographicArea}, {org.headquarter.city}, {org.headquarter.postalCode}</p>
                      <hr />
                    </div>
                  )) : null}
                </div>
            }
          </div>
          {companiesData?.length && !loading ? <div className="d-flex justify-content-center align-items-center mt-5 mb-3 pagination">
            <button className={`${page === 0 ? 'disabled' : ''} btn btn-danger`} onClick={() => setPage(page - 10)}
            >Previous</button>
            <p className='m-0 mx-5'>Page {page / 10 + 1} / {Math.ceil(dataCount / 10)}</p>
            <button className={`${dataCount <= page + 10 ? 'disabled' : ''} btn btn-danger`} onClick={() => setPage(page + 10)}>Next</button>
          </div> : null
          }
        </div>

        {/* ..................THIS IS MODAL...............  */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  {selectedCompany ? selectedCompany.company_name : 'Company Details'}
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
            {selectedCompany && (
                    <div className="company-card">
                        <img src={selectedCompany.company_logo} alt={`${selectedCompany.company_name} logo`} />
                        <h2>{selectedCompany.company_name}</h2>
                        <p><strong>Staff Count:</strong> {selectedCompany.staff_count}</p>
                        <p><strong>Industry:</strong> {selectedCompany.industries.join(', ')}</p>
                        <p><strong>Description:</strong> {selectedCompany.description}</p>
                        <p><strong>Founded On:</strong> {selectedCompany.founded_on}</p>
                        <p><strong>Company Type:</strong> {selectedCompany.company_type}</p>
                        <p><strong>Phone Number:</strong> {selectedCompany.phone_number ? selectedCompany.phone_number: 'N/A' }</p>
                        <p><strong>LinkedIn:</strong> <a href={selectedCompany.linkedin_url} target="_blank" rel="noopener noreferrer">View Profile</a></p>
                        <p><strong>Company Page:</strong> {selectedCompany.company_page_url ? <a href={selectedCompany.company_page_url} target="_blank" rel="noopener noreferrer">Visit Page</a> : 'N/A'}</p>
                        <p><strong>Job Search URL:</strong> {selectedCompany.job_search_page_url ? <a href={selectedCompany.job_search_page_url} target="_blank" rel="noopener noreferrer">Visit Page</a> : 'N/A'}</p>
                        <p><strong>Specialities:</strong> {selectedCompany.specialities.join(', ')}</p>
                        <p><strong>Headquarters:</strong> {selectedCompany.headquarter.country}, {selectedCompany.headquarter.geographicArea}, {selectedCompany.headquarter.city}, {selectedCompany.headquarter.postalCode}</p>
                        <div>
                        <p><strong>Funding Details:</strong></p>
                        <p className="fundingTypes" ><strong> Announced:</strong> {`${selectedCompany.funding_data.announcedOn.month}/${selectedCompany.funding_data.announcedOn.day}/${selectedCompany.funding_data.announcedOn.year}`}</p>
                        <p className="fundingTypes"><strong> Type:</strong>{selectedCompany.funding_data.fundingType}</p>
                        <p className="fundingTypes"><strong> Lead Investors:</strong></p>
          <ul>
          {selectedCompany.funding_data.leadInvestors.map((investor, index) => (
            <li key={index} className="fundingTypes">
              <a href={investor.investorsCrunchbaseUrl} target="_blank" rel="noopener noreferrer">{investor.name}</a>
            </li>
          ))}
        </ul>
                        <p className="fundingTypes"><strong> Money Raised:</strong> {selectedCompany.funding_data.moneyRaised.currencyCode} {selectedCompany.funding_data.moneyRaised.amount}</p>
                        <p className="fundingTypes"><strong> Company Crunch Base URL:</strong> {selectedCompany.funding_data.fundingRoundListCrunchbaseUrl ? <a href={selectedCompany.funding_data.fundingRoundListCrunchbaseUrl} target="_blank" rel="noopener noreferrer">Click Here</a> : 'N/A'}</p>
                        <p className="fundingTypes"><strong> Investors Crunch Base URL:</strong> {selectedCompany.funding_data.investorsCrunchbaseUrl ? <a href={selectedCompany.funding_data.investorsCrunchbaseUrl} target="_blank" rel="noopener noreferrer">Click Here</a> : 'N/A'}</p>
                        <p className="fundingTypes"><strong> Funding Round:</strong> {selectedCompany.funding_data.numFundingRounds}</p>
                      <p className="fundingTypes"><strong> Number of Other Investors:</strong> {selectedCompany.funding_data.numOtherInvestors}</p> 
                        </div>                        
                    <hr />
                    
                    <div>
                      <button
                        className=" btn btn-disco col-3"
                        onClick={handleEmailAddressesSubmit}
                        type="submit"
                      >
                        Email addresses
                      </button>
                      {emailData.length !==0 ?
                        <div>
                          <div className='col-12 minHeight84vh'>
                            <div className='row'>
                              <div className='col-12 bulkSummaryTable overflow-auto p-4'>
                                <DataTable
                                  columns={columns}
                                  data={emailData}
                                  // progressPending={pending}
                                  // pagination
                                  paginationServer
                                // paginationTotalRows={totalRows}
                                // paginationPerPage={perPage}
                                // paginationDefaultPage={currentPage}
                                // onChangeRowsPerPage={handlePerRowsChange}
                                // onChangePage={handlePageChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div> :null}
                    </div>
                    </div>
                )}
                
           
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default Discovercompany;