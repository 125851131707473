import { useEffect, useState } from 'react';
import './campaignStatistics.css';
import requestInstance from '../../../services/request';
import { API_ENDPOINT } from '../../../services/Api';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const CampaignStatistics = () => {
  const location = useLocation();
  const [statisticsData, setStatisticsData] = useState(null);
  const campaignId = location.pathname.split('/')?.at(3);
  const [selectedOption, setSelectedOption] = useState('firstEmail');
  const [followUpList, setFollowUpList] = useState(null);
  const campaignSummaryId = useSelector(
    (state) => state.campaign.campaignSummaryId
  );
  const [followUpStatsData, setfollowUpStatsData] = useState(null);

  useEffect(() => {
    const getStatisticsData = async () => {
      try {
        const response = await requestInstance.get(`${API_ENDPOINT.CAMPAIGN_STATISTICS}/${campaignId}`);
        setStatisticsData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }

    const getFollowUpData = async () => {
      try {
        const response = await requestInstance.get(API_ENDPOINT.FOLLOWUP_LISTING + `${campaignSummaryId}`);
        setFollowUpList(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }

    getFollowUpData();
    getStatisticsData();
  }, []);

  const getFollowUpStatsData = async (id) => {
    try {
      const response = await requestInstance.get(`${API_ENDPOINT.CAMPAIGN_STATS_FOLLOW_UP}/${id}/`);
      setfollowUpStatsData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getExcelFile = async () => {
    try {
      const response = await requestInstance.get(`${API_ENDPOINT.CAMPAIGN_STATS_EXCEL}/${campaignId}`, { responseType: 'blob' });

      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      toast.success("Download successfull");
      link.setAttribute("download", "Discovemail.csv");
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      toast.error('Download Failed');
    }
  }

  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value !== 'firstEmail') {
      getFollowUpStatsData(e.target.value); 
    }
  }

  return (
    <div className='stats-container'>
      <div className="top-container d-flex justify-content-between">
        <select
          className="followupSelect"
          name="campaign" id="campaign" onChange={handleDropdownChange}>
          <option value="firstEmail" selected={selectedOption === 'firstEmail'}>First Email</option>
          {
            followUpList?.map((followUp, index) => <option key={followUp.id} value={followUp?.id} selected={selectedOption === followUp?.id}>Follow Up - {index + 1}</option>)
          }
        </select>
        <div className="excel-download">
          <button className='btn btn-danger' onClick={getExcelFile}>Download CSV</button>
        </div>
      </div>
      <div className="chart-container d-flex justify-content-center my-5">
        <div className="chart d-flex flex-column align-items-center">
          <div className="mb-4 opened-circle">
            <svg viewBox="0 0 36 36" class="circular-chart blue">
              <path class="circle-bg"
                d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path class="circle"
                stroke-dasharray={`${selectedOption === 'firstEmail' ? statisticsData?.emails_sent > 0 ? 100 : 0 : followUpStatsData?.emails_sent > 0 ? 100 : 0 }, 100`}
                d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <text x="18" y="20.35" class="percentage"> {  
              selectedOption === 'firstEmail' ?
              statisticsData?.emails_sent : followUpStatsData?.emails_sent}</text>
            </svg>
          </div>
          <div className="label sent">Sent</div>
        </div>
        <div className="chart d-flex flex-column align-items-center">
          <div className="mb-4 opened-circle">
            <svg viewBox="0 0 36 36" class="circular-chart green">
              <path class="circle-bg"
                d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path class="circle"
                stroke-dasharray={`${selectedOption === 'firstEmail' ? statisticsData?.opened_percentage.replace('%', '') : followUpStatsData?.opened_percentage.replace('%', '')}, 100`}
                d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <text x="18" y="20.35" class="percentage">{  
              selectedOption === 'firstEmail' ?
              Number(statisticsData?.opened_percentage.replace('%', '')).toFixed(2) : Number(followUpStatsData?.opened_percentage.replace('%', '')).toFixed(2)}%</text>
            </svg>
          </div>
          <div className="label opened">Opened</div>
        </div>
      </div>
      <div className="table-container">
        <table className='statsTable'>
          {
            selectedOption === 'firstEmail' ? 
            <>
             <thead>
            <tr>
              <th></th>
              <th>Scheduled</th>
              <th>Sent</th>
              <th>Opened</th>
              <th>Unsubscribed</th>
              <th>Failed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='fw-bold'>First Email</td>
              <td>{statisticsData?.total_emails}</td>
              <td>{statisticsData?.emails_sent}</td>
              <td>{Number(statisticsData?.opened_percentage.replace('%', '')).toFixed(2)}% ({statisticsData?.opened_emails})</td>
              <td>{statisticsData?.unsubscribed_emails}</td>
              <td>{statisticsData?.bounced_emails}</td>
            </tr>
          </tbody>
            </>
            : 
            <>
               <thead>
            <tr>
              <th></th>
              <th>Scheduled</th>
              <th>Sent</th>
              <th>Opened</th>
              <th>Unsubscribed</th>
              <th>Failed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='fw-bold'>Follow Up</td>
              <td>{followUpStatsData?.total_followups}</td>
              <td>{followUpStatsData?.emails_sent}</td>
              <td>{Number(followUpStatsData?.opened_percentage.replace('%', '')).toFixed(2)}% ({followUpStatsData?.opened_emails})</td>
              <td>{followUpStatsData?.unsubscribed_emails}</td>
              <td>{followUpStatsData?.error_emails}</td>
            </tr>
          </tbody>
            </>
          }
        </table>
      </div>
    </div>
  )
}

export default CampaignStatistics