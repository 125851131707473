import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar_1";
import Navbar_Dash from "../pages/dashboard/Navbar";
import Footer from "./Footer";
import {
  Link,
  redirect,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import "./TrailScreen.css";
import { toast } from "react-toastify";
import { API_ENDPOINT } from "../services/Api";
import requestInstance from "../services/request";
import Cookies from "js-cookie";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SmallLoader from "../pages/loader/SmallLoader";
import Spinner from "../pages/loader/SimpleLoader";
import TrailMailFromNameAndCompany from "./Components/TrailMail/TrailMailFromNameAndCompany";
import TrailSearchCompany from "./Components/TrailSearchCompany/TrailSearchCompany";
import ContactUsSection from "../components/contact/ContactUsSection";
import { useDispatch } from "react-redux";
import { planStatus } from "../redux/slices/user/UserSlice";
import backArrow from "../assets/back_arrow_icon.svg";
import verifiedTick from "../assets/verified-tick-icon.svg";
import invalidCross from "../assets/invalid-cross-icon.svg";
import VerifiedMail from "../assets/email-icon.svg";
import copyLine from "../assets/copy-line-icon.svg";
import emailVerifierHero from '../assets/product/emailVerifierHero.webp';
import emailFinderHero from '../assets/product/emailFinderHero.png';
import domainVerifierHero from '../assets/product/domainVerifierHero.webp';
import searchCompanyHero from '../assets/product/searchCompanyHero.webp';
import emailFinderPic2 from '../assets/product/emailFinderPic2.webp';
import emailFinderPic3 from '../assets/product/emailFinderPic3.png';
import emailFinderPic4 from '../assets/product/emailFinderPic4.webp';
import domainVerifierPic2 from '../assets/product/domainVerifierPic2.webp';
import domainVerifierPic3 from '../assets/product/domainVerifierPic3.webp';
import searchCompanypic2 from '../assets/product/searchCompanypic2.webp'
import domainVerifierIcon from '../assets/product/domainVerifierIcon.svg';
import emailVerifierIcon from '../assets/product/emailVerifierIcon.svg';
import emailFinderIcon from '../assets/product/emailFinderIcon.svg';
import companyFinderIcon from '../assets/product/companyFinderIcon.svg';
import provideNameIcon from '../assets/product/provide_name_icon.svg';
import domainIcon from '../assets/domain-icon.svg';
import resultIcon from '../assets/product/carbon_result-icon.svg';
import superFast from '../assets/product/rocket.svg';
import support_247 from '../assets/product/247_support.svg';
import high_speed from '../assets/product/high_speed.svg';
import secure_validation from '../assets/product/secure_validation.svg';
import emailTool1 from '../assets/product/verifier_tool1.svg';
import emailTool2 from '../assets/product/verifier_tool2.svg';
import emailTool3 from '../assets/product/verifier_tool3.svg';
import emailTool4 from '../assets/product/verifier_tool4.svg';
import emailTool5 from '../assets/product/verifier_tool5.svg';
import emailTool6 from '../assets/product/verifier_tool6.svg';

export default function TrailArea({ pageRoute }) {
  const navigate = useNavigate();
  // const [params] = useSearchParams();

  // const query = params.get("query");
  const query = pageRoute;
  const token = localStorage.getItem('find_email')
  const dispatch = useDispatch();

  const [ShowItem, setShowItem] = useState("");
  const [loading, setLoading] = useState(false)

  const [VerifyEmail, setVerifyEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [EmailData, setEmailData] = useState({});
  const [showModalEmail, setShowModal] = useState(false);

  const [showModalDomain, setShowModalDomain] = useState(false);
  const [verifyDomain, setVerifiDomain] = useState("");
  const [domainData, setDomainData] = useState({});

  const [validDomain, setValidDomain] = useState("");
  const [Blacklisted, setBlacklisted] = useState([]);
  const [domaininfo, setDomainInfo] = useState("");

  const [left, setLeft] = useState(0);
  const [domainTrail, setDomainTrail] = useState(0);

  const [apiResponse, setApiResponse] = useState({});

  const [hasEmailFromNameLoading, setHasEmailFromNameLoading] = useState(false);

  const [isLogin, setIsLogin] = useState();


  // To get User Details API
  const planApi = () => {
    setLoading(true);
    requestInstance
      .get(API_ENDPOINT.ACTIVE_PLAN_STATUS)
      .then((res) => {
        setLoading(false);
        dispatch(planStatus(res?.data?.data));
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleEmailVerifierCard = () => {
    navigate("/email-verifier");
    const trailScreenElement = document.getElementById('trailscreen');
    if (trailScreenElement) {
      trailScreenElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleDomainVerifierCard = () => {
    navigate("/domain-verifier");
    const trailScreenElement = document.getElementById('trailscreen');
    if (trailScreenElement) {
      trailScreenElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleEmailFinderCard = () => {
    navigate("/email-from-name-and-domain");
    const trailScreenElement = document.getElementById('trailscreen');
    if (trailScreenElement) {
      trailScreenElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleCompanyFinderCard = () => {
    navigate("/search-company");
    const trailScreenElement = document.getElementById('trailscreen');
    if (trailScreenElement) {
      trailScreenElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handelEmailVerifier = (e) => {
    e.preventDefault();

    if (VerifyEmail === "") {
      toast.error("Please enter valid email address");
      return;
    } else {
      setIsLoading(true);

      const currentCount = Cookies.get("TrailMail") || 0;

      if (+currentCount > 4 && !isLogin) {
        navigate("/signup");
        toast.error("Limit Reached");
      } else {
        Cookies.set("TrailMail", +currentCount + 1, { expires: 365 });

        setLeft(left + 1);

        requestInstance
          .post(API_ENDPOINT.TRAIL_EMAIL_VERIFY, { email: VerifyEmail })
          .then((res) => {
            planApi();
            setShowModal(true);
            setEmailData(res.data.data.details);
            setApiResponse(res.data.data);
            setIsLoading(false);
            toast.success(res.data.message);
          })
          .catch((err) => {
            toast.error(err.response.message);
            setIsLoading(false);
          });
      }
    }
  };


  const handelDomainVerifier = (e) => {
    e.preventDefault();

    if (verifyDomain === "") {
      toast.error("Please Enter valid domain name");
    } else {
      const domainCount = Cookies.get("TrailDomain") || 0;

      if (+domainCount > 4 && !isLogin) {
        navigate("/signup");
        toast.error("Limit Reached");
      } else {
        Cookies.set("TrailDomain", +domainTrail + 1, { expires: 365 });

        setDomainTrail(domainTrail + 1);

        setShowModalDomain(true);
        setIsLoading(true);
        requestInstance
          .post(API_ENDPOINT.DOMAIN_VERIFY, { domain: verifyDomain })
          .then((res) => {
            setApiResponse(res.data.data);
            planApi();
            setIsLoading(false);
            setValidDomain(res?.data?.data);
            toast.success(res?.data?.status);
            setDomainInfo(res?.data?.data?.domain_info);
            setDomainData(res.data.data);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (pageRoute === 'trailarea/email-verifier') {
      navigate('/email-verifier');
    }
    if (pageRoute === 'trailarea/domain-verifier') {
      navigate('/domain-verifier');
    }
    if (pageRoute === 'trailarea/email-from-name-and-domain') {
      navigate('/email-from-name-and-domain');
    }
    if (pageRoute === 'trailarea/search-company') {
      navigate('/search-company');
    }
    setIsLogin(token?.length > 0 ? true : false);
  }, []);

  const handleCopyText = async () => {
    const textToCopy = document.getElementById('textToCopy');

    if (textToCopy) {
      try {
        await navigator.clipboard.writeText(textToCopy.textContent);
        // You can provide feedback to the user, e.g., show a tooltip or a message
        toast.success('Text copied to clipboard!');
      } catch (err) {
        toast.error('Unable to copy text: ', err);
      }
    }
  };


  const verifiedEmailIds = [
    {
      email: 'farah@gloify.com',
      status: 'This mail ID is verified',
      imgUrl: '/pic/trailScreen/person1.png'
    },
    {
      email: 'rudra@apnanaukri.com',
      status: 'This mail ID is verified',
      imgUrl: '/pic/trailScreen/person2.png'
    },
    {
      email: 'rekha@discovemail.com',
      status: 'This mail ID is verified',
      imgUrl: '/pic/trailScreen/person3.png'
    },
  ];

  const generalQuestion = [
    {
      id: 1,
      title: "What is the best time to use 1 credit on Discovemail.com?",
      description:
        "Each email request costs one credit on DiscoveMail.com. You can sign up for the 100% free plan at Discovemail.com.You can select one of our paying plans if you want some of the more advanced features. Our goal is to assist as many individuals as we can in meeting their prospecting needs without trying to take advantage of them financially.",
    },
    {
      id: 2,
      title: "What is the accuracy of Discovemail Email Finder ?",
      description:
        "The accuracy of Discovemail Email Finder can vary, but it's designed to provide reliable results by leveraging multiple data sources and advanced algorithms.Many users find it to be an efficient tool for discovering email addresses, especially for outreach and lead generation.However, as with any email finder tool, it's important to verify email addresses for accuracy before using them for communication to ensure successful outreach.",
    },
    {
      id: 3,
      title: "Are the email addresses guesses or found somewhere ?",
      description:
        "Email addresses found using an email finder tool like Discovemail are typically not guesses. Instead, these tools search for email addresses that are publicly available or have been published on websites, social media profiles, online directories, or other publicly accessible sources. The process typically involves web scraping and data mining techniques to locate and compile email addresses from various online sources. It doesn't involve random guessing of email addresses, as that would be highly inefficient and unlikely to yield accurate results.",
    },

  ];

  const generalQuestion2 = [
    {
      id: 4,
      title: "Can I find the email addresses of a list of people ?",
      description:
        "Yes, We have a bulk email verification option to verify email addresses. You have to just upload the file and press enter it will validate and give the results.",
    },
    {
      id: 5,
      title: "How is the Discovemail different from other email search tools ?",
      description:
        "The Discovemail automates email searches using various data sources, making it efficient for bulk searches and potentially offering email verification, integration, and privacy features that distinguish it from manual email search methods or other tools.",
    },
    {
      id: 6,
      title: "Does Discovemail send out any e-mail message while email verify?",
      description:
        "No, Discovemail doesn't send out any e-mail message while email verify",
    },

  ];

  const emailVerifierHeroData = {
    title: "Clean email lists ensure effective customer reach.",
    description: "Our email validator removes bounces, spam complaints, spam traps, and bots from your list, enhancing deliverability and boosting click rates. Reconnect with subscribers effectively.",
    buttonText: "Get start now"
  }
  const emailFinderHeroData = {
    title: "Discovering Contacts by Name and Company Domain",
    description: "The Email Finder simplifies connecting with professionals by consolidating email formats, web addresses, and verification status to swiftly locate reliable contact information.",
    buttonText: "Get start now"
  }
  const domainVerifierHeroData = {
    title: "Domain Verifier",
    description: "If you're trying to quickly find out who to contact within a corporation, the Domain Search is ideal. It finds publicly accessible email addresses in less than 20 seconds.",
    buttonText: "Get start now"
  }
  const companySearchHeroData = {
    title: "Search company",
    description: "A Powerful tool that provides valuable insights about companies of interest.",
    buttonText: "Get start now"
  }

  const canonicalUrl = `https://www.discovemail.com/trailarea/${query}`;


  const getImage = () => {
    let image = '';
    if (query === "email-verifier")
      image = emailVerifierHero
    else if (query === "email-from-name-and-domain")
      image = emailFinderHero
    else if (query === "domain-verifier")
      image = domainVerifierHero
    else if (query === "search-company")
      image = searchCompanyHero
    return image
  }
  return (
    <>
      <Helmet>
        <title>Email Verifier: Verify Email Validity with Discovemail.</title>
        <link rel="canonical" href={canonicalUrl}></link>
        <meta
          name="description"
          content="Discovemail Is A Free E-Mail Verification Tool To Verify E-Mail Addresses Ensuring Accurate And Valid Contact Information For Successful Communication. Try Today With DiscovEmail."
        />
      </Helmet>
      {isLogin ? <Navbar_Dash /> : <Navbar />}
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {!isLogin && (
            <section className="trailScreenFirstSection">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-sm-12 d-flex align-items-center">
                    <div className="">
                      <h1 className="title1">
                        {query === "email-verifier" &&
                          emailVerifierHeroData.title}{" "}
                        {query === "email-from-name-and-domain" &&
                          emailFinderHeroData.title}{" "}
                        {query === "domain-verifier" &&
                          domainVerifierHeroData.title}{" "}
                        {query === "search-company" &&
                          companySearchHeroData.title}
                      </h1>

                      <p className="description mt-2 mb-4 pe-md-5">
                        {query === "email-verifier" &&
                          emailVerifierHeroData.description}{" "}
                        {query === "email-from-name-and-domain" &&
                          emailFinderHeroData.description}{" "}
                        {query === "domain-verifier" &&
                          domainVerifierHeroData.description}{" "}
                        {query === "search-company" &&
                          companySearchHeroData.description}
                      </p>
                      <div className="d-flex align-items-center my-4">
                        <a
                          className="btn btn-disco"
                          href="/signup"
                          target="blank"
                        >
                          Get start now
                        </a>
                      </div>
                      <div>
                        <h6 className="description2">
                          <img
                            src={verifiedTick}
                            alt="img not found"
                            className="img-fluid me-2"
                          />
                          Gain 100 monthly credits with a complimentary account.
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex justify-content-center">
                    {/* <img src={getImage()} alt="img" className=" img-fluid" /> */}
                    {/* HERE IS THE NEW TESTING AREA ......... */}
                    <section
                      className={`${
                        isLogin ? "height100vh trailscreen" : " trailscreen"
                      }`}
                      id="trailscreen"
                    >
                      <div className="container">
                        <div className="row justify-content-center">
                          
                          
                          <div className="col-md-12 mb-lg-5 mb-4">
                            {showModalEmail ? (
                              <Modal
                                size="lg"
                                show={showModalEmail}
                                onHide={() => setShowModal(false)}
                                centered
                                className="emailVerifyModalPopup"
                              >
                                <Modal.Header
                                  closeButton
                                  className="align-item-baseline pt-4 position-relative"
                                >
                                  <div className="container-fluid">
                                    <div className="row">
                                      <div className="col-12 d-flex align-items-center gap-4">
                                        <div className="position-relative">
                                          <img
                                            src={VerifiedMail}
                                            alt="img not found"
                                            className="img-fluid"
                                          />
                                          {apiResponse?.res_status ==
                                          "valid" ? (
                                            <img
                                              src={verifiedTick}
                                              alt="img not found"
                                              className="img-fluid statusImg"
                                            />
                                          ) : (
                                            <img
                                              src={invalidCross}
                                              alt="img not found"
                                              className="img-fluid statusImg"
                                            />
                                          )}
                                        </div>
                                        <div className="">
                                          <h6
                                            id="textToCopy"
                                            className="emailTitle d-flex align-items-center"
                                          >
                                            {VerifyEmail}{" "}
                                            <span
                                              onClick={handleCopyText}
                                              className=" cursorPointer ms-3"
                                            >
                                              <img
                                                src={copyLine}
                                                alt="img not found"
                                                className="img-fluid"
                                              />
                                            </span>
                                          </h6>
                                          <p className="statusTitle mb-2">
                                            {apiResponse?.res_status == "valid"
                                              ? "This email address can be utilized securely."
                                              : "This email address is not intended for receiving incoming emails."}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Header>
                                <Modal.Body className="pt-4 pb-3">
                                  <div className="container-fluid">
                                    <div className="row">
                                      <div className="col-12 col-lg-6 mb-4">
                                        <h6 className="emailTitleTxt">
                                          DNS
                                          {EmailData.DNS_validity.status ===
                                          "Valid" ? (
                                            <img
                                              src={verifiedTick}
                                              alt="img not found"
                                              className="img-fluid ms-2"
                                            />
                                          ) : (
                                            <img
                                              src={invalidCross}
                                              alt="img not found"
                                              className="img-fluid ms-2"
                                            />
                                          )}
                                        </h6>
                                        <p className="emailDescriptionTxt">
                                          {EmailData.DNS_validity.status ===
                                          "Valid"
                                            ? "The domain server is legitimate and operates flawlessly, ensuring optimal online performance."
                                            : "The domain server is illegitimate and experiences issues, leading to suboptimal online performance."}
                                        </p>
                                      </div>
                                      <div className="col-12 col-lg-6 mb-4">
                                        <h6 className="emailTitleTxt">
                                          Server Status
                                          {EmailData.MX_validity.status ===
                                          "Valid" ? (
                                            <img
                                              src={verifiedTick}
                                              alt="img not found"
                                              className="img-fluid ms-2"
                                            />
                                          ) : (
                                            <img
                                              src={invalidCross}
                                              alt="img not found"
                                              className="img-fluid ms-2"
                                            />
                                          )}
                                        </h6>
                                        <p className="emailDescriptionTxt">
                                          {" "}
                                          {EmailData.MX_validity.status ===
                                          "Valid"
                                            ? "MX records have been properly configured for the domain, allowing us to establish a connection with the SMTP server indicated by these records."
                                            : "MX records are either absent for the domain, or our attempts to establish a connection with the SMTP server specified by these records have been unsuccessful."}
                                        </p>
                                      </div>
                                      <div className="col-12 col-lg-6">
                                        <h6 className="emailTitleTxt">
                                          SMTP
                                          {EmailData.SMTP_validity.status ===
                                          "Valid" ? (
                                            <img
                                              src={verifiedTick}
                                              alt="img not found"
                                              className=" ms-2 img-fluid"
                                            />
                                          ) : (
                                            <img
                                              src={invalidCross}
                                              alt="img not found"
                                              className="img-fluid ms-2"
                                            />
                                          )}
                                        </h6>
                                        <p className="emailDescriptionTxt">
                                          {EmailData.SMTP_validity.status ===
                                          "Valid"
                                            ? "This email address is active and capable of receiving incoming emails."
                                            : "Emails cannot be delivered to this email address."}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                              </Modal>
                            ) : null}

                            {showModalDomain ? (
                              <Modal
                                size="lg"
                                show={showModalDomain}
                                onHide={() => setShowModalDomain(false)}
                                centered
                                className="emailVerifyModalPopup"
                              >
                                <Modal.Header
                                  closeButton
                                  className="align-item-baseline pt-4 position-relative"
                                >
                                  <div className="container-fluid">
                                    <div className="row">
                                      <div className="col-12 d-flex align-items-center gap-4">
                                        <div className=" position-relative">
                                          <img
                                            src={VerifiedMail}
                                            alt="img not found"
                                            className="img-fluid"
                                          />
                                          {apiResponse?.valid_domain ? (
                                            <img
                                              src={verifiedTick}
                                              alt="img not found"
                                              className="img-fluid statusImg"
                                            />
                                          ) : (
                                            <img
                                              src={invalidCross}
                                              alt="img not found"
                                              className="img-fluid statusImg"
                                            />
                                          )}
                                        </div>
                                        <div className="">
                                          <h6
                                            id="textToCopy"
                                            className="emailTitle d-flex align-items-center"
                                          >
                                            {domainData.domain_info.domain}{" "}
                                            <span
                                              onClick={handleCopyText}
                                              className=" cursorPointer ms-3"
                                            >
                                              <img
                                                src={copyLine}
                                                alt="img not found"
                                                className="img-fluid"
                                              />
                                            </span>
                                          </h6>
                                          <p className="statusTitle mb-2">
                                            {apiResponse?.valid_domain
                                              ? "The domain is legitimate and adheres to all validation standards."
                                              : "The domain is illegitimate and does not conform to validation standards."}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Header>
                                <Modal.Body>
                                  <div className="container-fluid">
                                    <div className="row">
                                      <div className="col-12 col-lg-6">
                                        <div className="row">
                                          <div className="col-12 mb-4">
                                            <h6 className="emailTitleTxt">
                                              Official Website
                                            </h6>
                                            <a
                                              {...(validDomain?.official_website
                                                ? {
                                                    href: validDomain.official_website,
                                                    target: "_blank",
                                                    rel: "noreferrer",
                                                  }
                                                : { disabled: true })}
                                              className="officeWebUrl"
                                            >
                                              {validDomain?.official_website ||
                                                "NA"}
                                            </a>
                                          </div>

                                          <div className="col-12  mb-4">
                                            <h6 className="emailTitleTxt">
                                              Domain
                                              {apiResponse?.valid_domain ? (
                                                <img
                                                  src={verifiedTick}
                                                  alt="img not found"
                                                  className="img-fluid ms-2"
                                                />
                                              ) : (
                                                <img
                                                  src={invalidCross}
                                                  alt="img not found"
                                                  className="img-fluid ms-2"
                                                />
                                              )}
                                            </h6>

                                            <div className="d-flex align-items-center mb-2">
                                              <p className="mb-0 domainTextTitle">
                                                Created on
                                              </p>
                                              <span className="ms-2 domainSubTextTitle">
                                                : &nbsp;
                                                {domaininfo.creation_date
                                                  ? moment(
                                                      domaininfo
                                                        .creation_date[0]
                                                    ).format("DD/MM/YYYY")
                                                  : " NA"}
                                              </span>
                                            </div>
                                            <div className="d-flex align-items-center mb-2">
                                              <p className="mb-0 domainTextTitle">
                                                Expiry on
                                              </p>
                                              <span className="ms-2 domainSubTextTitle">
                                                : &nbsp;
                                                {domaininfo.expiration_date
                                                  ? moment(
                                                      domaininfo
                                                        .expiration_date[0]
                                                    ).format("DD/MM/YYYY")
                                                  : " NA"}
                                              </span>
                                            </div>
                                            <div className="d-flex align-items-center mb-2">
                                              <p className="mb-0 domainTextTitle">
                                                {" "}
                                                Registered By
                                              </p>
                                              <span className="ms-2 domainSubTextTitle">
                                                : &nbsp;
                                                {domaininfo.registrar || " NA"}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-12 col-lg-6 mb-4">
                                        <h6 className="emailTitleTxt">
                                          Blacklisted
                                          {apiResponse?.valid_domain ? (
                                            <img
                                              src={verifiedTick}
                                              alt="img not found"
                                              className="img-fluid ms-2"
                                            />
                                          ) : (
                                            <img
                                              src={invalidCross}
                                              alt="img not found"
                                              className="img-fluid ms-2"
                                            />
                                          )}
                                        </h6>

                                        <div className="d-flex align-items-center mb-2">
                                          <p className="mb-0 domainTextTitleTwo">
                                            spamcop.net:
                                          </p>
                                          <span
                                            className={
                                              validDomain?.is_blacklisted[
                                                "bl.spamcop.net"
                                              ] === "True"
                                                ? "domainValidStatus"
                                                : "domainInValidStatus"
                                            }
                                          >
                                            {validDomain?.is_blacklisted[
                                              "bl.spamcop.net"
                                            ]
                                              ? "True"
                                              : "False"}
                                          </span>
                                        </div>

                                        <div className="d-flex align-items-center mb-2">
                                          <p className="mb-0 domainTextTitleTwo">
                                            abuseat.org:
                                          </p>
                                          <span
                                            className={
                                              validDomain?.is_blacklisted[
                                                "cbl.abuseat.org"
                                              ] === "True"
                                                ? "domainValidStatus"
                                                : "domainInValidStatus"
                                            }
                                          >
                                            {validDomain?.is_blacklisted[
                                              "cbl.abuseat.org"
                                            ]
                                              ? "True"
                                              : "False"}
                                          </span>
                                        </div>

                                        <div className="d-flex align-items-center mb-2">
                                          <p className="mb-0 domainTextTitleTwo">
                                            sorbs.net:
                                          </p>
                                          <span
                                            className={
                                              validDomain?.is_blacklisted[
                                                "dnsbl.sorbs.net"
                                              ] === "True"
                                                ? "domainValidStatus"
                                                : "domainInValidStatus"
                                            }
                                          >
                                            {validDomain?.is_blacklisted[
                                              "dnsbl.sorbs.net"
                                            ]
                                              ? "True"
                                              : "False"}
                                          </span>
                                        </div>

                                        <div className="d-flex align-items-center mb-2">
                                          <p className="mb-0 domainTextTitleTwo">
                                            xbl.spamhaus.org:
                                          </p>
                                          <span
                                            className={
                                              validDomain?.is_blacklisted[
                                                "xbl.spamhaus.org"
                                              ] === "True"
                                                ? "domainValidStatus"
                                                : "domainInValidStatus"
                                            }
                                          >
                                            {validDomain?.is_blacklisted[
                                              "xbl.spamhaus.org"
                                            ]
                                              ? "True"
                                              : "False"}
                                          </span>
                                        </div>
                                        <div className="d-flex align-items-center mb-2">
                                          <p className="mb-0 domainTextTitleTwo">
                                            zen.spamhaus.org:
                                          </p>
                                          <span
                                            className={
                                              validDomain?.is_blacklisted[
                                                "zen.spamhaus.org"
                                              ] === "True"
                                                ? "domainValidStatus"
                                                : "domainInValidStatus"
                                            }
                                          >
                                            {validDomain?.is_blacklisted[
                                              "zen.spamhaus.org"
                                            ]
                                              ? "True"
                                              : "False"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                              </Modal>
                            ) : null}

                            <div className="howItWorks__desc my-4 row justify-content-center">
                              {query === "email-verifier" ? (
                                <>
                                  <article className="feature-card col-md-12 col-11 px-5 py-4">
                                    <div id="emailHelp" className="">
                                      <h2 className="title pt-2">
                                        Enter Email to Verify
                                      </h2>
                                      <p className="subTitle">
                                        We'll never share your email with anyone
                                        else.
                                      </p>
                                    </div>
                                    <form
                                      className=""
                                      onSubmit={handelEmailVerifier}
                                    >
                                      <div className="input-group flex-lg-fill mb-4">
                                        <input
                                          type="email"
                                          className="form-control w-100 py-3"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                          placeholder="Enter your email address to verify"
                                          value={VerifyEmail}
                                          onChange={(e) =>
                                            setVerifyEmail(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="d-flex justify-content-end">
                                        <button
                                          type="submit"
                                          className="btn btn-disco"
                                        >
                                          Verify the email address
                                        </button>
                                      </div>
                                    </form>
                                  </article>
                                </>
                              ) : query === "domain-verifier" ? (
                                <>
                                  <article className="feature-card col-md-12 col-11 px-5 py-4">
                                    <div>
                                      <h2 className="title pt-2">
                                        Domain Verifier
                                      </h2>
                                      <p className="subTitle">
                                        Verify Your Domain With Confidence.
                                      </p>
                                    </div>

                                    <form
                                      className=""
                                      onSubmit={handelDomainVerifier}
                                    >
                                      <div className="input-group flex-lg-fill mb-4">
                                        <input
                                          type="text"
                                          id="domain"
                                          name="domain"
                                          onChange={(e) =>
                                            setVerifiDomain(e.target.value)
                                          }
                                          value={verifyDomain}
                                          className="form-control w-100 py-3"
                                          placeholder="Enter domain address to verify"
                                        />
                                      </div>
                                      <div className="d-flex justify-content-end">
                                        <button
                                          type="submit"
                                          className="btn btn-disco"
                                        >
                                          Find Domain
                                        </button>
                                      </div>
                                    </form>
                                  </article>
                                </>
                              ) : query === "email-from-name-and-domain" ? (
                                <>
                                  <TrailMailFromNameAndCompany />
                                </>
                              ) : query === "search-company" ? (
                                <>
                                  <TrailSearchCompany />
                                </>
                              ) : (
                                "Nothing"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    {/* NEW TESTING ARE ENDS HERE .......... */}
                  </div>
                </div>
              </div>
            </section>
          )}

          {!isLogin && query === "domain-verifier" && (
            <section className="bg_color_dark2">
              <div class="container twoColumnContainer">
                <div class="row py-5 align-items-center">
                  <div className="col-md-6 col-12 d-flex justify-content-center">
                    <img
                      src={domainVerifierPic2}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div class="col-md-6 col-12 pe-lg-5 px-3 px-md-1">
                    <h2 className="title">
                      Domain Verification: Connect with any business
                      effortlessly
                    </h2>
                    <p className="description mt-4">
                      The Domain Search is a rapid tool for pinpointing contacts
                      within a company. It uncovers publicly available emails
                      along with confidence scores and detailed sources—all
                      accomplished within twenty seconds, streamlining your
                      outreach efforts and enhancing your contact discovery
                      process significantly.
                    </p>
                    {/* <a
                        className="btn btn-disco d-inline-block mt-3"
                        href="/sign-up"
                      >
                        Learn More
                      </a> */}
                  </div>
                </div>
              </div>
            </section>
          )}

          {!isLogin && query === "email-from-name-and-domain" && (
            <section className="bg_color_dark2">
              <div class="container twoColumnContainer">
                <div class="row py-5">
                  <div className="col-md-6 col-12 d-flex justify-content-center">
                    <img
                      src={emailFinderPic2}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div class="col-md-6 col-12 px-lg-5">
                    <h2 className="title">
                      Find email addresses in three easy steps with Discovemail:
                    </h2>
                    <div className="row mt-5">
                      <div className="col-md-2">
                        <img
                          src={provideNameIcon}
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className=" col-md-10 ps-0">
                        <h6 className="subTitle ">Enter a Name</h6>
                        <p className="description">
                          Enter the name and company domain of the person you're
                          looking for
                        </p>
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-md-2">
                        <img src={domainIcon} alt="img" className="img-fluid" />
                      </div>
                      <div className="col-md-10 ps-0">
                        <h6 className="subTitle ">Enter a Domain</h6>
                        <p className="description ">
                          Browse it through the list of email addresses
                          generated based on your search criteria
                        </p>
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-md-2">
                        <img src={resultIcon} alt="img" className="img-fluid" />
                      </div>
                      <div className=" col-md-10 ps-0">
                        <h6 className="subTitle">Get Validated Results</h6>
                        <p className="description">
                          Verify and select the correct email address, and
                          you're ready to connect!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {!isLogin && query === "email-verifier" && (
            <section className="bg_color_dark2 py-5 verifierServiceSection">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 text-center">
                    <img
                      src={superFast}
                      alt="img not found"
                      className="img-fluid"
                    />
                    <h2 className="title mt-2">99.8 accuracy</h2>
                    <p className="description">
                      Experience 99% accuracy in email verification. Test it
                      firsthand.
                    </p>
                  </div>
                  <div className="col-md-3 text-center">
                    <img
                      src={support_247}
                      alt="img not found"
                      className="img-fluid"
                    />
                    <h2 className="title mt-2">24/7 Customer</h2>
                    <p className="description">
                      Available 24/7 for guidance in email verification via
                      chat, phone, or email.
                    </p>
                  </div>
                  <div className="col-md-3 text-center">
                    <img
                      src={high_speed}
                      alt="img not found"
                      className="img-fluid"
                    />
                    <h2 className="title mt-2">
                      Rapid email validation speeds.
                    </h2>
                    <p className="description">
                      We're swift! Scrub 200,000 contacts in 80 mins; verify
                      email in 3 secs.
                    </p>
                  </div>
                  <div className="col-md-3 text-center">
                    <img
                      src={secure_validation}
                      alt="img not found"
                      className="img-fluid"
                    />
                    <h2 className="title mt-2">
                      Guaranteed Secure Email Validation
                    </h2>
                    <p className="description">
                      Your data is secure with military-grade encryption during
                      verification.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          )}

          {!isLogin && query === "search-company" && (
            <section className="bg_color_dark2 py-5 verifierServiceSection">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <img
                      src={superFast}
                      alt="img not found"
                      className="img-fluid"
                    />
                    <h2 className="title mt-2">Super Fast</h2>
                    <p className="description px-5">
                      Discovemail provides Super Fast Service with Highest
                      Success Ratio
                    </p>
                  </div>
                  <div className="col-md-4 text-center">
                    <img
                      src={support_247}
                      alt="img not found"
                      className="img-fluid"
                    />
                    <h2 className="title mt-2">Very Accurate</h2>
                    <p className="description px-5">
                      Discovemail is 100% trustable with mutual co-operation &
                      transperancy
                    </p>
                  </div>
                  <div className="col-md-4 text-center">
                    <img
                      src={secure_validation}
                      alt="img not found"
                      className="img-fluid"
                    />
                    <h2 className="title mt-2">Simple</h2>
                    <p className="description px-5">
                      Discovemail is very easy and simple to use with smooth
                      interface
                    </p>
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* COMMENTED TRIAL AREA SECTION HERE.......  */}
          <section
            className={`${
              isLogin ? "height100vh trailscreen" : " trailscreen"
            }`}
            id="trailscreen"
          >
            <div className="container">
              <div className="row justify-content-center">
                {isLogin && (
                  <div className="col-12 mt-4 ms-4 ">
                    <div
                      className="cursorPointer col-2 col-md-1"
                      onClick={handleBack}
                    >
                      <p className="mt-5 backText">
                        <img
                          src={backArrow}
                          alt="img not found"
                          className="img-fluid me-2"
                        />
                        Back
                      </p>
                    </div>
                  </div>
                )}
                <div className="col-11 pt-3 mt-lgs-5 mb-lg-2">
                  {isLogin && query === "email-verifier" && (
                    <div>
                      <h1 className="m-0 text-center mainTitle mb-3">
                        Email Verifier: Streamlined and Efficient
                      </h1>
                      <p className="mainDesc w-50 m-auto text-center">
                        We boast the market's most accurate email verification,
                        paired with unparalleled customer support and a
                        user-friendly platform.
                      </p>
                    </div>
                  )}
                  {isLogin && query === "domain-verifier" && (
                    <div>
                      <h1 className="m-0 text-center mainTitle mb-3">
                        Domain Verifier
                      </h1>
                      <p className="mainDesc w-50 m-auto text-center">
                        If you're trying to quickly find out who to contact
                        within a corporation, the Domain Search is ideal. It
                        finds publicly accessible email addresses in less than
                        20 seconds, along with sector filters, reliability
                        scores, and thorough resources.
                      </p>
                    </div>
                  )}
                  {isLogin && query === "email-from-name-and-domain" && (
                    <div>
                      <h1 className="m-0 text-center mainTitle mb-3">
                        Discovering Contacts by Name and Company Domain{" "}
                      </h1>
                      <p className="mainDesc w-50 m-auto text-center">
                        Find email addresses associated with specific
                        individuals and their company domains
                      </p>
                    </div>
                  )}
                  {isLogin && query === "search-company" && (
                    <div>
                      <h1 className="m-0 text-center mainTitle mb-3">
                        Unlocking Insights on Companies of Interest{" "}
                      </h1>
                      <p className="mainDesc w-50 m-auto text-center">
                        A Powerful tool that provides valuable insights about
                        companies of interest.
                      </p>
                    </div>
                  )}
                </div>
                <div className="col-md-12 mb-lg-5 mb-4">
                  {showModalEmail ? (
                    <Modal
                      size="lg"
                      show={showModalEmail}
                      onHide={() => setShowModal(false)}
                      centered
                      className="emailVerifyModalPopup"
                    >
                      <Modal.Header
                        closeButton
                        className="align-item-baseline pt-4 position-relative"
                      >
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 d-flex align-items-center gap-4">
                              <div className="position-relative">
                                <img
                                  src={VerifiedMail}
                                  alt="img not found"
                                  className="img-fluid"
                                />
                                {apiResponse?.res_status == "valid" ? (
                                  <img
                                    src={verifiedTick}
                                    alt="img not found"
                                    className="img-fluid statusImg"
                                  />
                                ) : (
                                  <img
                                    src={invalidCross}
                                    alt="img not found"
                                    className="img-fluid statusImg"
                                  />
                                )}
                              </div>
                              <div className="">
                                <h6
                                  id="textToCopy"
                                  className="emailTitle d-flex align-items-center"
                                >
                                  {VerifyEmail}{" "}
                                  <span
                                    onClick={handleCopyText}
                                    className=" cursorPointer ms-3"
                                  >
                                    <img
                                      src={copyLine}
                                      alt="img not found"
                                      className="img-fluid"
                                    />
                                  </span>
                                </h6>
                                <p className="statusTitle mb-2">
                                  {apiResponse?.res_status == "valid"
                                    ? "This email address can be utilized securely."
                                    : "This email address is not intended for receiving incoming emails."}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Header>
                      <Modal.Body className="pt-4 pb-3">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-6 mb-4">
                              <h6 className="emailTitleTxt">
                                DNS
                                {EmailData.DNS_validity.status === "Valid" ? (
                                  <img
                                    src={verifiedTick}
                                    alt="img not found"
                                    className="img-fluid ms-2"
                                  />
                                ) : (
                                  <img
                                    src={invalidCross}
                                    alt="img not found"
                                    className="img-fluid ms-2"
                                  />
                                )}
                              </h6>
                              <p className="emailDescriptionTxt">
                                {EmailData.DNS_validity.status === "Valid"
                                  ? "The domain server is legitimate and operates flawlessly, ensuring optimal online performance."
                                  : "The domain server is illegitimate and experiences issues, leading to suboptimal online performance."}
                              </p>
                            </div>
                            <div className="col-12 col-lg-6 mb-4">
                              <h6 className="emailTitleTxt">
                                Server Status
                                {EmailData.MX_validity.status === "Valid" ? (
                                  <img
                                    src={verifiedTick}
                                    alt="img not found"
                                    className="img-fluid ms-2"
                                  />
                                ) : (
                                  <img
                                    src={invalidCross}
                                    alt="img not found"
                                    className="img-fluid ms-2"
                                  />
                                )}
                              </h6>
                              <p className="emailDescriptionTxt">
                                {" "}
                                {EmailData.MX_validity.status === "Valid"
                                  ? "MX records have been properly configured for the domain, allowing us to establish a connection with the SMTP server indicated by these records."
                                  : "MX records are either absent for the domain, or our attempts to establish a connection with the SMTP server specified by these records have been unsuccessful."}
                              </p>
                            </div>
                            <div className="col-12 col-lg-6">
                              <h6 className="emailTitleTxt">
                                SMTP
                                {EmailData.SMTP_validity.status === "Valid" ? (
                                  <img
                                    src={verifiedTick}
                                    alt="img not found"
                                    className=" ms-2 img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={invalidCross}
                                    alt="img not found"
                                    className="img-fluid ms-2"
                                  />
                                )}
                              </h6>
                              <p className="emailDescriptionTxt">
                                {EmailData.SMTP_validity.status === "Valid"
                                  ? "This email address is active and capable of receiving incoming emails."
                                  : "Emails cannot be delivered to this email address."}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  ) : null}

                  {showModalDomain ? (
                    <Modal
                      size="lg"
                      show={showModalDomain}
                      onHide={() => setShowModalDomain(false)}
                      centered
                      className="emailVerifyModalPopup"
                    >
                      <Modal.Header
                        closeButton
                        className="align-item-baseline pt-4 position-relative"
                      >
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 d-flex align-items-center gap-4">
                              <div className=" position-relative">
                                <img
                                  src={VerifiedMail}
                                  alt="img not found"
                                  className="img-fluid"
                                />
                                {apiResponse?.valid_domain ? (
                                  <img
                                    src={verifiedTick}
                                    alt="img not found"
                                    className="img-fluid statusImg"
                                  />
                                ) : (
                                  <img
                                    src={invalidCross}
                                    alt="img not found"
                                    className="img-fluid statusImg"
                                  />
                                )}
                              </div>
                              <div className="">
                                <h6
                                  id="textToCopy"
                                  className="emailTitle d-flex align-items-center"
                                >
                                  {domainData.domain_info.domain}{" "}
                                  <span
                                    onClick={handleCopyText}
                                    className=" cursorPointer ms-3"
                                  >
                                    <img
                                      src={copyLine}
                                      alt="img not found"
                                      className="img-fluid"
                                    />
                                  </span>
                                </h6>
                                <p className="statusTitle mb-2">
                                  {apiResponse?.valid_domain
                                    ? "The domain is legitimate and adheres to all validation standards."
                                    : "The domain is illegitimate and does not conform to validation standards."}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-6">
                              <div className="row">
                                <div className="col-12 mb-4">
                                  <h6 className="emailTitleTxt">
                                    Official Website
                                  </h6>
                                  <a
                                    {...(validDomain?.official_website
                                      ? {
                                          href: validDomain.official_website,
                                          target: "_blank",
                                          rel: "noreferrer",
                                        }
                                      : { disabled: true })}
                                    className="officeWebUrl"
                                  >
                                    {validDomain?.official_website || "NA"}
                                  </a>
                                </div>

                                <div className="col-12  mb-4">
                                  <h6 className="emailTitleTxt">
                                    Domain
                                    {apiResponse?.valid_domain ? (
                                      <img
                                        src={verifiedTick}
                                        alt="img not found"
                                        className="img-fluid ms-2"
                                      />
                                    ) : (
                                      <img
                                        src={invalidCross}
                                        alt="img not found"
                                        className="img-fluid ms-2"
                                      />
                                    )}
                                  </h6>

                                  <div className="d-flex align-items-center mb-2">
                                    <p className="mb-0 domainTextTitle">
                                      Created on
                                    </p>
                                    <span className="ms-2 domainSubTextTitle">
                                      : &nbsp;
                                      {domaininfo.creation_date
                                        ? moment(
                                            domaininfo.creation_date[0]
                                          ).format("DD/MM/YYYY")
                                        : " NA"}
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-2">
                                    <p className="mb-0 domainTextTitle">
                                      Expiry on
                                    </p>
                                    <span className="ms-2 domainSubTextTitle">
                                      : &nbsp;
                                      {domaininfo.expiration_date
                                        ? moment(
                                            domaininfo.expiration_date[0]
                                          ).format("DD/MM/YYYY")
                                        : " NA"}
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center mb-2">
                                    <p className="mb-0 domainTextTitle">
                                      {" "}
                                      Registered By
                                    </p>
                                    <span className="ms-2 domainSubTextTitle">
                                      : &nbsp;
                                      {domaininfo.registrar || " NA"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-12 col-lg-6 mb-4">
                              <h6 className="emailTitleTxt">
                                Blacklisted
                                {apiResponse?.valid_domain ? (
                                  <img
                                    src={verifiedTick}
                                    alt="img not found"
                                    className="img-fluid ms-2"
                                  />
                                ) : (
                                  <img
                                    src={invalidCross}
                                    alt="img not found"
                                    className="img-fluid ms-2"
                                  />
                                )}
                              </h6>

                              <div className="d-flex align-items-center mb-2">
                                <p className="mb-0 domainTextTitleTwo">
                                  spamcop.net:
                                </p>
                                <span
                                  className={
                                    validDomain?.is_blacklisted[
                                      "bl.spamcop.net"
                                    ] === "True"
                                      ? "domainValidStatus"
                                      : "domainInValidStatus"
                                  }
                                >
                                  {validDomain?.is_blacklisted["bl.spamcop.net"]
                                    ? "True"
                                    : "False"}
                                </span>
                              </div>

                              <div className="d-flex align-items-center mb-2">
                                <p className="mb-0 domainTextTitleTwo">
                                  abuseat.org:
                                </p>
                                <span
                                  className={
                                    validDomain?.is_blacklisted[
                                      "cbl.abuseat.org"
                                    ] === "True"
                                      ? "domainValidStatus"
                                      : "domainInValidStatus"
                                  }
                                >
                                  {validDomain?.is_blacklisted[
                                    "cbl.abuseat.org"
                                  ]
                                    ? "True"
                                    : "False"}
                                </span>
                              </div>

                              <div className="d-flex align-items-center mb-2">
                                <p className="mb-0 domainTextTitleTwo">
                                  sorbs.net:
                                </p>
                                <span
                                  className={
                                    validDomain?.is_blacklisted[
                                      "dnsbl.sorbs.net"
                                    ] === "True"
                                      ? "domainValidStatus"
                                      : "domainInValidStatus"
                                  }
                                >
                                  {validDomain?.is_blacklisted[
                                    "dnsbl.sorbs.net"
                                  ]
                                    ? "True"
                                    : "False"}
                                </span>
                              </div>

                              <div className="d-flex align-items-center mb-2">
                                <p className="mb-0 domainTextTitleTwo">
                                  xbl.spamhaus.org:
                                </p>
                                <span
                                  className={
                                    validDomain?.is_blacklisted[
                                      "xbl.spamhaus.org"
                                    ] === "True"
                                      ? "domainValidStatus"
                                      : "domainInValidStatus"
                                  }
                                >
                                  {validDomain?.is_blacklisted[
                                    "xbl.spamhaus.org"
                                  ]
                                    ? "True"
                                    : "False"}
                                </span>
                              </div>
                              <div className="d-flex align-items-center mb-2">
                                <p className="mb-0 domainTextTitleTwo">
                                  zen.spamhaus.org:
                                </p>
                                <span
                                  className={
                                    validDomain?.is_blacklisted[
                                      "zen.spamhaus.org"
                                    ] === "True"
                                      ? "domainValidStatus"
                                      : "domainInValidStatus"
                                  }
                                >
                                  {validDomain?.is_blacklisted[
                                    "zen.spamhaus.org"
                                  ]
                                    ? "True"
                                    : "False"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  ) : null}

                  <div className="howItWorks__desc my-4 row justify-content-center">
                    {isLogin && query === "email-verifier" ? (
                      <>
                        <article className="feature-card col-md-6 col-11 px-5 py-4">
                          <div id="emailHelp" className="">
                            <h2 className="title pt-2">
                              Enter Email to Verify
                            </h2>
                            <p className="subTitle">
                              We'll never share your email with anyone else.
                            </p>
                          </div>
                          <form className="" onSubmit={handelEmailVerifier}>
                            <div className="input-group flex-lg-fill mb-4">
                              <input
                                type="email"
                                className="form-control w-100 py-3"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Enter your email address to verify"
                                value={VerifyEmail}
                                onChange={(e) => setVerifyEmail(e.target.value)}
                              />
                            </div>
                            <div className="d-flex justify-content-end">
                              <button type="submit" className="btn btn-disco">
                                Verify the email address
                              </button>
                            </div>
                          </form>
                        </article>
                      </>
                    ) : isLogin && query === "domain-verifier" ? (
                      <>
                        <article className="feature-card col-md-6 col-11 px-5 py-4">
                          <div>
                            <h2 className="title pt-2">Domain Verifier</h2>
                            <p className="subTitle">
                              Verify Your Domain With Confidence.
                            </p>
                          </div>

                          <form className="" onSubmit={handelDomainVerifier}>
                            <div className="input-group flex-lg-fill mb-4">
                              <input
                                type="text"
                                id="domain"
                                name="domain"
                                onChange={(e) =>
                                  setVerifiDomain(e.target.value)
                                }
                                value={verifyDomain}
                                className="form-control w-100 py-3"
                                placeholder="Enter domain address to verify"
                              />
                            </div>
                            <div className="d-flex justify-content-end">
                              <button type="submit" className="btn btn-disco">
                                Find Domain
                              </button>
                            </div>
                          </form>
                        </article>
                      </>
                    ) : isLogin && query === "email-from-name-and-domain" ? (
                      <>
                        <TrailMailFromNameAndCompany />
                      </>
                    ) : isLogin && query === "search-company" ? (
                      <>
                        <TrailSearchCompany />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          {!isLogin && query === "email-verifier" && (
            <section className="bg_color_dark2 py-5 emailValidationToolSection">
              <div className=" container">
                <div className="row">
                  <div className="col-md-6 offset-md-3 pe-md-5">
                    <h2 className="mainTitle text-center">
                      The world's most comprehensive email validation tools.
                    </h2>
                    <p className="mainDescription text-center">
                      Our email validation tools simplify list verification and
                      cleansing, guaranteeing successful message delivery to
                      your audience.
                    </p>
                  </div>
                  <div className="col-md-4 mt-4 pe-md-5">
                    <img
                      src={emailTool3}
                      alt="img not found"
                      className="img-fluid"
                    />
                    <h2 className="Title mt-3">Ensure email deliverability.</h2>
                    <p className="description">
                      We can identify emails that bounce by using our email
                      validation software. Keep your bounce rate below 5% by
                      regularly cleaning your list.
                    </p>
                  </div>
                  <div className="col-md-4 mt-4 pe-md-5">
                    <img
                      src={emailTool2}
                      alt="img not found"
                      className="img-fluid"
                    />
                    <h2 className="Title mt-3">MX Record Detection</h2>
                    <p className="description">
                      Our platform indicates the presence of an MX Record for
                      checked domains, ensuring authenticity of email addresses.
                    </p>
                  </div>
                  <div className="col-md-4 mt-4 pe-md-5">
                    <img
                      src={emailTool1}
                      alt="img not found"
                      className="img-fluid"
                    />
                    <h2 className="Title mt-3">Free Email Verifier</h2>
                    <p className="description">
                      Quickly check email addresses to determine if they're from
                      free, toxic, or disposable domains. Plus, receive names
                      when available. Use it anytime for swift verification.
                    </p>
                  </div>
                  {/* <div className="col-md-4 mt-4 pe-md-5">
                    <img src={emailTool1} alt='img not found' className="img-fluid" />
                    <h2 className="Title mt-3">Free Email Verifier</h2>
                    <p className="description">These temporary email accounts self-destruct and end up bouncing. We run a thorough email list cleaning to weed them out for you.</p>
                  </div> */}

                  <div className="col-md-6 offset-md-3 pe-md-5">
                    <h2 className="mainTitle text-center fw-bold mt-5">
                      Verifying emails is the initial step
                    </h2>
                    <p className="mainDescription text-center">
                      {" "}
                      Discovemail equips you with tools to engage vital
                      individuals for business success.
                    </p>
                  </div>
                  <div className="col-md-4 mt-4 pe-md-5">
                    <img
                      src={emailTool6}
                      alt="img not found"
                      className="img-fluid"
                    />
                    <h2 className="Title mt-3">Find the email address</h2>
                    <p className="description">
                      Find the verified email address of any professional
                    </p>
                  </div>

                  <div className="col-md-4 mt-4 pe-md-5">
                    <img
                      src={emailTool5}
                      alt="img not found"
                      className="img-fluid"
                    />
                    <h2 className="Title mt-3">Verify the email</h2>
                    <p className="description">
                      Verified email address of any professional
                    </p>
                  </div>
                  <div className="col-md-4 mt-4 pe-md-5">
                    <img
                      src={emailTool4}
                      alt="img not found"
                      className="img-fluid"
                    />
                    <h2 className="Title mt-3">Send a cold email campaign</h2>
                    <p className="description">
                      Connect your email for creating, personalizing,
                      scheduling, and sending targeted campaigns at scale with
                      Discovemail.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          )}

          {!isLogin && query === "domain-verifier" && (
            <section className="bg_color_dark2">
              <div class="container twoColumnContainer">
                <div class="row py-5 align-items-center">
                  <div class="col-md-6 col-12 pe-lg-5 px-3 px-md-1">
                    <h2 className="title">
                      Verify the domain and establish a connection
                    </h2>
                    <p className="description mt-4">
                      In any industry, reliable contacts are crucial.
                      Discovemail offers the top free email lookup tool for
                      establishing new connections. Validate and research emails
                      to transform your cold email outreach across sales, PR,
                      journalism, real estate, and beyond.
                    </p>
                    <a
                      className="btn btn-disco d-inline-block mt-3"
                      href="/signup"
                    >
                      Learn More
                    </a>
                  </div>
                  <div className="col-md-6 col-12 d-flex justify-content-center">
                    <img
                      src={domainVerifierPic3}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </section>
          )}

          {!isLogin && query === "search-company" && (
            <section className="bg_color_dark2">
              <div class="container twoColumnContainer">
                <div class="row py-5 align-items-center">
                  <div className="col-md-6 col-12 d-flex justify-content-center">
                    <img
                      src={searchCompanypic2}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div class="col-md-6 col-12 pe-lg-5 px-3 px-md-1">
                    <h2 className="title">Search about company</h2>
                    <p className="description mt-4">
                      The "Search Company" feature allows users to search for
                      specific companies and obtain detailed information about
                      them, including contact details, industry, size, location,
                      and more. This feature is particularly useful for sales,
                      marketing, and business development professionals looking
                      to identify potential leads or partners within specific
                      industries or regions.
                    </p>
                    <a
                      className="btn btn-disco d-inline-block mt-3"
                      href="/signup"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </section>
          )}

          {!isLogin && query === "email-from-name-and-domain" && (
            <section className="bg_color_dark2">
              <div class="container twoColumnContainer">
                <div class="row py-5 align-items-center">
                  <div class="col-md-6 col-12 pe-lg-5 px-3 px-md-1">
                    <h2 className="title">
                      Find email addresses in bulk effortlessly with
                      Discovemail:
                    </h2>
                    <p className="description mt-4">
                      1.Upload your list of names and company domains.
                    </p>
                    <p className="description">
                      2.Our system will process the data and generate a list of
                      email addresses associated with the provided information.
                    </p>
                    <p className="description">
                      3.Review the results and download the verified email
                      addresses in bulk for your outreach campaigns.
                    </p>
                    <a
                      className="btn btn-disco d-inline-block mt-3"
                      href="/signup"
                    >
                      Learn More
                    </a>
                  </div>
                  <div className="col-md-6 col-12 d-flex justify-content-center">
                    <img
                      src={emailFinderPic3}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </section>
          )}

          {!isLogin && query === "email-from-name-and-domain" && (
            <section className="bg_color_dark">
              <div class="container twoColumnContainer">
                <div class="row py-5 align-items-center">
                  <div className="col-md-6 col-12 d-flex justify-content-center">
                    <img
                      src={emailFinderPic4}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div class="col-md-6 col-12 pe-lg-5 px-3 px-md-1">
                    <h2 className="title">
                      Connect across all industries with our networking tools
                    </h2>
                    <p className="description mt-4">
                      In any industry, reliable contact is essential.
                      Discovemail offers the best free email lookup tool to
                      forge new connections. Research and validate emails to
                      revolutionize your cold email outreach in sales, PR,
                      journalism, real estate, and more. Sign up for a free
                      Discovemail account today!
                    </p>
                    <a
                      className="btn btn-disco d-inline-block mt-3"
                      href="/signup"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </section>
          )}

          {!isLogin && (
            <section className="bg_color_dark pt-5 ">
              <div className="container d-grid align-items-center trailAreaPartner ">
                <div className="row ">
                  <div className="col-md-6 ps-md-5 pt-5">
                    <h1 className="title ps-md-4 mt-5">
                      {query === "email-verifier" &&
                        "Discover why leading companies prefer us to enhance email delivery."}{" "}
                      {query === "email-from-name-and-domain" &&
                        "Use our email finder platform and get valid results in seconds."}{" "}
                      {query === "domain-verifier" &&
                        "Utilize our domain verification Platform to obtain valid results within seconds."}{" "}
                      {query === "search-company" &&
                        "Use our search company platform and get valid results in seconds."}{" "}
                    </h1>
                    <div className="d-flex align-items-center my-4 ps-md-4">
                      <a
                        className="btn btn-disco buttonColor"
                        href="/email-verifier"
                      >
                        Find Email Addresses
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            </section>
          )}

          {!isLogin && (
            <section className="faqSecondSection py-5">
              <div className="container py-3">
                <div className="row">
                  <div className="col-12 mb-4">
                    <h6 className="title text-center">
                      Frequently asked questions
                    </h6>
                  </div>
                  {/* <div className='col-12'> */}
                  <div
                    class="accordion frqAskedQusAccordion"
                    id="accordionExample"
                  >
                    <div class=" row">
                      <div className="col-md-6 general-acc">
                        {generalQuestion.map((res, index) => (
                          <div class="accordion-item mb-4 py-3" key={res.id}>
                            <h2
                              class="accordion-header"
                              id={"heading" + res.id}
                            >
                              <button
                                class={
                                  (res.id !== 1 ? "collapsed " : "") +
                                  "accordion-button border-0 pb-2"
                                }
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={"#collapse" + res.id}
                                aria-expanded={res.id === 1 ? true : false}
                                aria-controls={"collapse" + res.id}
                              >
                                {res.title}
                              </button>
                            </h2>
                            <div
                              id={"collapse" + res.id}
                              class={
                                (res.id === 1 ? "show " : "") +
                                "accordion-collapse collapse border-0"
                              }
                              aria-labelledby={"heading" + res.id}
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body pt-0">
                                <p className="m-0 pe-md-5">{res.description}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="col-md-6">
                        {generalQuestion2.map((res, index) => (
                          <div class="accordion-item mb-4 py-3" key={res.id}>
                            <h2
                              class="accordion-header"
                              id={"heading" + res.id}
                            >
                              <button
                                class={
                                  (res.id !== 1 ? "collapsed " : "") +
                                  "accordion-button border-0 pb-2"
                                }
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={"#collapse" + res.id}
                                aria-expanded={res.id === 1 ? true : false}
                                aria-controls={"collapse" + res.id}
                              >
                                {res.title}
                              </button>
                            </h2>
                            <div
                              id={"collapse" + res.id}
                              class={
                                (res.id === 1 ? "show " : "") +
                                "accordion-collapse collapse border-0"
                              }
                              aria-labelledby={"heading" + res.id}
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body pt-0">
                                <p className="m-0 pe-md-5">{res.description}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {!isLogin && (
            <section className="bg_color_dark otherProductSection">
              <div className="container pb-5">
                <div className="row g-5 pb-5">
                  <h2 className="heading text-center">Other Products</h2>
                  {query !== "email-verifier" && (
                    <div className="col-lg-4">
                      <div
                        className="productCard py-4 px-5 cursorPointer"
                        onClick={handleEmailVerifierCard}
                      >
                        <div className="d-flex flex-row align-items-center mb-3">
                          <img
                            src={emailVerifierIcon}
                            alt="img not found"
                            className="img-fluid"
                          />
                          <h3 className="title ms-4">Email Verifier</h3>
                        </div>
                        <p className="description">
                          Elevate your email marketing success with our powerful
                          email verification tool, ensuring accuracy and
                          validity for enhanced deliverability and engagement.
                        </p>
                      </div>
                    </div>
                  )}
                  {query !== "domain-verifier" && (
                    <div className="col-lg-4">
                      <div
                        className="productCard py-4 px-5 cursorPointer"
                        onClick={handleDomainVerifierCard}
                      >
                        <div className="d-flex flex-row align-items-center mb-3">
                          <img
                            src={domainVerifierIcon}
                            alt="img not found"
                            className="img-fluid"
                          />
                          <h3 className="title ms-4">Domain Verifier</h3>
                        </div>
                        <p className="description">
                          Quickly identify contacts within a company with Domain
                          Search, finding email addresses in under 20 seconds
                          with sector filters.
                        </p>
                      </div>
                    </div>
                  )}

                  {query !== "email-from-name-and-domain" && (
                    <div className="col-lg-4">
                      <div
                        className="productCard py-4 px-5 cursorPointer"
                        onClick={handleEmailFinderCard}
                      >
                        <div className="d-flex flex-row align-items-center mb-3">
                          <img
                            src={emailFinderIcon}
                            alt="img not found"
                            className="img-fluid"
                          />
                          <h3 className="title ms-4">Email finder</h3>
                        </div>
                        <p className="description">
                          Trust the Email Finder for streamlined communication,
                          compiling data to quickly locate reliable contact
                          information, enhancing productivity and outreach
                          efforts.
                        </p>
                      </div>
                    </div>
                  )}
                  {query !== "search-company" && (
                    <div className="col-lg-4">
                      <div
                        className="productCard py-4 px-5 cursorPointer"
                        onClick={handleCompanyFinderCard}
                      >
                        <div className="d-flex flex-row align-items-center mb-3">
                          <img
                            src={companyFinderIcon}
                            alt="img not found"
                            className="img-fluid"
                          />
                          <h3 className="title ms-4">Company finder</h3>
                        </div>
                        <p className="description">
                          A Powerful tool that provides valuable insights about
                          companies of interest.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          )}

          {!isLogin ? <Footer /> : null}
        </>
      )}
    </>
  );
}
