import React, { useState, useEffect } from "react";
import Navbar_Dash from "./Navbar";
import Navbar from "../../new_home/Navbar_1";
import "./Navbar.css";
import { BsFillPersonFill } from "react-icons/bs";
import { FiSend } from "react-icons/fi";
import { BsGlobe } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { RiFileExcel2Line } from "react-icons/ri";
import { useFormik } from "formik";
// import ProgressBar2 from "../../components/progressBar/ProgressBar";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import axios from "axios";
// import { setCurrentUser } from "../../utils/utils";
import moment from "moment";
// import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Helmet from "react-helmet";
import BlankPerson from "../../assets/video/blankperson.png";
import CheckEmail from "../domainEmail/CheckEmail";
import CheckDomain from "../domainEmail/CheckDomain";
import { Button, Modal } from "react-bootstrap";
import SmallLoader from "../loader/SmallLoader";
import CompanyOverview from "./CompanyOverview";
import { Loader } from "react-simple-widgets";
import Spinner from "../loader/SimpleLoader";
import DataTable from "react-data-table-component";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import { planStatus, userProfile } from '../../redux/slices/user/UserSlice';
import useDebounce from "../../hooks/useDebounce";

const validate = (values) => {
  var errors = {};

  if (values.first_name || values.last_name) {
    errors = "";
  } else {
    errors.first_name = "Please enter firstname or lastname";
  }
  return errors;
};

function NewDashboard() {
  const mail = localStorage.getItem("email");
  const navigate = useNavigate();

  const location = useLocation();
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const sessionId = searchParams.get('session_id');

  const [tablelist, setTablelist] = useState([]);
  const [showProgressBar, setshowProgressBar] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [errValue, setErrValue] = useState("");
  const [message, setMessage] = useState(false);
  const [data, setData] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);

  const [companyContactInfo, setCompanyContactInfo] = useState([]);
  const [companyInfo, setCompanyInfo] = useState([]);

  const [companyName, setCompanyName] = useState("");
  const [showCompanyModel, setShowCompanyModel] = useState(false);
  const [isCompanyLoading, setIsCompanyLoading] = useState(true);
  const [isCompanyInfoLoading, setIsCompanyInfoLoading] = useState(true);
  const [isCompanyError, setIsCompanyError] = useState(false);

  const [compPhoneNum, setcompPhoneNum] = useState("");
  const [currIndex, setcurrIndex] = useState();

  const [forPhone, setForPhone] = useState({ company: null, id: null });
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastIndex, setLastIndex] = useState(0);
  const [nextData, setNextData] = useState();
  const [prevData, setPrevData] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState('exportAllList');
  const dispatch = useDispatch();
  const debouncedSearchValue = useDebounce(searchTerm, 900);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      company: "",
    },
    validate,
    onSubmit: (search) => {
      setErrMessage(false);
      setLoading(true);
      setMessage(false);
      setshowProgressBar(true);
      emailfromname(search);
    },
  });

  //API for gettting Email through name and company
  const emailfromname = (search) => {
    let value = {
      first_name: search.first_name,
      last_name: search.last_name,
      company: search.company,
    };
    requestInstance
      .post(API_ENDPOINT.EMAIL_FROM_NAME, search)
      .then((res) => {
        setshowProgressBar(false);
        setMessage(true);
        // console.log(res.data);

        setData(res?.data?.data);
        setShowModal(true);
        setLoading(false);
        if (res?.data?.status === "fail") {
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setshowProgressBar(false);
        setLoading(false);
        setErrMessage(true);
        setErrValue(err?.response?.statusText || "Something went wrong");
        if (err?.response?.status == 500) {
          navigate("/signin");
          toast.error(err?.response?.statusText);
        }
      });
  };

  useEffect(() => {
    let ignore = false;

    const getTableData = async () => {
      try {
        setPending(true);
        const response = await requestInstance.get(`${API_ENDPOINT.GET_ALL_SCRAPPED_DATA_API}?limit=${perPage}&offset=${(currentPage - 1) * perPage}&search=${debouncedSearchValue}`);
        if (!ignore) {
          setTablelist(response.data.results["linkedin details"]);
          setTotalRows(response.data.count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        setPending(false);
      }
    }

    getTableData();

    return () => {
      ignore = true;
    };
  }, [debouncedSearchValue, currentPage, perPage]);

  const handleStripePayment = () =>{
    setLoading(true);
    const body = {
      "session_id" : sessionId,
    }
    requestInstance
      .post(API_ENDPOINT.STRIPE_PAYMENT_HANDLE, body)
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }


  useEffect(() => {
    if(sessionId?.length>0){
      handleStripePayment();
    }
  }, [sessionId]);

  const fetchCompanyContact = (company) => {
    setcompPhoneNum("");

    setIsCompanyError(false);
    setCompanyContactInfo([]);
    setCompanyInfo([]);

    // requestInstance
    //   .post(API_ENDPOINT.COMPANY, { company })
    //   .then((res) => {
    //     if (res.data.status === "success") {
    //       setCompanyContactInfo(res.data.data);

    //       toast.success(res.data.message);
    //     } else {
    //       setIsCompanyError(true);
    //       toast.error(res.data.message);
    //     }
    //     setIsCompanyLoading(false);
    //   })
    //   .catch(() => {
    //     setIsCompanyLoading(false);
    //   });

    requestInstance
      .post(API_ENDPOINT.COMPANY_INFO, { company })
      .then((res) => {
        if (res.data.status === "success") {
          setCompanyInfo(res.data.data);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        setIsCompanyInfoLoading(false);
      })
      .catch(() => {
        setIsCompanyInfoLoading(false);
      });
  };

  useEffect(() => {
    if (forPhone.id) {
      requestInstance
        .post(`${API_ENDPOINT.COMPANY_PHONE_INFO}${forPhone.id}`, {
          company: forPhone.company,
        })
        .then((res) => {
          if (res.data.status === "success") {
            setPhoneNumbers(res.data.data.company_numbers);
            setEmails(res.data.data.company_emails);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch(() => {
          console.log("err");
        });
    }
  }, [forPhone]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profileResponse = await requestInstance.get(API_ENDPOINT.USER_PROFILE);
        dispatch(userProfile(profileResponse?.data?.data));
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    const planApi = () => {
      requestInstance
        .get(API_ENDPOINT.ACTIVE_PLAN_STATUS)
        .then((res) => {
          dispatch(planStatus(res?.data?.data));
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchProfile();
    planApi();
  }, []);

  const products = filteredData.map((data, idx) => {
    const currentPageIdx = (currentPage - 1) * perPage;
    let serialNumber;
  
    if (!isNaN(currentPageIdx) && currentPageIdx >= 0) {
      serialNumber = currentPageIdx + idx + 1;
    } else {
      serialNumber = idx + 1; // Fallback if currentPage is not properly set
    }
  
    return {
      SNo:  serialNumber,
      IMAGE: data?.image_url ? (
        <img className="img" src={data?.image_url} alt="images"></img>
      ) : (
        <img className="img" src={BlankPerson} alt="images"></img>
      ),
      FIRST_NAME: data.first_name,
      LAST_NAME: data.last_name,
      EMAIL: (
        data?.active_guess_email ? <a href={`mailto:${data?.active_guess_email}`}>{data?.active_guess_email}</a> :
        data?.company_emails.length !== 0 ? <><a href={`mailto:${data?.company_emails[0]}`}>{data?.company_emails[0]}</a><span className="text-dark text-decoration-none"> (HQ)</span></> :
        "NA"
      ),
      LINKEDIN_USERNAME: data.linkedin_user_name,
      Phone_No: data?.phone_number
        ? data?.phone_number
        : data?.company_numbers.length !== 0
        ? data?.company_numbers[0] + " (HQ)"
        : "NA",
      COMPANY: data?.company ? (
        <>
          <a
            href="#"
            onClick={() => {
              setcurrIndex(idx + 1);
              // navigate(`/c/${data.company.replace(/\s+/g, '-')}/${data.id}`)
              fetchCompanyContact(data.company);
              setShowCompanyModel(true);
              setCompanyName(data.company);
              setForPhone({ company: data.company, id: data.id });
            }}
          >
            {data.company}
          </a>
          <br />
          {currIndex === idx + 1 && compPhoneNum}
        </>
      ) : (
        "NA"
      ),
      DESIGNATION: data?.designation || data?.headline?.split("|")[0] || "NA",
      JOINED_ON: moment(data.created_at).format("DD/MM/YYYY"),
      LAST_UPDATED: moment(data.updated_at).format("DD/MM/YYYY"),
      prof: data?.profile_url ? data?.profile_url : "NA",
    };
  });

  const columns = [
    {
      name: 'S.NO',
      selector: row => row.SNo,
      sortable: true,
    },
    {
        name: 'IMAGE',
        selector: row => row.IMAGE,
        style: {
          padding: '0.5rem', 
        },
    },
    {
        name: 'FIRST NAME',
        selector: row => row.FIRST_NAME,
        sortable: true,
        grow:2,
        
    },
    {
        name: 'LAST NAME',
        selector: row => row.LAST_NAME,
        sortable: true,
        grow:2,
    },
    {
        name: 'EMAIL',
        selector: row => row.EMAIL,
        sortable: true,
        grow:3,
        wrap: true,
        style: {
          // width:'30rem',
          whiteSpace: 'normal', // Setting 'normal' allows text to wrap
          wordWrap: 'break-word', // This property will force long words to break
        },
    },
    {
        name: 'LINKEDIN USERNAME',
        selector: row => row.LINKEDIN_USERNAME,
        sortable: true,
        grow:3,
        wrap: true,
        style: {
          width:'30rem',
          whiteSpace: 'normal', // Setting 'normal' allows text to wrap
          wordWrap: 'break-word', // This property will force long words to break
        },
    },
    {
        name: 'PHONE NO',
        selector: row => row.Phone_No,
        sortable: true,
        grow:3,
        wrap: true, // This property will wrap the content to the next line
        style: {
          width:'30rem',
          whiteSpace: 'normal', // Setting 'normal' allows text to wrap
          wordWrap: 'break-word', // This property will force long words to break
        },
    },
    {
        name: 'COMPANY',
        selector: row => row.COMPANY,
        sortable: true,
        grow:2,
        style:{
          width:'30rem'
        },
        wrap: true, // This property will wrap the content to the next line
        style: {
          whiteSpace: 'normal', // Setting 'normal' allows text to wrap
          wordWrap: 'break-word', // This property will force long words to break
        },
    },
    {
        name: 'DESIGNATION',
        selector: row => row.DESIGNATION.replace(/<[^>]*>/g, ''),
        sortable: true,
        grow:3,
        style:{
          width:'30rem'
        },
        wrap: true, // This property will wrap the content to the next line
        style: {
          whiteSpace: 'normal', // Setting 'normal' allows text to wrap
          wordWrap: 'break-word', // This property will force long words to break
        },
    },
    {
        name: 'JOINED ON',
        selector: row => row.JOINED_ON,
        sortable: true,
        grow: 2,
    },
    {
      name: 'LAST UPDATED',
      selector: row => row.LAST_UPDATED,
      sortable: true,
      grow: 2,
    },
    {
      name: 'PROFILE URL',
      selector: row => ( 
        <a href={row.prof} target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
              <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
            </svg>
      </a>),
      sortable: true,
      grow: 2,
    },
];

  paginationFactory({
    sizePerPage: 10,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  });

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month index starts from 0
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const maxDate = new Date();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDateChange = (selectedDate) => {
    if (selectedDate && selectedDate instanceof Date && !isNaN(selectedDate)) {
      if (selectedDate > endDate) {
        setEndDate(selectedDate);
      }      
      setStartDate(selectedDate);
      // console.log(formattedDate, "start date");
    } else {
      console.error("Invalid start date");
    }
  };

  const handleEndDateChange = (selectedDate) => {
    if (selectedDate && selectedDate instanceof Date && !isNaN(selectedDate)) {
      if (selectedDate < startDate) {        
        setEndDate(startDate); 
      } else {
        setEndDate(selectedDate); 
      }
      // console.log(formattedDate, "end date");
    } else {
      console.error("Invalid end date");
    }
  };

  // API for download excelsheet
  const handleExportToFile = () => {
    const token = JSON.parse(localStorage.getItem("find_email"));
    let url = `https://api.discovemail.com/user/get/excel/data`
    if (startDate && endDate) {
      url = url + `?start_date=${formatDate(startDate)}&end_date=${formatDate(endDate)}`
    }
    axios({
      url: url ,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}  `,
      },
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;

        toast.success("Download successfull");
        link.setAttribute("download", "Discovemail.csv"); //or any other extension    document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.log(err.response);
        // window.location.reload(true)
        if (err?.response?.status === 401) {
          navigate("/signin");
          toast.error(err?.response?.statusText);
        }
      });
  };

  const delay =  (time) => {
    return new Promise (res => setTimeout(res, time))
  }

  useEffect(() => {
    setFilteredData(
      tablelist.filter((data) =>
        Object.values(data)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [tablelist]);

  const GenerateCompanyInfo = () => {
    

    return (
      <>
       

<CompanyOverview
            phone={phoneNumbers}
            email={emails}
            info={companyInfo}
            name={companyName}
            onClose={() => setShowCompanyModel()}
          />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Discovemail-dashboard | The Best Online E-mail Checker To Verify E-mail Addresses For Free.</title>
        <meta
          name="description"
          content="Discovemail Is The Free Online E-mail Checker To Verify Your E-mail Addresses Ensuring Accurate And Valid Contact And Email Information For Successful Communication."
        />
      </Helmet>
      <div>
      <Navbar_Dash />

        {/* ---Search Field--- */}
        <div className="bg_color_dark">
          <div className="d-flex justify-content-between flex-column flex-md-row paddingTop8rem px-3 px-md-5 pb-5">
          <div className="form-group col-md-3 pb-3 pb-md-0 ">
              <div className=" input-group search-box">
              <span className="input-group-text login_icon">
                <BiSearch />
              </span>
              <input
                placeholder=" Search"
                type="text"
                className=" form-control py-2"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex gap-3 align-items-center">

            <button className="btn btn-disco" >
              <a href="https://chromewebstore.google.com/detail/discovemail/hbfdhianoohibldpghooaeeamjhcjhjn" target="_blank" className="text-decoration-none text-white" >Chrome Extension</a>
            </button>

            <button
              className="btn btn-disco px-5 "
              data-bs-toggle="modal" data-bs-target="#exampleModal"
            >
              {" "}
              <RiFileExcel2Line /> Export to spreadsheet
            </button>
          </div>
          </div>
        </div>

        {/* ---Table--- */}

        {/* Show Company Info using popup */}

        <Modal
          show={showCompanyModel}
          onHide={() => setShowCompanyModel(false)}
          centered
          size="xl"
        >
          <Modal.Body className="default850pxModelHeight">
            <GenerateCompanyInfo />
          </Modal.Body>
        </Modal>

        <div className="table-responsive px-3 px-md-5 pt-0 dashboard_table">
        <DataTable
            columns={columns}
            data={products}
            progressPending={pending}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={(newPerPage) => setPerPage(newPerPage)}
            onChangePage={(page) => setCurrentPage(page)}
          />
        </div>
      </div>

      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Export to SpreadSheet</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='my-4'>

                <div className='form-check form-check-inline'>
                  <input
                    type='radio'
                    className='form-check-input'
                    id='exportAllList'
                    name='exportAllList'
                    value='exportAllList'
                    checked={selectedOption === 'exportAllList'}
                    onChange={handleOptionChange}
                  />
                  <label className='form-check-label' htmlFor='exportAllList'>
                    Export All list
                  </label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    type='radio'
                    className='form-check-input'
                    id='exportDateFilter'
                    name='exportDateFilter'
                    value='exportDateFilter'
                    checked={selectedOption === 'exportDateFilter'}
                    onChange={handleOptionChange}
                  />
                  <label className='form-check-label' htmlFor='exportDateFilter'>
                    Export with date Filter
                  </label>
                </div>
                
                {selectedOption === 'exportDateFilter' && (
                <div className="d-flex flex-row mt-3">
                  <div>
                    <label>Start Date</label>
                      <DatePicker selected={startDate} onChange={handleStartDateChange} dateFormat="dd-MM-yyyy" maxDate={maxDate} />
                  </div>
                  <div>
                    <label>End Date</label>
                      <DatePicker selected={endDate} onChange={handleEndDateChange} dateFormat="dd-MM-yyyy" maxDate={maxDate} />
                  </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={handleExportToFile} disabled={selectedOption === 'exportDateFilter' && startDate== null}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download mx-2" viewBox="0 0 16 16">
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>Download</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewDashboard;
