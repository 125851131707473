import React from "react";
import { createBrowserRouter, Route, RouterProvider, Routes } from "react-router-dom";
import MainBody from "./pages/dashboard/MainBody";

import { GAuthVerification, ProtectedRoute, RoleAuth } from "./utils/authHelper";
import Privacy from "./pages/privacyPolicy/Privacy";

import EmailLink from "./pages/resetPassword/EmailLink";
import Password from "./pages/resetPassword/Password";

import Newhome from "./new_home/New";
import NewSignIn from "./pages/sigin/NewSignIn";
import NewSignUp from "./pages/sigin/NewSignUp";
import NewDashboard from "./pages/dashboard/NewDashboard";
import NewSelectPlan from "./pages/selectPlan/NewSelectPlan";
import NewPrice from "./new_home/NewPrice";

import EditProfile from "./pages/dashboard/EditProfile";
import CompanyOverView from "./pages/company-overview/";
import TrailArea from "./new_home/TrailArea";
import GoogleSheetAddOn from "./pages/google-sheet-add-on/Google-sheet-add-on";
import UnderConstruction from "./pages/under-construction/UnderConstruction";
import Campaign from "./pages/campaign";
import TermsOfService from "./pages/termsOfService/TermsOfService";
import ImportLeads from "./pages/layout/ImportLeads";
import Layout from "./pages/layout/Layout";
import AllLeads from "./pages/layout/AllLeads";
import LeadConfig from "./pages/layout/LeadConfig";
import LeadSummary from "./pages/layout/LeadSummary";
import AffiliateProgram from "./pages/affiliate-program/AffiliateProgram";
import CampaignLayout from "./pages/layout/CampaignLayout";
import Claim from "./pages/claim/Claim";
import Bulks from "./pages/bulk/Bulks";
import BulkTasks from "./pages/bulk/BulkTasks";
import BulkNew from "./pages/bulk/BulkNew";
import BulkLaunch from "./pages/bulk/BulkLaunch";
import BulkSummary from "./pages/bulk/BulkSummary";
import OurRecords from "./pages/our-records/OurRecords";
import Compare from "./pages/compare/Compare";
import Subscription from "./pages/subscription/Subscription";
import SettingsLayout from "./pages/dashboard/SettingsLayout";
import NotFound from './pages/not-found/NotFound';
import Verifier from "./pages/verifier/verifier";
import Finder from "./pages/finder/Finder";
import Faq from "./pages/faq/Faq";
import AboutUs from "./pages/about-us/AboutUs";
import BulkTasksFinder from "./pages/bulk/BulkTasksFinder";
import BulkNewFinder from "./pages/bulk/BulkNewFinder";
import BulkFinderLaunch from "./pages/bulk/BulkFinderLaunch";
import BulkFinderConfig from "./pages/bulk/BulkFinderConfig";
import BulkFinderSummary from "./pages/bulk/BulkFinderSummary";
import Discovercompany from "./pages/Discovercompany";
import Team from './pages/team/Team';
import Invite from './pages/team/Invite';


const AppRoutes = () => {
  const router = createBrowserRouter([
    {
      path: "/email-verifier",
      element: <TrailArea pageRoute='email-verifier' />
    },
    {
      path: "/domain-verifier",
      element: <TrailArea pageRoute='domain-verifier' />
    },
    {
      path: "/email-from-name-and-domain",
      element: <TrailArea pageRoute='email-from-name-and-domain' />
    },
    {
      path: "/search-company",
      element: <TrailArea pageRoute='search-company' />
    },
    {
      path: "trailarea/email-verifier",
      element: <TrailArea pageRoute='trailarea/email-verifier' />
    },
    {
      path: "trailarea/domain-verifier",
      element: <TrailArea pageRoute='trailarea/domain-verifier' />
    },
    {
      path: "trailarea/email-from-name-and-domain",
      element: <TrailArea pageRoute='trailarea/email-from-name-and-domain' />
    },
    {
      path: "trailarea/search-company",
      element: <TrailArea pageRoute='trailarea/search-company' />
    },
    {
      path: "/select-plan",
      element: <NewSelectPlan />,
    },
    {
      path: "/signup",
      element: <NewSignUp />,
    },
    {
      path: "/signin",
      element: <NewSignIn />,
    },
    {
      path: "/affiliate-program",
      element: <AffiliateProgram />,
    },
    {
      path: "/faq",
      element: <Faq />,
    },
    {
      path: "/claim",
      element: <Claim />,
    },
    {
      path: "/our-records",
      element: <OurRecords />,
    },
    {
      path: "/compare",
      element: <Compare/>,
    },
    {
      path: "/privacy-policy",
      element: <Privacy />,
    },
    {
      path: "/Privacy",
      element: <Privacy />,
    },
    {
      path: "/terms-of-service",
      element: <TermsOfService />,
    },
    {
      path: "/company",
      element: <AboutUs />,
    },
    {
      path: "/pricing",
      element: <NewPrice />,
    },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <NewDashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: "/dashboard/price1",
      element: (
        <ProtectedRoute>
          <NewPrice />
        </ProtectedRoute>
      ),
    },

    {
      path: "/c/:company",
      element: (
          <CompanyOverView />
      )
    },
    {
      path: "/our-addon",
      element: (
          <GoogleSheetAddOn />
      )
    },

    {
      path: "/resetpassword",
      element: <Password />,
    },

    {
      path: "/forget",
      element: <EmailLink />,
    },
    {
      path: "/",
      element: <Newhome />,
    },

    {
      path: "/under-construction",
      element: <UnderConstruction />
    },
    {
      path: "/campaign",
      element: (
        <ProtectedRoute>
          <Campaign />
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/content",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='content' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/audience",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='audience' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/settings",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='settings' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/review",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='review' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/summary/:id",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='summary' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/all-campaigns",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='all-campaigns' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/setting",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='setting' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/archive",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='archive' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/reporting",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='reporting' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/summary/:id/emails",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='summary' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/summary/:id/audience",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='summary' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/summary/:id/statistics",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='summary' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/summary/:id/settings",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='summary' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/summary/:id/contents",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='summary' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/lead",
      element: (
        <ProtectedRoute>
              <Layout showOutlet={true} />
        </ProtectedRoute>
      ),
      children: [
          {
            path: "import-leads",
            element: <ImportLeads />, 
          },
          {
            path: "import-leads/leads-configuration",
            element: <LeadConfig />,
          },
          {
            path: "import-leads/leads-summary",
            element: <LeadSummary />,
          },
        ],
    },
    {
      path: "/leads/:leadName",
      element: (
        <ProtectedRoute>
          <Layout showOutlet={false}/>
        </ProtectedRoute>
      ),
    },

    {
      path: "/verifier",
      element: (
        <ProtectedRoute>
          <Verifier />
        </ProtectedRoute>
      ),
    },

    {
      path: "/finder",
      element: (
        <ProtectedRoute>
          <Finder />
        </ProtectedRoute>
      ),
    },
    {
      path: "/discover",
      element: (
        <ProtectedRoute>
          <Discovercompany />
        </ProtectedRoute>
      ),
    },

    {
      path: "/bulks",
      element: (
        <ProtectedRoute>
          <Bulks />
        </ProtectedRoute>
      ),
    },
    {
      path: "/bulk-tasks/bulk-email-verifier",
      element: (
        <ProtectedRoute>
          <BulkTasks pageRoute='bulk-email-verifier'/>
        </ProtectedRoute>
      ),
    },
    {
      path: "/bulk-tasks/new-bulk-email-verifier",
      element: (
        <ProtectedRoute>
          <BulkNew pageRoute='new-bulk-email-verifier' />
        </ProtectedRoute>
      ),
    },
    {
      path: "/bulk-tasks/new-bulk-email-verifier/:id",
      element: (
        <ProtectedRoute>
          <BulkLaunch />
        </ProtectedRoute>
      ),
    },
    {
      path: "/bulk-tasks/bulk-email-verifier-summary/:id",
      element: (
        <ProtectedRoute>
          <BulkSummary />
        </ProtectedRoute>
      ),
    },
    {
      path: "/bulk-tasks-finder/bulk-email-finder",
      element: (
        <ProtectedRoute>
          <BulkTasksFinder pageRoute='bulk-email-finder' />
        </ProtectedRoute>
      ),
    },
    {
      path: "/bulk-tasks-finder/new-bulk-email-finder",
      element: (
        <ProtectedRoute>
          <BulkNewFinder pageRoute='new-bulk-email-finder' />
        </ProtectedRoute>
      ),
    },
    {
      path: "/bulk-tasks-finder/bulk-finder-config/:id",
      element: (
        <ProtectedRoute>
          <BulkFinderConfig />
        </ProtectedRoute>
      ),
    },
    {
      path: "/bulk-tasks-finder/new-bulk-email-finder/:id",
      element: (
        <ProtectedRoute>
          <BulkFinderLaunch />
        </ProtectedRoute>
      ),
    },
    {
      path: "/bulk-tasks-finder/bulk-email-finder-summary/:id",
      element: (
        <ProtectedRoute>
          <BulkFinderSummary />
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/create-template",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='create-template' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/all-template",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='all-template' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/template/:id",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='template' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/global-templates",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='global-templates' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/campaign/global-templates/:id",
      element: (
        <ProtectedRoute>
          <GAuthVerification>
            <CampaignLayout pageRoute='single-template' />
          </GAuthVerification>
        </ProtectedRoute>
      ),
    },
    {
      path: "/settings",
      element: (
        <ProtectedRoute>
              <SettingsLayout />
        </ProtectedRoute>
      ),
      children: [
          {
            path: "/settings/profile",
            element: <EditProfile />, 
          },
          {
            path: "/settings/subscription",
            element: <Subscription />,
          },
          {
            path: "/settings/team",
            element: (
              <RoleAuth allowedRoles={["Owner", "Admin"]}>
                <Team />
              </RoleAuth>
            ),
          },
          {
            path: "/settings/team/invite",
            element: (
              <RoleAuth allowedRoles={["Owner", "Admin"]}>
                <Invite />
              </RoleAuth>
            ),
          },
        ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
   
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default AppRoutes;
