import React from "react";
import "./Video.css";
import My_video from "../assets/video/Welcome_Discovemail.mp4";

function Video() {
  // const vidRef=useRef();
  // useEffect(() => {
  //     document.getElementById('vid').play();
  // }, [])

  return (
    <div className="video_container">
      <video
        width="100%"
        height="100%"
        controls
        loop
        autoPlay
        muted
        className=""
      >
        <source src={My_video} type="video/mp4" />
      </video>
    </div>
  );
}

export default Video;
