import { Fragment, useEffect, useState } from 'react';
import './globalTemplate.css';
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi2";
import requestInstance from '../../../../services/request';
import { API_ENDPOINT } from '../../../../services/Api';
import { Link } from 'react-router-dom';
import Spinner from '../../../loader/SimpleLoader';

const GlobalTemplates = () => {
  const [activeCategory, setActiveCategory] = useState('All Templates');
  const [activeSubCategory, setActiveSubCategory] = useState(null);
  const [categoryFilterData, setCategoryFilterData] = useState(null);
  const [globalTemplatesList, setGlobalTemplatesList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const getGlobalTemplatesFilterList = async () => {
      try {
        const response = await requestInstance.get(API_ENDPOINT.GLOBAL_TEMPLATES_FILTER_LIST);
        setCategoryFilterData(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    getGlobalTemplatesFilterList();
  }, []);

  useEffect(() => {
    let ignore = false;

    const getGlobalTemplatesList = async () => {
      try {
        setLoading(true);
        const response = await requestInstance.get(`${API_ENDPOINT.GLOBAL_TEMPLATES_LIST}?template_type=${activeCategory === 'All Templates' ? '' : activeCategory}&template_name=${activeSubCategory ?? ''}&limit=9&offset=${page}`);
        if (!ignore) {
          setGlobalTemplatesList(response.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    getGlobalTemplatesList();

    return () => {
      ignore = true;
    };
  }, [activeCategory, activeSubCategory, page]);

  const handleCategoryClick = (e, category) => {
    e.stopPropagation();
    e.preventDefault();
    setActiveCategory(category);
    setActiveSubCategory(null);
  };

  const handleSubCategoryClick = (e, subCategory) => {
    e.stopPropagation();
    e.preventDefault();
    setActiveSubCategory(subCategory);
  };

  return (
    <div className='container mt-4 globalTemplatesContainer'>
      <div className="row">
        <div className="col-2">
          <h6 className='mb-3'>Categories</h6>
          <ul>
            <li className={activeCategory === 'All Templates' ? 'categoryActive' : ''} onClick={() => {
              setActiveCategory('All Templates');
              setActiveSubCategory(null);
              setPage(0);
            }}>
              <span>All Templates</span>
            </li>
            {
              categoryFilterData?.data?.map((category) => (
                <Fragment key={category?.id}>
                  <li className={`${activeCategory === category?.template_type ? 'categoryActive' : ''} d-flex justify-content-between align-items-center category`} onClick={() => {
                    setActiveCategory(category?.template_type);
                    setActiveSubCategory(null);
                    setPage(0);
                  }}>
                    <span>{category?.template_type}</span>
                    {activeCategory === category?.template_type ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />}
                  </li>
                  <ul className={`${activeCategory === category?.template_type ? '' : 'subCategoryInActive'} subCategory`}>
                    {
                      category?.template_names?.map((subCategory) => (
                        <li className={`${activeSubCategory === subCategory?.template_name ? 'categoryActive' : ''} subCategoryListItem`} onClick={() => {
                          setActiveSubCategory(subCategory?.template_name)
                          setPage(0);
                        }} key={subCategory?.id}>{subCategory?.template_name}</li>
                      ))
                    }
                  </ul>
                </Fragment>
              ))
            }
          </ul>
        </div>
        {
          loading ? <Spinner /> : <div className="col-10">
            <p className='templateCount'>{globalTemplatesList?.count} email templates</p>
            <div className="row g-4">
              {
                globalTemplatesList?.data?.map((template) => (
                  <Link to={`/campaign/global-templates/${template?.id}`} key={template?.id} className="col-4 cardContainer">
                    <div className="templateCard">
                      <div className='templateSubject'>{template?.template_subject}</div>
                      <br />
                      <div className='templateBody' dangerouslySetInnerHTML={{ __html: template?.template_body }}></div>
                      <div className='templateFilter'>
                        <span onClick={(e) => handleCategoryClick(e, template?.template_type)}>{template?.template_type}</span>
                        <span onClick={(e) => handleSubCategoryClick(e, template?.template_name)}>{template?.template_name}</span>
                      </div>
                    </div>
                  </Link>
                ))
              }
            </div>
            <div className="d-flex justify-content-center align-items-center mt-5 mb-3 pagination">
              <button className={`${page === 0 ? 'disabled' : ''} btn btn-danger`} onClick={() => setPage(page - 9)}>Previous</button>
              <p className='m-0 mx-5'>Page {page / 9 + 1} / {Math.ceil(globalTemplatesList?.count / 9)}</p>
              <button className={`${globalTemplatesList?.count < page + 9 ? 'disabled' : ''} btn btn-danger`} onClick={() => setPage(page + 9)}>Next</button>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default GlobalTemplates;
