import React, { useEffect, useState } from 'react'
import requestInstance from '../../../services/request';
import { API_ENDPOINT } from '../../../services/Api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { googleAuth,googleSign, resetGoogleAuth } from '../../../redux/slices/googleAuth/GoogleAuthSlice';

const CampaignSetting = () => {
    const [email, setEmail] = useState();
    const [loading, setLoading] = useState(false);
    const  navigate = useNavigate();
    const dispatch = useDispatch();

    //   ---API for newCampaignGooogleApi---
  const campaignGoogleContent = async() => {
    setLoading(true)
    await requestInstance
      .get(API_ENDPOINT.CAMPAIGN_SIGNATURE_API)
      .then((res) => {
        dispatch(googleAuth(true));
        dispatch(googleSign(res?.data?.data));
        setEmail(res?.data?.data?.email);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleDelete = async() =>{
    setLoading(true)
    await requestInstance
      .get(API_ENDPOINT.GOOGLE_AUTH_REVOKE)
      .then((res) => {
          setLoading(false);
          if(res.data.status === 200 || 400 || 401 || 500){
            dispatch(resetGoogleAuth());
            navigate('/campaign');
          }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    campaignGoogleContent();
  }, [])
  
  return (
    <div>
        <h5 className='text-start fw-semibold mt-3'>Campaigns settings</h5>

        <div className='mt-5 px-2'>
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                       <div>
                        <h6 className='fw-semibold'>Email Account</h6>
                        <small className='text-secondary'>Control and manage the email accounts connected to Discovemail Campaigns.</small>
                        </div> 
                    </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div className="accordion-body px-3">
                            <div className='row'>
                                <div className='col-12 overflow-auto'>
                                <table className="campaignTable table">
                                    <thead>
                                    <tr>
                                        <th scope="col" className='px-3'>EMAIL </th>
                                        <th scope="col" className='px-3'>STATUS</th>
                                        <th scope="col" className='px-3'>TYPE</th>
                                        <th scope="col" className='px-3'></th>
                                    </tr>
                                    </thead>
                                   { loading ? <p>Loading...</p> :<tbody>
                                        <tr className='bgBlueHover'>
                                        <td className='px-3 py-4'>
                                            <div className=''>
                                            <p className='campaignTitle mb-2'>{email}</p>
                                            </div>
                                        </td>
                                        <td className='px-3 py-4'>
                                        <div className='text-success'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-patch-check-fill me-2" viewBox="0 0 16 16">
                                        <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"/>
                                        </svg>{'Connected'}
                                        </div>
                                        </td>
                                        <td className='px-3 py-4'>{'Gmail'}</td>
                                    
                                        <td className='px-3 text-end cursorPointer text-danger py-4'><button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal">Delete</button></td>
                                        </tr>
                                    </tbody>}
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="accordion-item">
                    <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Accordion Item #2
                    </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                    </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Accordion Item #3
                    </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                    </div>
                    </div>
                </div> */}
            </div>

           {/* Modal starts */}
    
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-6" id="exampleModalLabel">Disconnect the account {email}</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                         <p>Are you sure you want to disconnect this email account? Doing so will result in the cancellation of all scheduled emails and a pause in the active campaigns associated with this account.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={handleDelete}>Disconnect</button>
                    </div>
                    </div>
                </div>
                </div>

           {/* Modal Ends */}
        </div>
    </div>
  )
}

export default CampaignSetting;