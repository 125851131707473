import React, { useEffect, useRef, useState } from "react";
import requestInstance from "../../../services/request";
import { API_ENDPOINT } from "../../../services/Api";
import "../review/CampaignReview.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineStop } from "react-icons/ai";
import {
  campaignName,
  campaignSummaryId,
} from "../../../redux/slices/campaign/CampaignSlice";
import CampaignDetailEmail from "./CampaignDetailEmail";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import EmailAudience from "./EmailAudience";
import CampaignStatistics from './CampaignStatistics';
import CampaignSetting from "./CampaignSettings";
import ContentSummary from "../campaign-contents/ContentSummary";

const CampaignSummary = (props) => {
  const [summaryContent, setSummaryContent] = useState();
  const [followUpContent, setFollowUpContent] = useState(null);
  const [showFollowupList, setshowFollowupList] = useState(false);
  const [followupListData, setfollowupListData] = useState(null);
  const [followupMailId, setfollwupMailId] = useState("");
  const [selectedIndex, setselectedIndex] = useState("");
  const [latestPath, setLatestPath] = useState('emails');
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const campaignSummaryId = useSelector(
    (state) => state.campaign.campaignSummaryId
  );
  const followUpRef = useRef(null);
  const tab = location.pathname.split('/').at(-1)

  //   ---API for newCampaignApi---
  const campaignSummaryContent = async () => {
    await requestInstance
      .get(API_ENDPOINT.CAMPAIGN_SUMMARY_CONTENT + `/${id}` )
      .then((res) => {
        setSummaryContent(res?.data?.data?.email_audience_content_data);
        dispatch(
          campaignName(
            res?.data?.data?.campaign_email_content_data?.campaign_name
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const colorPallet = {
    Error: "text-danger",
    Scheduled: "text-warning",
    "Email sent": "text-success",
    Opened: "text-info",
    "In-Progress": "text-dark",
  };
  
  const handleFollowupClick = () => {
    setshowFollowupList((prev) => !prev);
  };
  useEffect(() => {
    campaignSummaryContent();
  }, []);

  useEffect(() => {
    requestInstance
      .get(API_ENDPOINT.FOLLOWUP_LISTING + `${campaignSummaryId}`)
      .then((res) => {
        setfollowupListData(res.data.data);
      });
  }, [showFollowupList]);

  useEffect(() => {
    if (followupMailId) {
      requestInstance
        .get(
          API_ENDPOINT.FOLLOWUP_MAIL_DISLAY + `?followup_id=${followupMailId}`
        )
        .then((res) => {
          setFollowUpContent(res.data.data);
        });
    }
  }, [followupMailId]);

  
  const handleFollowUpstop =(stopId)=>{
    const stopFollowUpId = {followup_email_id: stopId}
    requestInstance
    .put(`${API_ENDPOINT.FOLLOWUP_STOP}${campaignSummaryId}`, JSON.stringify(stopFollowUpId))
    .then((res)=>{
      toast.success("Follow-Up Stopped Successfully")
    })
    .catch((error) => {
      console.error('Error sending data:', error);
    });
  }

  const handleFollowupSelect = (e) => {
    setfollwupMailId(e.target.value);
  };

  return (
    <div className="CampaignReview col-12 pb-3 px-5">

    {/* ...........NEW HEADER SECTION START...........  */}
      <div className="campaignDetailHeader">
        <ul className="nav nav-underline">
          <li className="nav-item" >
            <Link className={`nav-link ${tab === 'content' ? 'activeHeader' : ''}`} to={`/campaign/summary/${id}/contents`} style={{color:'#707880'}}>
              Content
            </Link>
          </li>
          <li className="nav-item">
            <Link className={`nav-link ${tab === 'audience' ? 'activeHeader' : ''}`} to={`/campaign/summary/${id}/audience`} style={{color:'#707880'}}>
              Audience
            </Link>
          </li>
          <li className="nav-item">
            <Link className={`nav-link ${tab === 'emails' ? 'activeHeader' : ''}`} to={`/campaign/summary/${id}/emails`} aria-current="page" style={{color:'#707880'}}>
              Emails
            </Link>
          </li>
          <li className="nav-item">
            <Link className={`nav-link ${tab === 'settings' ? 'activeHeader' : ''}`} to={`/campaign/summary/${id}/settings`} style={{color:'#707880'}}>
              Settings
            </Link>
          </li>
          <li className="nav-item">
            <Link className={`nav-link ${tab === 'statistics' ? 'activeHeader' : ''}`} to={`/campaign/summary/${id}/statistics`} style={{color:'#707880'}}>
              Statistics
            </Link>
          </li>
        </ul>
      </div>

      {
        tab === 'emails' ? 
        <CampaignDetailEmail /> : tab === 'audience' ? 
            <EmailAudience /> : tab === 'statistics' ? <CampaignStatistics /> : tab === 'settings' ? <CampaignSetting /> :tab === 'contents' ? <ContentSummary /> : null }
      

    </div>
  );
};

export default CampaignSummary;
