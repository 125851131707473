import React, { useState } from "react";
import NavbarDash from "../pages/dashboard/Navbar";
import { priceCardData } from "../resource/data/priceCardData";
import { MdDone, MdClose } from "react-icons/md";
import Slider from "react-slick";
import TickCircle from "../assets/tickCircle.svg";
import requestInstance from "../services/request";
import { API_ENDPOINT } from "../services/Api";
// import { Helmet } from "react-helmet";
import { loadStripe } from '@stripe/stripe-js';
import { toast } from "react-toastify";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import Navbar from "./Navbar_1";
import Footer from "./Footer";

function NewPrice() {
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('find_email');
  const navigate = useNavigate();

  const handlePayment = async (val) => {
    if (token?.length > 0) {
      setLoading(true);
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

      const body = {
        "plan_type": val,
      }

      requestInstance
        .post(API_ENDPOINT.STRIPE_PAYMENT_CHECKOUT, body)
        .then((res) => {
          // toast.success(res.data.message);
          const session = res?.data?.data;
          const result = stripe.redirectToCheckout({ sessionId: session.sessionId })
          if (result.error) {
            console.log(result.error);
          }
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    } else {
      navigate("/signin");
    }
  }

  const generalQuestion = [
    {
      id: 1,
      title: "What is the best time to use 1 credit on Discovemail.com?",
      description:
        "Each email request costs one credit on DiscoveMail.com. You can sign up for the 100% free plan at Discovemail.com.You can select one of our paying plans if you want some of the more advanced features. Our goal is to assist as many individuals as we can in meeting their prospecting needs without trying to take advantage of them financially.",
    },
    {
      id: 2,
      title: "What is the accuracy of Discovemail Email Finder ?",
      description:
        "The accuracy of Discovemail Email Finder can vary, but it's designed to provide reliable results by leveraging multiple data sources and advanced algorithms.Many users find it to be an efficient tool for discovering email addresses, especially for outreach and lead generation.However, as with any email finder tool, it's important to verify email addresses for accuracy before using them for communication to ensure successful outreach.",
    },
    {
      id: 3,
      title: "Are the email addresses guesses or found somewhere ?",
      description:
        "Email addresses found using an email finder tool like Discovemail are typically not guesses. Instead, these tools search for email addresses that are publicly available or have been published on websites, social media profiles, online directories, or other publicly accessible sources. The process typically involves web scraping and data mining techniques to locate and compile email addresses from various online sources. It doesn't involve random guessing of email addresses, as that would be highly inefficient and unlikely to yield accurate results.",
    },

  ];

  const generalQuestion2 = [
    {
      id: 4,
      title: "Can I find the email addresses of a list of people ?",
      description:
        "Yes, We have a bulk email verification option to verify email addresses. You have to just upload the file and press enter it will validate and give the results.",
    },
    {
      id: 5,
      title: "How is the Discovemail different from other email search tools ?",
      description:
        "The Discovemail automates email searches using various data sources, making it efficient for bulk searches and potentially offering email verification, integration, and privacy features that distinguish it from manual email search methods or other tools.",
    },
    {
      id: 6,
      title: "Does Discovemail send out any e-mail message while email verify?",
      description:
        "No, Discovemail doesn't send out any e-mail message while email verify",
    },

  ];

  var settings = {

    infinite: true,
    slidesToShow: 4,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const [hover, setHover] = useState({
    id: priceCardData[0].id,
  });

  const MouseOver = (e, ele) => {
    setHover({
      id: ele.id,
    });
  };

  const MouseOut = (e, ele) => {
    setHover({
      id: ele.id,
    });
  };

  const handleRedirect = (type) => {
    window.open(window.location.origin + `/select-plan?plan=${type}`, "_blank");
  };

  const user = JSON.parse(localStorage.getItem("find_email"));
  return (
    <>
      {token?.length > 0 ? <NavbarDash /> : <Navbar/>}
      <section className={(window.location.pathname === "/pricing" ? 'mt-5 ' : '') + "pricePlan pt-md-5 py-5 py-md-0"} id="price">
        <div className={(window.location.pathname === "/pricing" ? 'mt-5 ' : '') + " container"}>
          <div className="row justify-content-center">
            <div className="col-12">
              <h1 className="mainTitle text-center">Select the plan that best fits your needs.</h1>
            </div>
            <div className=" col-md-8 pb-lg-5">
              <p className="mainDescription text-center px-3">Every subscription includes a complimentary demo.</p>
            </div>
            <div className='col-lg-12 workWithUsWeb pb-5 d-xl-block d-none'>
              <div className='row justify-content-between'>
                <div className='col-3 comparisonSection pt-5 px-0'>
                  <div className='comparisonSection'>
                    <div className='comparisonTitle text-center'>
                      <h3 className='comparisonTitleTxt '></h3>
                    </div>
                    <div className='comparisonItem py-3 d-flex align-items-center justify-content-between'>
                      <h6 className='itemTxt m-0 fs-5'>Email Authenticity Verification</h6>
                    </div>

                    <div className='comparisonItem subtxt_color d-flex align-items-center justify-content-between'>
                      <p className='itemTxt fs-5m-0'>Yearly searches</p>
                    </div>

                    <div className='comparisonItem subtxt_color d-flex align-items-center justify-content-between'>
                      <h6 className='itemTxt  m-0'>Additional searches</h6>
                    </div>

                    <div className='comparisonItem subtxt_color d-flex align-items-center justify-content-between'>
                      <p className='itemTxt fs-5 m-0'>Yearly verifications</p>
                    </div>

                    <div className='comparisonItem subtxt_color d-flex align-items-center justify-content-between'>
                      <h6 className='itemTxt  m-0'>Additional verifications</h6>
                    </div>

                    <div className='comparisonItem d-flex align-items-center justify-content-between'>
                      <p className='itemTxt fs-5 m-0'>Domain Search</p>
                    </div>

                    <div className='comparisonItem subtxt_color d-flex align-items-center justify-content-between'>
                      <h6 className='itemTxt  m-0'>Company search</h6>
                    </div>

                    <div className='comparisonItem subtxt_color d-flex align-items-center justify-content-between'>
                      <h6 className='itemTxt  m-0'>CSV exports</h6>
                    </div>

                    <div className='comparisonItem py-3 d-flex align-items-center justify-content-between'>
                      <h6 className='itemTxt m-0 fs-5'>Campaigns</h6>
                    </div>

                    <div className='comparisonItem subtxt_color d-flex align-items-center justify-content-between'>
                      <p className='itemTxt fs-5 m-0'>Linked email addresses</p>
                    </div>

                    <div className='comparisonItem subtxt_color d-flex align-items-center justify-content-between'>
                      <p className='itemTxt fs-5 m-0'>Email account cycling</p>
                    </div>

                    <div className='comparisonItem subtxt_color d-flex align-items-center justify-content-between'>
                      <p className='itemTxt fs-5 m-0'>Attachments & images in emails</p>
                    </div>

                    <div className='comparisonItem subtxt_color d-flex align-items-center justify-content-between'>
                      <p className='itemTxt fs-5 m-0'>Link tracking</p>
                    </div>

                    <div className='comparisonItem subtxt_color d-flex align-items-center justify-content-between'>
                      <p className='itemTxt fs-5 m-0'>Custom tracking domain</p>
                    </div>

                    <div className='comparisonItem subtxt_color d-flex align-items-center justify-content-between'>
                      <p className='itemTxt fs-5 m-0'>Reporting</p>
                    </div>

                    <div className='comparisonItem subtxt_color d-flex align-items-center justify-content-between'>
                      <p className='itemTxt fs-5 m-0'>Recipients per campaign</p>
                    </div>

                    <div className='comparisonItem py-3 d-flex align-items-center justify-content-between  '>
                      <h6 className='itemTxt m-0 fs-5'>Extensions & Enhancements</h6>
                    </div>

                    <div className='comparisonItem subtxt_color d-flex align-items-center justify-content-between  '>
                      <p className='itemTxt fs-5 m-0'>Browser extensions</p>
                    </div>

                    <div className='comparisonItem subtxt_color d-flex align-items-center justify-content-between  '>
                      <p className='itemTxt fs-5 m-0'>Google Sheets add-on</p>
                    </div>

                    <div className='comparisonItem subtxt_color py-3 d-flex align-items-center justify-content-between  '>
                      <h6 className='itemTxt m-0 fs-5'>No Team Member Limit</h6>
                    </div>

                    <div className='comparisonItem subtxt_color py-3 d-flex align-items-center justify-content-between  '>
                      <h6 className='itemTxt m-0 fs-5'>Support</h6>
                    </div>

                  </div>
                </div>

                <div className='col-2 px-0 infoSection text-center pt-5'>
                  <div className='infoSectionTitle'>
                    <h3 className="title">Free</h3>
                    <p className="priceText subtxt_color d-flex justify-content-center align-items-center"><span className="fw-bolder fs-2 text-light">$0</span>&nbsp;&nbsp;/month</p>
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <p className="mb-0">100</p>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <p className="mb-0">100</p>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center'>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center'>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center'>
                    <p className="mb-0">1</p>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center'>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center'>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center'>
                    <MdClose className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center'>
                    <MdClose className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center'>
                    <MdClose className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center'>
                    <p className="mb-0">500</p>
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center'>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center'>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center'>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                    <MdClose className="fs-4" />
                  </div>
                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                    <div className="d-grid gap-2 my-3">
                      <button className="btn btn-disco fw-bold" type="button" onClick={() => handlePayment('free')}>CHOOSE PLAN</button>
                    </div>
                  </div>

                </div>

                <div className='col-2 px-0 infoSection text-center pt-5'>
                  <div className='infoSectionTitle px-3'>
                    <h3 className="title">Basic</h3>
                    <p className="priceText subtxt_color d-flex justify-content-center align-items-baseline"><span className="fw-bolder fs-2 text-light">$40</span>&nbsp;&nbsp;/month</p>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <p className="mb-0">20,000 Credits</p>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <p className="mb-0">20,000 Credits</p>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem py-3  d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                    <p className="mb-0">Email</p>
                  </div>
                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                    <div className="d-grid gap-2 my-3">
                      <button className="btn btn-disco fw-bold" type="button" onClick={() => handlePayment('basic')}>CHOOSE PLAN</button>
                    </div>
                  </div>
                </div>

                <div className='col-2 px-0 infoSection text-center pt-5'>
                  <div className='infoSectionTitle px-3'>
                    <h3 className="title">Standard</h3>
                    <p className="priceText d-flex subtxt_color justify-content-center align-items-baseline"><span className="fw-bolder fs-2 text-light">$130</span>&nbsp;&nbsp;/month</p>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <p className="mb-0">33,500 Credits</p>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <p className="mb-0">33,500 Credits</p>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                    <p className="mb-0">Email + Chat + Phone</p>
                  </div>
                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                    <div className="d-grid gap-2 my-3">
                      <button className="btn btn-disco fw-bold" type="button" onClick={() => handlePayment('standard')}>CHOOSE PLAN</button>
                    </div>
                  </div>
                </div>
                <div className='col-2 px-0 infoSection text-center pt-5'>
                  <div className='infoSectionTitle px-3'>
                    <h3 className="title">Premium</h3>
                    <p className="priceText d-flex justify-content-center subtxt_color align-items-baseline"><span className="fw-bolder fs-2 text-light">$449</span>&nbsp;&nbsp;/month</p>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <p className="mb-0">70,000 Credits</p>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <p className="mb-0">70,000 Credits</p>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem  py-3 d-flex align-items-center justify-content-center  '>
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                    <MdDone className="fs-4" />
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                    <p className="mb-0">Email + Chat + Phone</p>
                  </div>

                  <div className='infoSectionItem py-3 d-flex align-items-center justify-content-center  '>
                    <div className="d-grid gap-2 my-3">
                      <button className="btn btn-disco " type="button" onClick={() => handlePayment('premium')}>CHOOSE PLAN</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-11 priceCardMobile workWithUsWeb d-block d-xl-none py-5 rounded-4 mb-5">
              <h3 className="title">Free</h3>
              <p className="priceText d-flex justify-content-center align-items-center">$&nbsp;<span className="fw-bolder fs-2">0</span>&nbsp;month</p>
              <div className="row">
                <div className="col-12">
                  <h6 className='itemTxt fs-5 text-dark'>Email Authenticity Verification</h6>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Yearly searches</p>
                </div>
                <div className="col-4">
                  <p className="">100</p>
                </div>
                <div className="col-8">
                  <h6 className='itemTxt '>Additional searches</h6>
                </div>
                <div className="col-4">
                  <p className=""></p>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Monthly verifications</p>
                </div>
                <div className="col-4">
                  <p className="">100</p>
                </div>
                <div className="col-8">
                  <h6 className='itemTxt  m-0'>Additional verifications</h6>
                </div>
                <div className="col-4">
                  <p className=""></p>
                </div>
                <div className="col-12">
                  <p className='itemTxt fs-5 text-dark'>Domain Search</p>
                </div>
                <div className="col-8">
                  <h6 className='itemTxt '>Company search</h6>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <h6 className='itemTxt'>CSV exports</h6>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-12">
                  <h6 className='itemTxt fs-5 text-dark'>Campaigns</h6>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Linked email addresses</p>
                </div>
                <div className="col-4">
                  <p className="">1</p>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Email account cycling</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Attachments & images in emails</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Link tracking</p>
                </div>
                <div className="col-4">
                  <MdClose className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Custom tracking domain</p>
                </div>
                <div className="col-4">
                  <MdClose className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Reporting</p>
                </div>
                <div className="col-4">
                  <MdClose className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Recipients per campaign</p>
                </div>
                <div className="col-4">
                  <p className="">500</p>
                </div>
                <div className="col-12">
                  <h6 className='itemTxt fs-5 text-dark'>Extensions & Enhancements</h6>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Browser extensions</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Google Sheets add-on</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <h6 className='itemTxt fs-5 text-dark'>No Team Member Limit</h6>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <h6 className='itemTxt fs-5 text-dark'>Support</h6>
                </div>
                <div className="col-4">
                  <MdClose className="fs-4" />
                </div>
              </div>
            </div>
            <div className="col-11 priceCardMobile workWithUsWeb d-block d-xl-none py-5 rounded-4 mb-5">
              <h3 className="title">Basic</h3>
              <p className="priceText d-flex justify-content-center align-items-center">$&nbsp;<span className="fw-bolder fs-2">40</span>&nbsp;month</p>
              <div className="row">
                <div className="col-12">
                  <h6 className='itemTxt fs-5 text-dark'>Email Authenticity Verification</h6>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Yearly searches</p>
                </div>
                <div className="col-4">
                  <p className="">20,000</p>
                </div>
                <div className="col-8">
                  <h6 className='itemTxt '>Additional searches</h6>
                </div>
                <div className="col-4">
                  <p className=""></p>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Yearlyly verifications</p>
                </div>
                <div className="col-4">
                  <p className="">20,000</p>
                </div>
                <div className="col-8">
                  <h6 className='itemTxt  m-0'>Additional verifications</h6>
                </div>
                <div className="col-4">
                  <p className=""></p>
                </div>
                <div className="col-12">
                  <p className='itemTxt fs-5 text-dark'>Domain Search</p>
                </div>
                <div className="col-8">
                  <h6 className='itemTxt '>Company search</h6>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <h6 className='itemTxt'>CSV exports</h6>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-12">
                  <h6 className='itemTxt fs-5 text-dark'>Campaigns</h6>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Linked email addresses</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Email account cycling</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Attachments & images in emails</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Link tracking</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Custom tracking domain</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Reporting</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Recipients per campaign</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-12">
                  <h6 className='itemTxt fs-5 text-dark'>Extensions & Enhancements</h6>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Browser extensions</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Google Sheets add-on</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <h6 className='itemTxt fs-5 text-dark'>No Team Member Limit</h6>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <h6 className='itemTxt fs-5 text-dark'>Support</h6>
                </div>
                <div className="col-4">
                  <p className="">Email</p>
                </div>
              </div>
            </div>
            <div className="col-11 priceCardMobile workWithUsWeb d-block d-xl-none py-5 rounded-4 mb-5">
              <h3 className="title">Standard</h3>
              <p className="priceText d-flex justify-content-center align-items-center">$&nbsp;<span className="fw-bolder fs-2">130</span>&nbsp;month</p>
              <div className="row">
                <div className="col-12">
                  <h6 className='itemTxt fs-5 text-dark'>Email Authenticity Verification</h6>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Yearly searches</p>
                </div>
                <div className="col-4">
                  <p className="">33,500</p>
                </div>
                <div className="col-8">
                  <h6 className='itemTxt '>Additional searches</h6>
                </div>
                <div className="col-4">
                  <p className=""></p>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Yearly verifications</p>
                </div>
                <div className="col-4">
                  <p className="">33,500</p>
                </div>
                <div className="col-8">
                  <h6 className='itemTxt  m-0'>Additional verifications</h6>
                </div>
                <div className="col-4">
                  <p className=""></p>
                </div>
                <div className="col-12">
                  <p className='itemTxt fs-5 text-dark'>Domain Search</p>
                </div>
                <div className="col-8">
                  <h6 className='itemTxt '>Company search</h6>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <h6 className='itemTxt'>CSV exports</h6>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-12">
                  <h6 className='itemTxt fs-5 text-dark'>Campaigns</h6>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Linked email addresses</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Email account cycling</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Attachments & images in emails</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Link tracking</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Custom tracking domain</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Reporting</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Recipients per campaign</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-12">
                  <h6 className='itemTxt fs-5 text-dark'>Extensions & Enhancements</h6>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Browser extensions</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Google Sheets add-on</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <h6 className='itemTxt fs-5 text-dark'>No Team Member Limit</h6>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <h6 className='itemTxt fs-5 text-dark'>Support</h6>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
              </div>
            </div>
            <div className="col-11 priceCardMobile workWithUsWeb d-block d-xl-none py-5 rounded-4 ">
              <h3 className="title">Premium</h3>
              <p className="priceText d-flex justify-content-center align-items-center">$&nbsp;<span className="fw-bolder fs-2">449</span>&nbsp;month</p>
              <div className="row">
                <div className="col-12">
                  <h6 className='itemTxt fs-5 text-dark'>Email Authenticity Verification</h6>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Yearly searches</p>
                </div>
                <div className="col-4">
                  <p className="">70,000</p>
                </div>
                <div className="col-8">
                  <h6 className='itemTxt '>Additional searches</h6>
                </div>
                <div className="col-4">
                  <p className=""></p>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Yearly verifications</p>
                </div>
                <div className="col-4">
                  <p className="">70,000</p>
                </div>
                <div className="col-8">
                  <h6 className='itemTxt  m-0'>Additional verifications</h6>
                </div>
                <div className="col-4">
                  <p className=""></p>
                </div>
                <div className="col-12">
                  <p className='itemTxt fs-5 text-dark'>Domain Search</p>
                </div>
                <div className="col-8">
                  <h6 className='itemTxt '>Company search</h6>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <h6 className='itemTxt'>CSV exports</h6>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-12">
                  <h6 className='itemTxt fs-5 text-dark'>Campaigns</h6>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Linked email addresses</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Email account cycling</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Attachments & images in emails</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Link tracking</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Custom tracking domain</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Reporting</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Recipients per campaign</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-12">
                  <h6 className='itemTxt fs-5 text-dark'>Extensions & Enhancements</h6>
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Browser extensions</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <p className='itemTxt fs-5 text-dark'>Google Sheets add-on</p>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <h6 className='itemTxt fs-5 text-dark'>No Team Member Limit</h6>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
                <div className="col-8">
                  <h6 className='itemTxt fs-5 text-dark'>Support</h6>
                </div>
                <div className="col-4">
                  <MdDone className="fs-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {window.location.pathname === "/pricing" &&
        <section className="faqSecondSection py-5">
          <div className="container py-3">
            <div className="row">
              <div className="col-12 mb-4">
                <h6 className="title text-center">Frequently asked questions</h6>
              </div>
              {/* <div className='col-12'> */}
              <div
                class="accordion frqAskedQusAccordion"
                id="accordionExample"
              >
                <div
                  class=" row"

                >
                  <div className="col-md-6 general-acc">
                    {generalQuestion.map((res, index) => (
                      <div class="accordion-item mb-4 py-3" key={res.id}>
                        <h2 class="accordion-header" id={"heading" + res.id}>
                          <button
                            class={
                              (res.id !== 1 ? "collapsed " : "") +
                              "accordion-button border-0 pb-2"
                            }
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapse" + res.id}
                            aria-expanded={res.id === 1 ? true : false}
                            aria-controls={"collapse" + res.id}
                          >
                            {res.title}
                          </button>
                        </h2>
                        <div
                          id={"collapse" + res.id}
                          class={
                            (res.id === 1 ? "show " : "") +
                            "accordion-collapse collapse border-0"
                          }
                          aria-labelledby={"heading" + res.id}
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body pt-0">
                            <p className="m-0 pe-md-5">{res.description}</p>
                          </div>
                        </div>
                      </div>

                    ))}
                  </div>
                  <div className="col-md-6">
                    {generalQuestion2.map((res, index) => (
                      <div class="accordion-item mb-4 py-3" key={res.id}>
                        <h2 class="accordion-header" id={"heading" + res.id}>
                          <button
                            class={
                              (res.id !== 1 ? "collapsed " : "") +
                              "accordion-button border-0 pb-2"
                            }
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapse" + res.id}
                            aria-expanded={res.id === 1 ? true : false}
                            aria-controls={"collapse" + res.id}
                          >
                            {res.title}
                          </button>
                        </h2>
                        <div
                          id={"collapse" + res.id}
                          class={
                            (res.id === 1 ? "show " : "") +
                            "accordion-collapse collapse border-0"
                          }
                          aria-labelledby={"heading" + res.id}
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body pt-0">
                            <p className="m-0 pe-md-5">{res.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>}      
      {window.location.pathname === "/pricing" && <Footer />}
    </>
  );
}

export default NewPrice;
