import React from 'react';
import Navbar from '../../pages/dashboard/Navbar';
import './Bulks.css';
import { useNavigate } from 'react-router-dom';
import rightArrow from '../../assets/inner-page/right-arrow-icon.svg'
import mailIcon from '../../assets/inner-page/mail-icon.svg'
import mailFinderIcon from '../../assets/inner-page/mail-finder-icon.svg'

const Bulks = () => {
  const navigate = useNavigate();

  const handleBulkTask = () => {
    navigate('/bulk-tasks/bulk-email-verifier');
  }

  const handleBulkEmailFinder = () => {
    navigate('/bulk-tasks-finder/bulk-email-finder');
  }

  return (
    <>
      <Navbar />
      <div className='bulk_main '>
        <div className='bulk_card p-5'>
          <div className=''>
            <h5 className='mainTitle'>Bulk tasks</h5>
            <p className='mainDesc'>Select which activities to perform in mass quantities</p>
          </div>
          <hr className='horizontal-line my-4' />

          <div className='d-flex justify-content-between align-items-center bulksHover cursorPointer' onClick={() => handleBulkTask()}>
            <div className='d-flex gap-3'>
              <div>
                <img src={mailIcon} alt="img not found" />
              </div>
              <div>
                <h6 className='subTitle'>Email Verifier</h6>
                <p className='subDesc'>Validate email addresses.</p>
              </div>
            </div>

            <div>
              <img src={rightArrow} alt='img not found' className='img-fluid' />
            </div>
          </div>

          <hr className='horizontal-line my-4' />
          <div className='d-flex justify-content-between align-items-center bulksHover cursorPointer' onClick={() => handleBulkEmailFinder()}>
            <div className='d-flex gap-3'>
              <div>
                <img src={mailFinderIcon} alt="img not found" />
              </div>
              <div>
                <h6 className='subTitle'>Email Finder</h6>
                <p className='subDesc'>Source professional email addresses for a list of names and affiliated companies.</p>
              </div>
            </div>

            <div>
              <img src={rightArrow} alt='img not found' className='img-fluid' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Bulks;