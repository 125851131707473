import React, { useState } from 'react'
import { Helmet } from "react-helmet";
// import Navbar from "../../new_home/Navbar_1";
import Navbar_Dash from '../dashboard/Navbar';
import Footer from '../../new_home/Footer';
import CampaignHeader from "../campaign/CampaignHeader";
import CampaignSideBar from "../campaign/CampaignSideBar";
import "../campaign/Campaign.css";
import CampaignAudience from '../campaign/audience/CampaignAudience';
import CampaignSettings from '../campaign/settings/CampaignSettings';
import CampaignReview from '../campaign/review/CampaignReview';
import CampaignSummary from '../campaign/summary/CampaignSummary';
import AllCampaigns from '../campaign/allCampaigns/AllCampaigns';
import Content from '../campaign/dashboard/Content';
import CampaignSetting from '../campaign/settings/CampaignSetting';
import Archive from '../campaign/archive/Archive';
import CampaignTemplate from '../campaign/emailTemplates/CampaignTemplate';
import CampaignEmailTemplate from '../campaign/emailTemplates/CampaignEmailTemplate';
import CreateEmailTemplate from '../campaign/emailTemplates/CreateEmailTemplates';
import CampaignDetailEmail from '../campaign/summary/CampaignDetailEmail';
import EmailAudience from '../campaign/summary/EmailAudience';
import GlobalTemplates from '../campaign/emailTemplates/global-templates/GlobalTemplates';
import Template from '../campaign/emailTemplates/global-templates/Template';
import Reporting from '../campaign/reporting/Reporting';

const CampaignLayout = (props) => {
  const [callBackAudience, setCallBackAudience] = useState();
  const [callBackCampaignId, setCallBackCampaignId] = useState();
  const [callBackCampaignIndicator, setCallBackCampaignIndicator] = useState();
  const [callBackSelectAllLeads, setCallBackSelectAllLeads] = useState();
  
  const handleAudience = (aud) => {
    const moreArray = [];
    aud?.selectedRows.map((res)=>{
      moreArray.push(res.email)
    })
    setCallBackAudience(moreArray);
  }

  const handleCampaignId = (val) =>{
    setCallBackCampaignId(val);
  }

  const handleCampaignIndicator = (val)=>{
    setCallBackCampaignIndicator(val);
  }

  const handleAllSelectLeads = (val) =>{
    setCallBackSelectAllLeads(val);
  }

  return (
      <>
        <Helmet>
          <title>
            Campaign |  Top And Quick E-mail Verification For Your Business - Discovemail. | The Best Online Email Extractor And Verifier For Free - Discovemail.
          </title>

          <meta
            name="description"
            content="Discovemail Is The Best Online Email Extractor And Verifier For Free. Improve your email marketing with Discovemail. Ensure High-Quality Email Lists For Better Results. "
          />

          <meta
            name="keywords"
            content=" Discovemail Is A Quick E-mail Verification Tool. Get Fast & Accurate Results With Discovemail. Improve Your E-mail Deliverability. Try With Discovemail Today For Free. "
          />

        </Helmet>

        <div className='page'>
        <div className='headerContainer'>
            <Navbar_Dash />
        </div>

        <div className='row mt7rem  p-0 g-0' style={{ flex: 'auto', overflow: 'auto' }}>
          <div className='col-2 scrollLeft'>
          <CampaignSideBar />
          </div>
          <div className='col-10 position-relative pageBody scrollRight p-0'>
          <div className="col-12 leftside dashboard">
              {
                props.pageRoute !== 'all-campaigns' && props.pageRoute !== 'setting' && props.pageRoute !== 'archive' && props.pageRoute !== 'template' && props.pageRoute !== 'all-template' && props.pageRoute !== 'create-template' && props.pageRoute !== 'global-templates' && props.pageRoute !== 'single-template' && props.pageRoute !== 'reporting' && <CampaignHeader
                 pageRoute={props.pageRoute} 
                 callBackAudience={callBackAudience}
                 callBackCampaignIndicator={callBackCampaignIndicator}
                 callBackSelectAllLeads={callBackSelectAllLeads}
                />
              }
              <div className="row justify-content-center minHeight73vh" >
                {props.pageRoute === 'all-campaigns' && <AllCampaigns handleCampaignId={handleCampaignId} handleCampaignIndicator={handleCampaignIndicator} />}
                {props.pageRoute === 'content' && <Content/>}
                {props.pageRoute === 'audience' && <CampaignAudience handleAudience={handleAudience} handleAllSelectLeads={handleAllSelectLeads} />}
                {props.pageRoute === 'settings' && <CampaignSettings />}
                {props.pageRoute === 'review' && <CampaignReview />}
                {props.pageRoute === 'summary' && <CampaignSummary pageRoute={props.pageRoute} />}
                {props.pageRoute === 'create-template' && <CreateEmailTemplate />}
                {props.pageRoute === 'all-template' && <CampaignTemplate />}
                {props.pageRoute === 'template' && <CampaignEmailTemplate />}
                {props.pageRoute === 'setting' && <CampaignSetting />}
                {props.pageRoute === 'archive' && <Archive />}
                {props.pageRoute === 'global-templates' && <GlobalTemplates />}
                {props.pageRoute === 'single-template' && <Template />}
                {props.pageRoute === 'reporting' && <Reporting />}
              </div>
            </div>
          </div>
        </div>
    </div>
      </>
  )
}

export default CampaignLayout;