import React, { useEffect } from 'react';
import "./ColdEmail.css";
import MailImage from '../../../assets/mail-image.svg';
import SearchCompany from '../../../assets/home-search-company.svg';
import TakeDiscoveMail from '../../../assets/takeDiscoveMail.svg';


const ColdEmail = () => {
    useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
    }, []);

    return (
        <>
            <div className='bg_color_dark'>
                <section className=' container-fluid searchCompany  py-5 d-flex justify-content-center'>
                    <div className="row px-2 px-lg-5 pb-4 ">
                        <div className="col-12 pe-lg-5 text-center">
                            <h2 className='search_title'>Search Company</h2>
                            <p className='search_description'>Unlock valuable insights about companies of interest with our powerful tool. <br/> Access comprehensive data to make informed decisions and drive business growth.</p>
                            <a
                                className="btn btn-disco d-inline-block mt-3"
                                href="/search-company"
                            >
                                SEARCH COMPANY DETAILS
                            </a>
                        </div>
                    </div>
                </section>

                <section className='coldSection'>
                    <div class="container">
                        <div class="row py-5 mt-5">
                            <div class="col-md-6 col-12 pe-lg-5 px-3 px-md-1">
                                <h2 className='cold_title'>Boost your response rates with DiscoveMail for targeted campaigns.</h2>
                                <p className='cold_description mt-4'>Unlock the potential of DiscoveMail Campaigns to optimize your prospecting efforts, increasing your chances of eliciting higher responses. Seamlessly craft emails, schedule follow-ups, and analyze campaign performance for precise insights. Elevate your outreach strategy and drive engagement with targeted campaigns tailored to your audience.</p>
                                <a
                                    className="btn btn-disco d-inline-block mt-3"
                                    href="/email-verifier"
                                >
                                    START CAMPAIGN
                                </a>
                            </div>
                            <div className='col-md-6 col-12 text-center mt-5 mt-md-2'>
                                <img src={MailImage} alt="img" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </section>

                <section className=' container takeDiscoverMail mt-5'>
                    <div className="row px-3 px-lg-5 pb-4 align-items-center flex-column-reverse flex-md-row ">
                        <div className='col-md-6 col-12'>
                            <div>
                                <img src={TakeDiscoveMail} alt='img' className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <h2 className='title my-4 my-lg-0 pe-lg-5 me-lg-5'>Take Discovemail with you.</h2>
                            <p className='description pe-lg-5 me-lg-5'>Uncover email addresses associated with the LinkedIn profiles you're viewing in just one click.</p>
                            <a
                                className="btn btn-disco d-inline-block mt-3"
                                href="https://chrome.google.com/webstore/detail/discovemail/hbfdhianoohibldpghooaeeamjhcjhjn"
                            >
                                ADD TO CHROME
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default ColdEmail;