import React from "react";
import "./hero.css";
// import My_video from "../../../assets/video/intro_video.mp4";
import HeroImg from '../../../assets/home-banner.png';
import newHeroImg from '../../../assets/homebanner-new.png';
import Unsplash from '../../../assets/unsplash.svg';
import Notion from '../../../assets/notion.svg';
import Intercom from '../../../assets/intercom.svg';
import Discript from '../../../assets/discript.svg';
import Grammarly from '../../../assets/grammarly.svg';

export default function HeroBanner() {
  return (
    <section className="hero-banner" id="#home">
      <div className="container pb-5">
        <div className="row">
          <div className="col-md-6 col-sm-12 pt-5 d-flex align-items-center px-0">  
            <div className="hero-area">
              <h1 className="hero-banner__title text-center text-lg-start">
              {/* Email Verification Solution: Easy, Rapid & Precise. */}
              <span>Ultimate Email Toolkit</span> <br />
              <span className="gradientColor">Finder, </span>
              <span className="gradientColor">Verifier & Campaigns</span>
              </h1>
              <p className="hero-banner__des text-center text-lg-start">
                {/* Protect your reputation and resources. With a 37% yearly drop in email reliability, ensure delivery to over 43% of recipients. Maximize deliverability with a bulk email verification solution. */}
                Ready to launch your email campaigns? start verifying your email list now. It's time your emails start paying you off
              </p>
              <div className="d-flex align-items-center my-4 justify-content-center justify-content-md-start" id="general">
                <a
                  className="btn btn-disco"
                  href="/email-verifier"
                >
                  Try it Free
                </a>
              </div>

            </div>
          </div>
          {/* <div className="col-md-6 col-sm-12 d-flex justify-content-end">
            <img src={HeroImg} alt="img" className=" img-fluid"/>
          </div> */}
          <div className="col-md-6 col-sm-12 d-flex justify-content-end newHeroImg-Box">
            <img src={newHeroImg} alt="img" className="newHeroImg"/>
          </div>
        </div>
        {/* <div className="row my-5 px-5">
          <div className="col-12 d-md-flex justify-content-evenly d-md-block">
            <img src={Unsplash} alt="image" />
            <img src={Notion} alt="image" />
            <img src={Intercom} alt="image" />
            <img src={Discript} alt="image" />
            <img src={Grammarly} alt="image" />
          </div>
        </div> */}
      </div>
    </section>
  );
}
