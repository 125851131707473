import React, { useState } from "react";
import "./Password.css";
import { useFormik } from "formik";
import PageNavbar from "../sigin/PageNavbar";
import DiscoveLogo from '../../assets/discovemail_logo.png'
import forgetBanner from '../../assets/forgetBanner.webp'
import { RiKey2Line } from "react-icons/ri";
import { CiMail } from "react-icons/ci";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import { setCurrentUser } from "../../utils/utils";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../loader/Loader";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
import Helmet from "react-helmet";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Please enter email";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Please Enter a new Password";
  } else if (!/^(?=.*?[a-z])/i.test(values.password)) {
    errors.password = "Must contain lowercase";
  } else if (!/^(?=.*?[0-9])/i.test(values.password)) {
    errors.password = "Must contain number";
  } else if (!/.{4,}/i.test(values.password)) {
    errors.password = "Must have 4 characters";
  } else if (!values.confirm) {
    errors.confirm = "Please Enter Confirm Password";
  } else if (values.password !== values.confirm) {
    errors.confirm = "Password Does not match";
  }

  return errors;
};

function Password() {
  const user = localStorage.getItem("ID");

  const navigate = useNavigate();
  const [hidePassword, setHidePassword] = useState(true);
  const [values, setValues] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // Function for Hide and Show Password
  function togglePasswordVisibility() {
    setHidePassword(!hidePassword);
  }

  const formik = useFormik({
    initialValues: {
      email: user ? user : "",
      password: "",
      confirm: "",
    },
    validate,
    onSubmit: (values) => {
      setLoading(true);
      setButtonDisabled(true);
      Change(values);
    },
  });

  const Change = (values) => {
    let data = JSON.stringify({
      email: values.email,
      password: values.password,
    });
    // API for Password Reset
    requestInstance
      .post(API_ENDPOINT.PASSWORD_RESET_API, data)
      .then((res) => {
        if (res.status == 200) {
          //console.log(res)
          setCurrentUser(res.data.access);
          toast.success(res.data.message);
          setLoading(false);
          setButtonDisabled(false);
          navigate("/signin");
        }
      })
      .catch((err) => {
        //console.log(err)
        setLoading(false);
        setButtonDisabled(false);
      });
  };

  const tabHead = "Discovemail-reset"
  return (
    <div className="">
      <Helmet>
        <title>{tabHead}</title>
      </Helmet>


      <div className="loginSection bg_color_dark">
        <div className="container-fluid px-0 mx-0">
          <div className="row px-0 mx-0 align-items-center">
            <div className="col-md-6 px-0 position-relative">
              {/* ---Part1--- */}
              <div >
                <img src={forgetBanner} alt="img not found" className="img-fluid" />
              </div>
              <div className="logo">
                <a href="/">
                  <img src={DiscoveLogo} alt="img not found" className="img-fluid" />
                </a>
              </div>
            </div>
            {/* ---Part2--- */}
            <div className="col-md-6">
              <div className="row justify-content-center py-5 px-md-5">
                <div className=" col-10">
                  <h1 className="loginTitle text-center mb-5"> Reset Password</h1>
                  <div>
                    <form onSubmit={formik.handleSubmit} className="">
                      <div className="form-label mb-lg-4 mb-3">
                        <div className="input-group">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className="form-control py-3"
                            placeholder="Email address"
                          />
                        </div>

                        {formik.touched.email && formik.errors.email ? (
                          <div className="errormsg">{formik.errors.email}</div>
                        ) : null}
                      </div>

                      <div className="form-label mb-lg-4 mt-3 mb-3">
                        <div className="input-group ">
                          <input
                            type="password"
                            id="password"
                            name="password"
                            title="Password must contain atleast 4 characters and atleast 1 number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            className="form-control  py-3"
                            placeholder="New Password"
                          />
                        </div>
                        {formik.touched.password && formik.errors.password ? (
                          <div className="errormsg">{formik.errors.password}</div>
                        ) : null}
                      </div>

                      <div className="input-group">
                        <input
                          type={hidePassword ? "password" : "text"}
                          id="confirm"
                          name="confirm"
                          title="Password must contain atleast 4 characters and atleast 1 number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.confirm}
                          className="form-control password py-3"
                          placeholder="Confirm Password"
                        />
                        <span
                          className="input-group-text login_icon"
                          onClick={togglePasswordVisibility}
                        >
                          {hidePassword ? (
                            <AiOutlineEye />
                          ) : (
                            <AiOutlineEyeInvisible />
                          )}
                        </span>
                      </div>
                      {formik.touched.confirm && formik.errors.confirm ? (
                        <div className="errormsg">{formik.errors.confirm}</div>
                      ) : null}

                      <div >
                        <button
                          type="submit"
                          className="btn btn-disco w-100 py-3 mb-3 mt-4"
                          disabled={loading}
                        >
                          {loading ? <Loader /> : <>Reset Password</>}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Password;
