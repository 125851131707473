import React, { useState } from "react";
import "./howItWorks.css";

import EmailVerifier from "../../../assets/home-email-verify.png";
import DomainVerifier from "../../../assets/home-domain-verify.webp";
import EmailFromNameDomain from "../../../assets/home-name-verify.png";
import VerifyIcon from "../../../assets/email-verify-icon.svg";
import DomainIcon from "../../../assets/domain-icon.svg";
import SearchCompany from "../../../assets/company-list.png"
import nameDomainVerify from "../../../assets/name-domain-verify-icon.svg"
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function Index() {
  const [toggleItem, setToggleItem] = useState(0);

  return (
    <section className="howItWorks__area bg_color_dark" id="products">
      <div className=" container pb-5">
        <div className="row justify-content-center">
          <div className="col-12">
            <h1 className="mainTitle text-center">Our Product</h1>
          </div>
          <div className=" col-lg-7">
            <p className="mainDescription text-center px-lg-3">DiscoveMail swiftly reveal the necessary contact details for prominent decision-makers in a time-effective manner.</p>
          </div>
          <div className="col-12 tab-container my-5">
            <div className="row gap-5 gap-lg-0">
              <ul class="nav nav-underline justify-content-center">
                <li class="nav-item ">
                  <a class="nav-link active d-flex align-items-end py-3 " aria-current="page" href="#tab1" data-bs-toggle="tab"><img src={VerifyIcon} alt="img" class="img-fluid" />&nbsp;&nbsp; Email Verifier</a>
                </li>
                
                <li class="nav-item border-LR">
                  <a class="nav-link d-flex align-items-end py-3" href="#tab2" data-bs-toggle="tab"><img src={DomainIcon} alt="img" class="img-fluid" />&nbsp;&nbsp; Domain Verifier</a>
                </li>
                
                <li class="nav-item">
                  <a class="nav-link  d-flex align-items-end py-3" href="#tab3" data-bs-toggle="tab"><img src={nameDomainVerify} alt="img" class="img-fluid" />&nbsp;&nbsp; Email finder</a>
                </li>
              </ul>


              <div class="tab-content mt-5 pt-5">
                <div class="tab-pane fade show active " id="tab1">

                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6 col-12 d-flex justify-content-center">
                        <div>
                          <img src={EmailVerifier} alt="img" class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6 col-12 align-self-center">
                        <h2 class="title1 mb-4 pe-lg-5 me-lg-5">Email verification that's easy-to-use.</h2>
                        <p class="description1 pe-lg-5 me-lg-5">Simplifies the process, allowing you to upload lists directly from your computer. With a user-friendly interface, you can quickly identify and remove invalid or outdated email addresses. Streamline your email marketing efforts and ensure deliverability rates with clean, accurate lists.</p>
                        <a class="btn btn-disco d-inline-block mt-3" href="/email-verifier">START</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab2">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6 col-12 d-flex justify-content-center">
                        <div>
                          <img src={DomainVerifier} alt="img" class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6 col-12 align-self-center">
                        <h2 class="title1 mb-4 pe-lg-5 me-lg-5">Verify Your Domain With Confidence</h2>
                        <p class="description1 pe-lg-5 me-lg-5">If you're trying to quickly find out who to contact within a corporation, the Domain Search is ideal. It finds publicly accessible email addresses in less than 20 seconds, along with sector filters, reliability scores, and thorough resources.</p>
                        <a class="btn btn-disco d-inline-block mt-3" href="trailarea/domain-verifier">START</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab3">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6 col-12 d-flex justify-content-center">
                        <div>
                          <img src={EmailFromNameDomain} alt="img" class="img-fluid" />
                        </div>
                      </div>
                      <div class="col-md-6 col-12 align-self-center">
                        <h2 class="title1 mb-4 pe-lg-5 me-lg-5">Discovering Contacts by Name and Company Domain</h2>
                        <p class="description1 pe-lg-5 me-lg-5">For streamlined communication with professionals, trust the Email Finder. It compiles our data, including email formats, web addresses, and verification status, to quickly locate reliable contact information. Simplify your outreach efforts and save valuable time with this comprehensive tool. Whether you're reaching out to potential clients, partners, or colleagues, the Email Finder ensures that you have accurate and up-to-date contact details at your fingertips. Say goodbye to the hassle of searching for contact information manually and enhance your productivity with this powerful solution.</p>
                        <a class="btn btn-disco d-inline-block mt-3" href="trailarea/email-from-name-and-domain">START</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
